@charset "UTF-8";
/*
    Project: Martfury - Marketplace html template.
    Version: 2.1.1
    Date Created: 06/12/2018
    Date Updated: 2020-12-14T14:10:47+07:00
    Author: Nouthemes
    Developed by: diaryforlife
    Homepage: https://themeforest.net/user/nouthemes/portfolio
*/
/*
    TABLE OF CONTENT
        1. RESET AND TYPHOGRAPHY
            1.1 reset
            1.2 typhography
        2. ELEMENTS
            2.1 grid
            2.2 list
            2.3 button
            2.4 form
            2.5 post
            2.6 pagination
            2.7 breadcrumb
            2.8 block
            2.9 widget
            2.10 slider
            2.11 masonry
            2.12 product
            2.13 banner
            2.14 filter
            2.15 table
            2.16 loader
            2.17 modal
        3. COMPONENTS
             3.1 Cart
             3.2 forms
             3.3 search
             3.4 section
             3.5 pages
        4. MODULES
            4.1 header
            4.2 footer
        5. HELPERS
        6. CUSTOM
*/
.masonry-wrapper .grid-item .grid-item__inner, .setting .grid-item .grid-item__inner {
  position: relative;
}

.masonry-wrapper.ac_masonry-grid .grid-item__content-wrapper, .setting.ac_masonry-grid .grid-item__content-wrapper {
  position: absolute;
}

.masonry-wrapper .grid-item .grid-item__inner .grid-item__content-wrapper, .setting .grid-item .grid-item__inner .grid-item__content-wrapper {
  overflow: hidden;
}

.masonry-wrapper.ac_masonry-grid .grid-item__content-wrapper, .setting.ac_masonry-grid .grid-item__content-wrapper {
  margin: 0 !important;
}

.masonry-wrapper.ac_masonry-grid .grid-item__inner {
  padding-top: 100%;
}

.masonry-wrapper.ac_masonry-grid .grid-item.extra-large .grid-item__inner, .masonry-wrapper.ac_masonry-grid .grid-item.wide .grid-item__inner {
  padding-top: 50%;
}

.masonry-wrapper.ac_masonry-grid .grid-item.high .grid-item__inner {
  padding-top: 200%;
}

.masonry-wrapper.ac_masonry-grid[data-ratio="4by3"] .grid-item__inner {
  padding-top: 75%;
}

.masonry-wrapper.ac_masonry-grid[data-ratio="4by3"] .grid-item.extra-large .grid-item__inner, .masonry-wrapper.ac_masonry-grid[data-ratio="4by3"] .grid-item.wide .grid-item__inner {
  padding-top: 37.5%;
}

.masonry-wrapper.ac_masonry-grid[data-ratio="4by3"] .grid-item.high .grid-item__inner {
  padding-top: 150%;
}

.masonry-wrapper.ac_masonry-grid[data-ratio="16by9"] .grid-item__inner {
  padding-top: 56.25%;
}

.masonry-wrapper.ac_masonry-grid[data-ratio="16by9"] .grid-item.extra-large .grid-item__inner, .masonry-wrapper.ac_masonry-grid[data-ratio="16by9"] .grid-item.wide .grid-item__inner {
  padding-top: 28.125%;
}

.masonry-wrapper.ac_masonry-grid[data-ratio="16by9"] .grid-item.high .grid-item__inner {
  padding-top: 112.5%;
}

.masonry-wrapper[data-col-xs="1"] .grid-item,
.masonry-wrapper[data-col-xs="1"] .grid-sizer {
  width: 100%;
}

.masonry-wrapper[data-col-xs="2"] .grid-item,
.masonry-wrapper[data-col-xs="2"] .grid-sizer {
  width: 50%;
}

.masonry-wrapper[data-col-xs="2"] .grid-item.wide, .masonry-wrapper[data-col-xs="2"] .grid-item.large {
  width: 100%;
}

.masonry-wrapper[data-col-xs="2"] .grid-item.extra-large {
  width: 100%;
}

.masonry-wrapper[data-col-xs="3"] .grid-item,
.masonry-wrapper[data-col-xs="3"] .grid-sizer {
  width: 33.33333%;
}

.masonry-wrapper[data-col-xs="3"] .grid-item.wide, .masonry-wrapper[data-col-xs="3"] .grid-item.large {
  width: 66.66667%;
}

.masonry-wrapper[data-col-xs="3"] .grid-item.extra-large {
  width: 100%;
}

.masonry-wrapper[data-col-xs="4"] .grid-item,
.masonry-wrapper[data-col-xs="4"] .grid-sizer {
  width: 25%;
}

.masonry-wrapper[data-col-xs="4"] .grid-item.wide, .masonry-wrapper[data-col-xs="4"] .grid-item.large {
  width: 50%;
}

.masonry-wrapper[data-col-xs="4"] .grid-item.extra-large {
  width: 100%;
}

.masonry-wrapper[data-col-xs="5"] .grid-item,
.masonry-wrapper[data-col-xs="5"] .grid-sizer {
  width: 20%;
}

.masonry-wrapper[data-col-xs="5"] .grid-item.wide, .masonry-wrapper[data-col-xs="5"] .grid-item.large {
  width: 40%;
}

.masonry-wrapper[data-col-xs="5"] .grid-item.extra-large {
  width: 100%;
}

.masonry-wrapper[data-col-xs="6"] .grid-item,
.masonry-wrapper[data-col-xs="6"] .grid-sizer {
  width: 16.66667%;
}

.masonry-wrapper[data-col-xs="6"] .grid-item.wide, .masonry-wrapper[data-col-xs="6"] .grid-item.large {
  width: 33.33333%;
}

.masonry-wrapper[data-col-xs="6"] .grid-item.extra-large {
  width: 100%;
}

.masonry-wrapper[data-col-xs="7"] .grid-item,
.masonry-wrapper[data-col-xs="7"] .grid-sizer {
  width: 14.28571%;
}

.masonry-wrapper[data-col-xs="7"] .grid-item.wide, .masonry-wrapper[data-col-xs="7"] .grid-item.large {
  width: 28.57143%;
}

.masonry-wrapper[data-col-xs="7"] .grid-item.extra-large {
  width: 100%;
}

.masonry-wrapper[data-col-xs="8"] .grid-item,
.masonry-wrapper[data-col-xs="8"] .grid-sizer {
  width: 12.5%;
}

.masonry-wrapper[data-col-xs="8"] .grid-item.wide, .masonry-wrapper[data-col-xs="8"] .grid-item.large {
  width: 25%;
}

.masonry-wrapper[data-col-xs="8"] .grid-item.extra-large {
  width: 100%;
}

.masonry-wrapper[data-col-xs="9"] .grid-item,
.masonry-wrapper[data-col-xs="9"] .grid-sizer {
  width: 11.11111%;
}

.masonry-wrapper[data-col-xs="9"] .grid-item.wide, .masonry-wrapper[data-col-xs="9"] .grid-item.large {
  width: 22.22222%;
}

.masonry-wrapper[data-col-xs="9"] .grid-item.extra-large {
  width: 100%;
}

.masonry-wrapper[data-col-xs="10"] .grid-item,
.masonry-wrapper[data-col-xs="10"] .grid-sizer {
  width: 10%;
}

.masonry-wrapper[data-col-xs="10"] .grid-item.wide, .masonry-wrapper[data-col-xs="10"] .grid-item.large {
  width: 20%;
}

.masonry-wrapper[data-col-xs="10"] .grid-item.extra-large {
  width: 100%;
}

@media (min-width: 768px) {
  .masonry-wrapper[data-col-sm="1"] .grid-item,
  .masonry-wrapper[data-col-sm="1"] .grid-sizer {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .masonry-wrapper[data-col-sm="2"] .grid-item,
  .masonry-wrapper[data-col-sm="2"] .grid-sizer {
    width: 50%;
  }
  .masonry-wrapper[data-col-sm="2"] .grid-item.wide, .masonry-wrapper[data-col-sm="2"] .grid-item.large {
    width: 100%;
  }
  .masonry-wrapper[data-col-sm="2"] .grid-item.extra-large {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .masonry-wrapper[data-col-sm="3"] .grid-item,
  .masonry-wrapper[data-col-sm="3"] .grid-sizer {
    width: 33.33333%;
  }
  .masonry-wrapper[data-col-sm="3"] .grid-item.wide, .masonry-wrapper[data-col-sm="3"] .grid-item.large {
    width: 66.66667%;
  }
  .masonry-wrapper[data-col-sm="3"] .grid-item.extra-large {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .masonry-wrapper[data-col-sm="4"] .grid-item,
  .masonry-wrapper[data-col-sm="4"] .grid-sizer {
    width: 25%;
  }
  .masonry-wrapper[data-col-sm="4"] .grid-item.wide, .masonry-wrapper[data-col-sm="4"] .grid-item.large {
    width: 50%;
  }
  .masonry-wrapper[data-col-sm="4"] .grid-item.extra-large {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .masonry-wrapper[data-col-sm="5"] .grid-item,
  .masonry-wrapper[data-col-sm="5"] .grid-sizer {
    width: 20%;
  }
  .masonry-wrapper[data-col-sm="5"] .grid-item.wide, .masonry-wrapper[data-col-sm="5"] .grid-item.large {
    width: 40%;
  }
  .masonry-wrapper[data-col-sm="5"] .grid-item.extra-large {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .masonry-wrapper[data-col-sm="6"] .grid-item,
  .masonry-wrapper[data-col-sm="6"] .grid-sizer {
    width: 16.66667%;
  }
  .masonry-wrapper[data-col-sm="6"] .grid-item.wide, .masonry-wrapper[data-col-sm="6"] .grid-item.large {
    width: 33.33333%;
  }
  .masonry-wrapper[data-col-sm="6"] .grid-item.extra-large {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .masonry-wrapper[data-col-sm="7"] .grid-item,
  .masonry-wrapper[data-col-sm="7"] .grid-sizer {
    width: 14.28571%;
  }
  .masonry-wrapper[data-col-sm="7"] .grid-item.wide, .masonry-wrapper[data-col-sm="7"] .grid-item.large {
    width: 28.57143%;
  }
  .masonry-wrapper[data-col-sm="7"] .grid-item.extra-large {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .masonry-wrapper[data-col-sm="8"] .grid-item,
  .masonry-wrapper[data-col-sm="8"] .grid-sizer {
    width: 12.5%;
  }
  .masonry-wrapper[data-col-sm="8"] .grid-item.wide, .masonry-wrapper[data-col-sm="8"] .grid-item.large {
    width: 25%;
  }
  .masonry-wrapper[data-col-sm="8"] .grid-item.extra-large {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .masonry-wrapper[data-col-sm="9"] .grid-item,
  .masonry-wrapper[data-col-sm="9"] .grid-sizer {
    width: 11.11111%;
  }
  .masonry-wrapper[data-col-sm="9"] .grid-item.wide, .masonry-wrapper[data-col-sm="9"] .grid-item.large {
    width: 22.22222%;
  }
  .masonry-wrapper[data-col-sm="9"] .grid-item.extra-large {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .masonry-wrapper[data-col-sm="10"] .grid-item,
  .masonry-wrapper[data-col-sm="10"] .grid-sizer {
    width: 10%;
  }
  .masonry-wrapper[data-col-sm="10"] .grid-item.wide, .masonry-wrapper[data-col-sm="10"] .grid-item.large {
    width: 20%;
  }
  .masonry-wrapper[data-col-sm="10"] .grid-item.extra-large {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .masonry-wrapper[data-col-md="1"] .grid-item,
  .masonry-wrapper[data-col-md="1"] .grid-sizer {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .masonry-wrapper[data-col-md="2"] .grid-item,
  .masonry-wrapper[data-col-md="2"] .grid-sizer {
    width: 50%;
  }
  .masonry-wrapper[data-col-md="2"] .grid-item.wide, .masonry-wrapper[data-col-md="2"] .grid-item.large {
    width: 100%;
  }
  .masonry-wrapper[data-col-md="2"] .grid-item.extra-large {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .masonry-wrapper[data-col-md="3"] .grid-item,
  .masonry-wrapper[data-col-md="3"] .grid-sizer {
    width: 33.33333%;
  }
  .masonry-wrapper[data-col-md="3"] .grid-item.wide, .masonry-wrapper[data-col-md="3"] .grid-item.large {
    width: 66.66667%;
  }
  .masonry-wrapper[data-col-md="3"] .grid-item.extra-large {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .masonry-wrapper[data-col-md="4"] .grid-item,
  .masonry-wrapper[data-col-md="4"] .grid-sizer {
    width: 25%;
  }
  .masonry-wrapper[data-col-md="4"] .grid-item.wide, .masonry-wrapper[data-col-md="4"] .grid-item.large {
    width: 50%;
  }
  .masonry-wrapper[data-col-md="4"] .grid-item.extra-large {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .masonry-wrapper[data-col-md="5"] .grid-item,
  .masonry-wrapper[data-col-md="5"] .grid-sizer {
    width: 20%;
  }
  .masonry-wrapper[data-col-md="5"] .grid-item.wide, .masonry-wrapper[data-col-md="5"] .grid-item.large {
    width: 40%;
  }
  .masonry-wrapper[data-col-md="5"] .grid-item.extra-large {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .masonry-wrapper[data-col-md="6"] .grid-item,
  .masonry-wrapper[data-col-md="6"] .grid-sizer {
    width: 16.66667%;
  }
  .masonry-wrapper[data-col-md="6"] .grid-item.wide, .masonry-wrapper[data-col-md="6"] .grid-item.large {
    width: 33.33333%;
  }
  .masonry-wrapper[data-col-md="6"] .grid-item.extra-large {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .masonry-wrapper[data-col-md="7"] .grid-item,
  .masonry-wrapper[data-col-md="7"] .grid-sizer {
    width: 14.28571%;
  }
  .masonry-wrapper[data-col-md="7"] .grid-item.wide, .masonry-wrapper[data-col-md="7"] .grid-item.large {
    width: 28.57143%;
  }
  .masonry-wrapper[data-col-md="7"] .grid-item.extra-large {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .masonry-wrapper[data-col-md="8"] .grid-item,
  .masonry-wrapper[data-col-md="8"] .grid-sizer {
    width: 12.5%;
  }
  .masonry-wrapper[data-col-md="8"] .grid-item.wide, .masonry-wrapper[data-col-md="8"] .grid-item.large {
    width: 25%;
  }
  .masonry-wrapper[data-col-md="8"] .grid-item.extra-large {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .masonry-wrapper[data-col-md="9"] .grid-item,
  .masonry-wrapper[data-col-md="9"] .grid-sizer {
    width: 11.11111%;
  }
  .masonry-wrapper[data-col-md="9"] .grid-item.wide, .masonry-wrapper[data-col-md="9"] .grid-item.large {
    width: 22.22222%;
  }
  .masonry-wrapper[data-col-md="9"] .grid-item.extra-large {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .masonry-wrapper[data-col-md="10"] .grid-item,
  .masonry-wrapper[data-col-md="10"] .grid-sizer {
    width: 10%;
  }
  .masonry-wrapper[data-col-md="10"] .grid-item.wide, .masonry-wrapper[data-col-md="10"] .grid-item.large {
    width: 20%;
  }
  .masonry-wrapper[data-col-md="10"] .grid-item.extra-large {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .masonry-wrapper[data-col-lg="1"] .grid-item,
  .masonry-wrapper[data-col-lg="1"] .grid-sizer {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .masonry-wrapper[data-col-lg="2"] .grid-item,
  .masonry-wrapper[data-col-lg="2"] .grid-sizer {
    width: 50%;
  }
  .masonry-wrapper[data-col-lg="2"] .grid-item.wide, .masonry-wrapper[data-col-lg="2"] .grid-item.large {
    width: 100%;
  }
  .masonry-wrapper[data-col-lg="2"] .grid-item.extra-large {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .masonry-wrapper[data-col-lg="3"] .grid-item,
  .masonry-wrapper[data-col-lg="3"] .grid-sizer {
    width: 33.33333%;
  }
  .masonry-wrapper[data-col-lg="3"] .grid-item.wide, .masonry-wrapper[data-col-lg="3"] .grid-item.large {
    width: 66.66667%;
  }
  .masonry-wrapper[data-col-lg="3"] .grid-item.extra-large {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .masonry-wrapper[data-col-lg="4"] .grid-item,
  .masonry-wrapper[data-col-lg="4"] .grid-sizer {
    width: 25%;
  }
  .masonry-wrapper[data-col-lg="4"] .grid-item.wide, .masonry-wrapper[data-col-lg="4"] .grid-item.large {
    width: 50%;
  }
  .masonry-wrapper[data-col-lg="4"] .grid-item.extra-large {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .masonry-wrapper[data-col-lg="5"] .grid-item,
  .masonry-wrapper[data-col-lg="5"] .grid-sizer {
    width: 20%;
  }
  .masonry-wrapper[data-col-lg="5"] .grid-item.wide, .masonry-wrapper[data-col-lg="5"] .grid-item.large {
    width: 40%;
  }
  .masonry-wrapper[data-col-lg="5"] .grid-item.extra-large {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .masonry-wrapper[data-col-lg="6"] .grid-item,
  .masonry-wrapper[data-col-lg="6"] .grid-sizer {
    width: 16.66667%;
  }
  .masonry-wrapper[data-col-lg="6"] .grid-item.wide, .masonry-wrapper[data-col-lg="6"] .grid-item.large {
    width: 33.33333%;
  }
  .masonry-wrapper[data-col-lg="6"] .grid-item.extra-large {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .masonry-wrapper[data-col-lg="7"] .grid-item,
  .masonry-wrapper[data-col-lg="7"] .grid-sizer {
    width: 14.28571%;
  }
  .masonry-wrapper[data-col-lg="7"] .grid-item.wide, .masonry-wrapper[data-col-lg="7"] .grid-item.large {
    width: 28.57143%;
  }
  .masonry-wrapper[data-col-lg="7"] .grid-item.extra-large {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .masonry-wrapper[data-col-lg="8"] .grid-item,
  .masonry-wrapper[data-col-lg="8"] .grid-sizer {
    width: 12.5%;
  }
  .masonry-wrapper[data-col-lg="8"] .grid-item.wide, .masonry-wrapper[data-col-lg="8"] .grid-item.large {
    width: 25%;
  }
  .masonry-wrapper[data-col-lg="8"] .grid-item.extra-large {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .masonry-wrapper[data-col-lg="9"] .grid-item,
  .masonry-wrapper[data-col-lg="9"] .grid-sizer {
    width: 11.11111%;
  }
  .masonry-wrapper[data-col-lg="9"] .grid-item.wide, .masonry-wrapper[data-col-lg="9"] .grid-item.large {
    width: 22.22222%;
  }
  .masonry-wrapper[data-col-lg="9"] .grid-item.extra-large {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .masonry-wrapper[data-col-lg="10"] .grid-item,
  .masonry-wrapper[data-col-lg="10"] .grid-sizer {
    width: 10%;
  }
  .masonry-wrapper[data-col-lg="10"] .grid-item.wide, .masonry-wrapper[data-col-lg="10"] .grid-item.large {
    width: 20%;
  }
  .masonry-wrapper[data-col-lg="10"] .grid-item.extra-large {
    width: 100%;
  }
}

.masonry-wrapper[data-gap="5"] {
  margin: -3px;
}

.masonry-wrapper[data-gap="5"] .grid-item .grid-item__content-wrapper {
  margin: 3px;
  top: 3px;
  right: 3px;
  bottom: 3px;
  left: 3px;
}

.masonry-wrapper[data-gap="10"] {
  margin: -5px;
}

.masonry-wrapper[data-gap="10"] .grid-item .grid-item__content-wrapper {
  margin: 5px;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
}

.masonry-wrapper[data-gap="15"] {
  margin: -8px;
}

.masonry-wrapper[data-gap="15"] .grid-item .grid-item__content-wrapper {
  margin: 8px;
  top: 8px;
  right: 8px;
  bottom: 8px;
  left: 8px;
}

.masonry-wrapper[data-gap="20"] {
  margin: -10px;
}

.masonry-wrapper[data-gap="20"] .grid-item .grid-item__content-wrapper {
  margin: 10px;
  top: 10px;
  right: 10px;
  bottom: 10px;
  left: 10px;
}

.masonry-wrapper[data-gap="25"] {
  margin: -13px;
}

.masonry-wrapper[data-gap="25"] .grid-item .grid-item__content-wrapper {
  margin: 13px;
  top: 13px;
  right: 13px;
  bottom: 13px;
  left: 13px;
}

.masonry-wrapper[data-gap="30"] {
  margin: -15px;
}

.masonry-wrapper[data-gap="30"] .grid-item .grid-item__content-wrapper {
  margin: 15px;
  top: 15px;
  right: 15px;
  bottom: 15px;
  left: 15px;
}

/*1. RESET AND TYPHOGRAPHY */
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

progress {
  vertical-align: baseline;
}

template,
[hidden] {
  display: none;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:active,
a:hover {
  outline-width: 0;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: inherit;
}

b,
strong {
  font-weight: bolder;
}

dfn {
  font-style: italic;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

mark {
  background-color: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

button,
input,
select,
textarea {
  font: inherit;
  margin: 0;
}

optgroup {
  font-weight: bold;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  /* 3 */
  white-space: normal;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

* {
  font-family: "Work Sans", sans-serif;
  font-weight: 400;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  color: #000;
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: 700;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 12px;
}

p {
  font-size: 1.4rem;
  line-height: 1.6em;
  color: #666;
}

p span {
  font-family: inherit;
  color: inherit;
  font-size: inherit;
}

a {
  position: relative;
  color: inherit;
  text-decoration: none;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

a:hover {
  color: #F7E5CD;
}

a,
input,
textarea,
button,
select {
  outline: none;
}

a:hover,
h1:focus,
h2:focus,
h3:focus,
h4:focus,
h5:focus,
h6:focus {
  text-decoration: none;
}

a:focus,
button:focus,
select:focus,
input:focus,
textarea:focus {
  outline: none;
  text-decoration: none;
}

img {
  max-width: 100%;
}

iframe {
  border: 0;
  max-width: 100%;
  width: 100%;
}

figure {
  margin: 0;
}

.ps-document ul,
.ps-document ol {
  margin-bottom: 10px;
}

.ps-document ul ul,
.ps-document ul ol,
.ps-document ol ul,
.ps-document ol ol {
  margin-bottom: 0;
}

.ps-document ul li,
.ps-document ol li {
  color: #666;
  font-size: 14px;
  line-height: 1.6em;
}

.ps-document h5 {
  font-size: 16px;
  font-weight: 600;
}

.ps-document p {
  margin-bottom: 4rem;
  line-height: 1.8em;
  color: #666;
}

.ps-document p i {
  font-family: "Libre Baskerville", serif;
}

.ps-document p strong {
  font-weight: 600;
  color: #666;
}

.ps-document p strong i {
  font-weight: 400;
  color: #000;
}

.ps-document blockquote {
  position: relative;
  margin-bottom: 3rem;
  padding: 3rem 2rem;
  padding-left: 6rem;
  background-color: #f1f1f1;
}

.ps-document blockquote:before {
  content: '“';
  position: absolute;
  top: 10px;
  left: 30px;
  font-family: "Libre Baskerville", serif;
  font-size: 5rem;
  color: #666;
}

.ps-document blockquote p {
  margin-bottom: 0;
  font-family: "Libre Baskerville", serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.8em;
  color: #666;
  font-style: italic;
}

.ps-document .ps-document__heading {
  position: relative;
  display: block;
  margin-bottom: 30px;
  text-align: center;
}

.ps-document .ps-document__heading h4 {
  display: inline-block;
  padding: 0 3rem;
  margin-bottom: 0;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  background-color: #ffffff;
}

.ps-document .ps-document__heading:before {
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ebebeb;
  z-index: -1;
}

.ps-success {
  color: #529115;
  font-weight: 500;
}

.ps-heading {
  margin-bottom: 1.5rem;
  font-size: 3.6rem;
  color: #000;
  font-weight: 600;
  line-height: 1.4em;
}

.ps-heading--2 {
  font-size: 2.4rem;
}

@media (max-width: 767px) {
  .ps-heading {
    font-size: 2.4rem;
  }
}

.ps-blockquote {
  border-left: 4px solid #F7E5CD;
  padding-left: 60px;
}

.ps-blockquote p {
  font-family: "Libre Baskerville", serif;
  font-size: 26px;
  font-weight: 400;
  color: #000;
}

.ps-blockquote span {
  font-weight: 600;
  color: #000;
}

.ps-tag {
  font-weight: 600;
}

.ps-tag--in-stock {
  color: #669900;
}

.ps-tag--out-stock {
  color: #000;
}

/*2. ELEMENTS */
html, body {
  min-height: 100%;
  position: relative;
}

.ps-row {
  margin: 0 -15px;
}

.ps-row::after {
  clear: both;
  content: "";
  display: table;
}

.ps-row .ps-column {
  float: left;
  width: 20%;
  padding: 0 15px;
}

@media (max-width: 1600px) {
  .ps-row .ps-column {
    width: 25%;
  }
}

@media (max-width: 991px) {
  .ps-row .ps-column {
    width: calc(100% / 3);
  }
}

@media (max-width: 767px) {
  .ps-row .ps-column {
    width: calc(100% / 2);
  }
}

@media (max-width: 479px) {
  .ps-row .ps-column {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .ps-col-tiny .col-xs-12 {
    width: 50%;
  }
}

@media (max-width: 479px) {
  .ps-col-tiny .col-xs-12 {
    width: 100%;
  }
}

body {
  overflow-x: hidden;
}

.ps-container {
  max-width: 1650px;
  margin: 0 auto;
  padding: 0 15px;
}

@media (max-width: 1680px) {
  .ps-container {
    padding: 0 30px;
    max-width: 100%;
  }
}

@media (max-width: 479px) {
  .ps-container {
    padding: 0 15px;
  }
}

@media (min-width: 1200px) {
  .container {
    min-width: 1230px;
  }
}

.ps-list--social {
  margin: 0;
  padding: 0;
}

.ps-list--social li {
  position: relative;
  display: inline-block;
  margin-right: 20px;
  vertical-align: top;
}

.ps-list--social li a {
  display: inline-block;
  font-size: 18px;
  color: #666;
}

.ps-list--social li a:hover {
  color: #F7E5CD;
}

.ps-list--social li a.facebook i {
  color: #3b5998;
}

.ps-list--social li a.twitter i {
  color: #0084b4;
}

.ps-list--social li a.google-plus i {
  color: #FF0000;
}

.ps-list--social li a.instagram i {
  color: #C32AA3;
}

.ps-list--pricing li {
  position: relative;
  padding: 1.5rem 2rem;
  background-color: #ffffff;
  vertical-align: top;
  color: #000000;
}

.ps-list--pricing li::after {
  clear: both;
  content: "";
  display: table;
}

.ps-list--pricing li span {
  float: right;
  vertical-align: top;
}

.ps-list--pricing li:nth-child(even) {
  background-color: #f7f4ee;
}

.ps-list--checked li {
  margin-bottom: 20px;
}

.ps-list--checked li a {
  position: relative;
  display: block;
  padding-left: 30px;
  font-size: 14px;
  color: #313131;
}

.ps-list--checked li a:before {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  width: 20px;
  height: 20px;
  border: solid 1px #8d8d8d;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.ps-list--checked li a:after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 4px;
  left: 8px;
  color: #fff;
  width: 6px;
  height: 10px;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  visibility: hidden;
  opacity: 0;
}

.ps-list--checked li a:hover:before {
  background-color: #F7E5CD;
  border-color: #F7E5CD;
}

.ps-list--checked li a:hover:after {
  visibility: visible;
  opacity: 1;
}

.ps-list--checked li.current a:before {
  background-color: #F7E5CD;
  border-color: #F7E5CD;
}

.ps-list--checked li.current a:after {
  visibility: visible;
  opacity: 1;
}

.ps-list--checked.ps-list--checked-circle li a:before {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.ps-list--checked.ps-list--checked-circle li a:hover:before {
  background-color: #a7a7a7;
  border-color: #a7a7a7;
}

.ps-list--arrow a {
  position: relative;
  display: block;
  padding-left: 15px;
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #626262;
}

.ps-list--arrow a:before {
  content: "\f0da";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  font-family: FontAwesome;
}

.ps-list--arrow a:hover {
  color: #F7E5CD;
}

.ps-list--arrow a:hover .circle {
  background-color: #222222;
  border-color: #222222;
}

.ps-list--arrow a:hover .circle:before {
  color: #fff;
  visibility: visible;
  opacity: 1;
}

.ps-list--arrow li {
  margin-bottom: 20px;
}

.ps-list--arrow li.current a {
  color: #222222;
}

.ps-list--arrow li.current .circle {
  background-color: #222222;
}

.ps-list--arrow li.current .circle:before {
  visibility: visible;
  opacity: 1;
  color: #fff;
}

.ps-list--dot {
  margin: 0;
  padding: 0;
  margin-bottom: 2rem;
  list-style-type: none;
}

.ps-list--dot li {
  margin-bottom: 1rem;
  position: relative;
  padding-left: 20px;
  font-size: 1.4rem;
  color: #000000;
  line-height: 20px;
}

.ps-list--dot li:before {
  content: '';
  position: absolute;
  top: 5px;
  left: 0;
  width: 6px;
  height: 6px;
  background-color: #000000;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.ps-list--plus li {
  margin: 10px 0;
}

.ps-list--plus li a {
  display: block;
  position: relative;
  padding-left: 15px;
  line-height: 20px;
}

.ps-list--plus li a:before {
  content: '+';
  position: absolute;
  top: 0;
  left: 0;
  line-height: 20px;
}

.ps-list--link li {
  display: block;
  padding: 4px 0;
}

.ps-list--link li a {
  display: inline-block;
  color: #666;
  line-height: 20px;
  position: relative;
}

.ps-list--link li a:before {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #000;
  -webkit-transform: scale3d(0, 1, 1);
  -moz-transform: scale3d(0, 1, 1);
  -ms-transform: scale3d(0, 1, 1);
  -o-transform: scale3d(0, 1, 1);
  transform: scale3d(0, 1, 1);
  transition: transform 0.4s cubic-bezier(0.7, 0, 0.3, 1);
  -webkit-transform-origin: 100% 50%;
  -moz-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  -o-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}

.ps-list--link li a:hover:before {
  -webkit-transform: scale3d(1, 1, 1);
  -moz-transform: scale3d(1, 1, 1);
  -ms-transform: scale3d(1, 1, 1);
  -o-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transform-origin: 0 50%;
  -moz-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
  -o-transform-origin: 0 50%;
  transform-origin: 0 50%;
}

.ps-list--categories li {
  position: relative;
  padding: 6px 20px 6px 0;
}

.ps-list--categories li a {
  display: block;
  line-height: 22px;
}

.ps-list--categories li .sub-toggle {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
}

.ps-list--categories li .sub-toggle i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.ps-list--categories li .sub-toggle i:before {
  content: "\e93a";
  font-family: 'Linearicons' !important;
}

.ps-list--categories li .sub-toggle:before, .ps-list--categories li .sub-toggle:after {
  display: none;
}

.ps-list--categories li .sub-menu {
  position: relative;
  padding-left: 20px;
  visibility: visible;
  opacity: 1;
  transition: all 0s ease;
  background-color: transparent;
  border: none;
  display: none;
}

.ps-list--categories li .sub-menu li a {
  background-color: transparent;
  padding: 0;
  border: none;
}

.ps-list--categories li .sub-menu li.menu-item-has-children .sub-toggle {
  display: inline-block;
}

.ps-list--categories li .sub-menu li.menu-item-has-children .sub-menu {
  position: relative;
  top: 0;
  left: 0;
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.ps-list--blog-links {
  display: block;
  text-align: center;
}

.ps-list--blog-links li {
  display: inline-block;
  margin-right: 20px;
}

.ps-list--blog-links li a {
  font-size: 18px;
  font-weight: 600;
  color: #999;
  line-height: 1.2em;
}

.ps-list--blog-links li a:hover {
  color: #000;
}

.ps-list--blog-links li:last-child {
  margin-right: 0;
}

.ps-list--blog-links li.active a {
  color: #000;
}

.ps-list--social-color {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ps-list--social-color li {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}

.ps-list--social-color li a {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  width: 38px;
  height: 38px;
  color: #ffffff;
}

.ps-list--social-color li a.facebook {
  background-color: #1b4e9b;
}

.ps-list--social-color li a.facebook:hover {
  background-color: #296fda;
}

.ps-list--social-color li a.twitter {
  background-color: #00aeef;
}

.ps-list--social-color li a.twitter:hover {
  background-color: #3dcaff;
}

.ps-list--social-color li a.google-plus {
  background-color: #cc0000;
}

.ps-list--social-color li a.google-plus:hover {
  background-color: #ff1a1a;
}

.ps-list--social-color li a.linkedin {
  background-color: #2d567f;
}

.ps-list--social-color li a.linkedin:hover {
  background-color: #417cb7;
}

.ps-list--social-color li a.feed {
  background-color: #f86a02;
}

.ps-list--social-color li a.feed:hover {
  background-color: #fe9549;
}

button {
  cursor: pointer;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.menu-toggle, .ps-btn--hamburger {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  overflow: hidden;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.menu-toggle span, .menu-toggle:before, .menu-toggle:after, .ps-btn--hamburger span, .ps-btn--hamburger:before, .ps-btn--hamburger:after {
  position: absolute;
  right: 10px;
  height: 2px;
  width: 20px;
  background-color: #303030;
  z-index: 100;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.menu-toggle span, .ps-btn--hamburger span {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.menu-toggle:before, .menu-toggle:after, .ps-btn--hamburger:before, .ps-btn--hamburger:after {
  content: '';
}

.menu-toggle:before, .ps-btn--hamburger:before {
  top: 12px;
}

.menu-toggle:after, .ps-btn--hamburger:after {
  bottom: 12px;
}

.menu-toggle:hover, .ps-btn--hamburger:hover {
  cursor: pointer;
}

.menu-toggle.active span, .ps-btn--hamburger.active span {
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
  visibility: hidden;
  opacity: 0;
}

.menu-toggle.active:before, .menu-toggle.active:after, .ps-btn--hamburger.active:before, .ps-btn--hamburger.active:after {
  top: 50%;
  bottom: auto;
  width: 20px;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  background-color: #fc354c;
}

.menu-toggle.active:before, .ps-btn--hamburger.active:before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.menu-toggle.active:after, .ps-btn--hamburger.active:after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

@media (max-width: 1199px) {
  .menu-toggle, .ps-btn--hamburger {
    display: inline-block;
  }
}

.ps-btn, button.ps-btn {
  display: inline-block;
  padding: 15px 45px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #000;
  border: none;
  font-weight: 600;
  border-radius: 4px;
  background-color: #F7E5CD;
  transition: all .4s ease;
  cursor: pointer;
}

.ps-btn--rounded, button.ps-btn--rounded {
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  border-radius: 50px;
}

.ps-btn--curve, button.ps-btn--curve {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
}

.ps-btn--outline, button.ps-btn--outline {
  border: 2px solid #F7E5CD;
  color: #F7E5CD;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  box-shadow: none;
  font-weight: 500;
}

.ps-btn--outline:hover, button.ps-btn--outline:hover {
  color: #ffffff;
  background-color: #F7E5CD;
}

.ps-btn--fullwidth, button.ps-btn--fullwidth {
  width: 100%;
  text-align: center;
}

.ps-btn--sm, button.ps-btn--sm {
  padding: .5rem 2rem;
  font-size: 1.2rem;
}

.ps-btn--sm.ps-btn--curve, button.ps-btn--sm.ps-btn--curve {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

.ps-btn--lg, button.ps-btn--lg {
  padding: 1.5rem 4rem;
}

.ps-btn--xl, button.ps-btn--xl {
  padding: 2rem 6rem;
  font-size: 1.6rem;
}

.ps-btn.ps-btn--reverse, button.ps-btn.ps-btn--reverse {
  background-color: #576391;
}

.ps-btn.ps-btn--reverse:hover, button.ps-btn.ps-btn--reverse:hover {
  background-color: #222222;
}

.ps-btn.ps-btn--gray, button.ps-btn.ps-btn--gray {
  background-color: #e5e5e5;
  color: #000000;
  font-size: 1.6rem;
}

.ps-btn.ps-btn--black, button.ps-btn.ps-btn--black {
  background-color: #000;
}

.ps-btn.ps-btn--black.ps-btn--outline, button.ps-btn.ps-btn--black.ps-btn--outline {
  background-color: transparent;
  border: 2px solid #000;
  color: #000;
}

.ps-btn.ps-btn--black.ps-btn--outline:hover, button.ps-btn.ps-btn--black.ps-btn--outline:hover {
  background-color: #000;
  color: #ffffff;
}

.ps-btn:hover, .ps-btn:active, button.ps-btn:hover, button.ps-btn:active {
  background-color: #222;
  color: #fff;
}

.ps-btn:hover.ps-btn--black, .ps-btn:active.ps-btn--black, button.ps-btn:hover.ps-btn--black, button.ps-btn:active.ps-btn--black {
  background-color: #F7E5CD;
}

.ps-btn--favorite {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  border: none;
  font-size: 18px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  vertical-align: top;
}

.ps-btn--favorite i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #000000;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.ps-btn--favorite:hover i {
  color: #222222;
}

.ps-btn--close {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.ps-btn--close:before, .ps-btn--close:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2px;
  height: 50%;
  background-color: #9f9a98;
  -webkit-transform-origin: center center;
  -moz-transform-origin: center center;
  -ms-transform-origin: center center;
  -o-transform-origin: center center;
  transform-origin: center center;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.ps-btn--close:before {
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  -moz-transform: translate(-50%, -50%) rotate(45deg);
  -ms-transform: translate(-50%, -50%) rotate(45deg);
  -o-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}

.ps-btn--close:after {
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  -moz-transform: translate(-50%, -50%) rotate(-45deg);
  -ms-transform: translate(-50%, -50%) rotate(-45deg);
  -o-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}

.ps-btn--close:hover {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.ps-btn--close:hover:before, .ps-btn--close:hover:after {
  background-color: #f44336;
  -webkit-transition-delay: 0.3s;
  -moz-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

#back2top {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 30px;
  right: 30px;
  width: 40px;
  height: 40px;
  z-index: 10000;
  border: none;
  border: 1px solid #ddd;
  transition: all .4s ease;
  box-shado: 0 0 3px 0 rgba(0, 0, 0, 0.2);
  background-color: transparent;
  border-radius: 4px;
  visibility: hidden;
  opacity: 0;
}

#back2top i {
  z-index: 10001;
  font-size: 14px;
  margin-bottom: -2px;
  color: #000;
}

#back2top:hover {
  cursor: pointer;
  background-color: #F7E5CD;
  border-color: #F7E5CD;
}

#back2top.active {
  bottom: 30px;
  visibility: visible;
  opacity: 1;
}

.form-control {
  outline: none;
  height: 50px;
  font-size: 14px;
  padding: 0 20px;
  border: none;
  height: 50px;
  background-color: transparent;
  border: 1px solid #dddddd;
  border-radius: 0;
  box-shadow: 0 0 rgba(0, 0, 0, 0);
  transition: all .4s ease;
  box-shadow: 0 0 0 #000;
}

.form-control::-webkit-input-placeholder {
  opacity: 1;
}

.form-control::-moz-placeholder {
  opacity: 1;
}

.form-control:-moz-placeholder {
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  opacity: 1;
}

.form-control:focus {
  outline: none;
  box-shadow: 0 0 0 #000;
  border-color: #F7E5CD;
}

textarea.form-control {
  height: auto;
  padding: 2rem;
  resize: none;
}

.ps-input--has-icon {
  position: relative;
}

.ps-input--has-icon > i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 15px;
}

.ps-select {
  display: inline-block;
  min-width: 120px;
  cursor: pointer;
}

.ps-select .selectize-input {
  vertical-align: top;
}

.ps-number {
  position: relative;
}

.ps-number span {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 50%;
  border-left: 1px solid #979797;
  transition: all .4s ease;
}

.ps-number span:before {
  font-family: FontAwesome;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #000;
}

.ps-number span.up {
  border-bottom: 1px solid #979797;
}

.ps-number span.up:before {
  content: "\f106";
}

.ps-number span.down {
  top: auto;
  bottom: 0;
}

.ps-number span.down:before {
  content: "\f107";
}

.ps-number span:hover {
  cursor: pointer;
  background-color: #F7E5CD;
}

.ps-number span:hover:before {
  color: #fff;
}

.ps-checkbox {
  position: relative;
  display: block;
}

.ps-checkbox > input {
  position: absolute;
  visibility: hidden;
  box-sizing: border-box;
}

.ps-checkbox label {
  margin-bottom: 0;
  position: relative;
  padding-left: 30px;
  font-family: "Work Sans", sans-serif;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
  cursor: pointer;
}

.ps-checkbox label:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0px;
  height: 20px;
  width: 20px;
  z-index: 10;
  border: 1px solid #000;
  transition: all .4s ease;
}

.ps-checkbox label:after {
  content: '';
  display: block;
  position: absolute;
  top: 4px;
  left: 7px;
  width: 6px;
  height: 10px;
  border: 2px solid #fff;
  border-top: none;
  border-left: none;
  z-index: 10;
  opacity: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: all .4s ease;
}

.ps-checkbox input[type=checkbox]:checked ~ label:before {
  background-color: #222222;
  border-color: #222222;
}

.ps-checkbox input[type=checkbox]:checked ~ label:after {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  opacity: 1;
}

.ps-checkbox--inline {
  display: inline-block;
  margin-bottom: 1rem;
}

.ps-checkbox--color {
  margin-right: 1rem;
}

.ps-checkbox--color > label {
  margin-bottom: 0;
  width: 20px;
  height: 20px;
  padding-left: 0;
  background-color: transparent;
  border-radius: 50%;
  transition: all .4s ease;
}

.ps-checkbox--color > label:before {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border: none;
  border-radius: 50%;
}

.ps-checkbox--color > label:after {
  display: none !important;
}

.ps-checkbox--color input[type=radio]:checked ~ label {
  background-color: transparent;
}

.ps-checkbox--color input[type=radio]:checked ~ label:before {
  background-color: transparent;
  border: 1px solid #ccc;
}

.ps-checkbox--color.color-1 label:before {
  background-color: #000000;
}

.ps-checkbox--color.color-1 input[type=checkbox]:checked ~ label:before {
  background-color: #000000;
  border: 2px solid rgba(0, 0, 0, 0.2);
}

.ps-checkbox--color.color-2 label:before {
  background-color: #709abf;
}

.ps-checkbox--color.color-2 input[type=checkbox]:checked ~ label:before {
  background-color: #709abf;
  border: 2px solid rgba(0, 0, 0, 0.2);
}

.ps-checkbox--color.color-3 label:before {
  background-color: #ad8111;
}

.ps-checkbox--color.color-3 input[type=checkbox]:checked ~ label:before {
  background-color: #ad8111;
  border: 2px solid rgba(0, 0, 0, 0.2);
}

.ps-checkbox--color.color-4 label:before {
  background-color: #57d6db;
}

.ps-checkbox--color.color-4 input[type=checkbox]:checked ~ label:before {
  background-color: #57d6db;
  border: 2px solid rgba(0, 0, 0, 0.2);
}

.ps-checkbox--color.color-5 label:before {
  background-color: #e5ac10;
}

.ps-checkbox--color.color-5 input[type=checkbox]:checked ~ label:before {
  background-color: #e5ac10;
  border: 2px solid rgba(0, 0, 0, 0.2);
}

.ps-checkbox--color.color-6 label:before {
  background-color: #8ce84a;
}

.ps-checkbox--color.color-6 input[type=checkbox]:checked ~ label:before {
  background-color: #8ce84a;
  border: 2px solid rgba(0, 0, 0, 0.2);
}

.ps-checkbox--color.color-7 label:before {
  background-color: #c9c9c9;
}

.ps-checkbox--color.color-7 input[type=checkbox]:checked ~ label:before {
  background-color: #c9c9c9;
  border: 2px solid rgba(0, 0, 0, 0.2);
}

.ps-checkbox--color.color-8 label:before {
  background-color: #e664ef;
}

.ps-checkbox--color.color-8 input[type=checkbox]:checked ~ label:before {
  background-color: #e664ef;
  border: 2px solid rgba(0, 0, 0, 0.2);
}

.ps-checkbox--color.color-8 label:before {
  background-color: #9d58e2;
}

.ps-checkbox--color.color-8 input[type=checkbox]:checked ~ label:before {
  background-color: #9d58e2;
  border: 2px solid rgba(0, 0, 0, 0.2);
}

.ps-checkbox--color.color-8 label:before {
  background-color: #ffffff;
}

.ps-checkbox--color.color-8 input[type=checkbox]:checked ~ label:before {
  background-color: #ffffff;
  border: 2px solid rgba(0, 0, 0, 0.2);
}

.ps-checkbox.color-sidebar > label:before {
  width: 26px;
  height: 26px;
  border: 1px solid #ddd;
}

.ps-checkbox--size {
  margin-right: 1rem;
}

.ps-checkbox--size > label {
  margin-bottom: 0;
  padding: 8px 20px;
  border: none;
  color: #222222;
  background-color: #f6f6f6;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  transition: all .4s ease;
}

.ps-checkbox--size > label:before, .ps-checkbox--size > label:after {
  display: none;
}

.ps-checkbox--size input[type=checkbox]:checked ~ label {
  color: #ffffff;
  background-color: #a7a7a7;
}

.ps-radio {
  position: relative;
}

.ps-radio > input {
  position: absolute;
  visibility: hidden;
  box-sizing: border-box;
}

.ps-radio label {
  position: relative;
  margin-bottom: 0;
  padding-left: 30px;
  font-family: "Work Sans", sans-serif;
  color: #737373;
  font-weight: 400;
  cursor: pointer;
}

.ps-radio label:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0px;
  height: 20px;
  width: 20px;
  z-index: 10;
  border: 1px solid rgba(19, 8, 1, 0.2);
  background-color: #fff;
  transition: all .4s ease;
  border-radius: 50%;
}

.ps-radio label:after {
  content: '';
  display: block;
  position: absolute;
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
  z-index: 15;
  opacity: 0;
  -webkit-transform: scale(1.5, 1.5);
  -moz-transform: scale(1.5, 1.5);
  -ms-transform: scale(1.5, 1.5);
  -o-transform: scale(1.5, 1.5);
  transform: scale(1.5, 1.5);
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  transition: all 0.6s ease;
}

.ps-radio input[type=radio]:checked ~ label:before {
  background-color: #222222;
  border-color: #222222;
}

.ps-radio input[type=radio]:checked ~ label:after {
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1);
  opacity: 1;
}

.ps-radio--inline {
  display: inline-block;
  margin-right: 20px;
}

.ps-radio--color {
  margin-right: 1rem;
}

.ps-radio--color > label {
  margin-bottom: 0;
  width: 20px;
  height: 20px;
  padding-left: 0;
  background-color: transparent;
  border-radius: 50%;
  transition: all .4s ease;
}

.ps-radio--color > label:before {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border: none;
}

.ps-radio--color > label:after {
  display: none !important;
}

.ps-radio--color input[type=radio]:checked ~ label {
  background-color: rgba(61, 196, 126, 0.5);
}

.ps-radio--color.color-1 label:before {
  background-color: #35dccb;
}

.ps-radio--color.color-1 input[type=radio]:checked ~ label {
  background-color: rgba(53, 220, 203, 0.5);
}

.ps-radio--color.color-1 input[type=radio]:checked ~ label:before {
  background-color: #21bdad;
}

.ps-radio--color.color-2 label:before {
  background-color: #fec6df;
}

.ps-radio--color.color-2 input[type=radio]:checked ~ label {
  background-color: rgba(254, 198, 223, 0.5);
}

.ps-radio--color.color-2 input[type=radio]:checked ~ label:before {
  background-color: #fd94c3;
}

.ps-radio--color.color-3 label:before {
  background-color: #ffd980;
}

.ps-radio--color.color-3 input[type=radio]:checked ~ label {
  background-color: rgba(255, 217, 128, 0.5);
}

.ps-radio--color.color-3 input[type=radio]:checked ~ label:before {
  background-color: #ffca4d;
}

.ps-color {
  position: relative;
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 5px;
}

.ps-color--1 {
  background-color: #000;
}

.ps-color--2 {
  background-color: #ad4d4b;
}

.ps-color--3 {
  background-color: #fdb201;
}

.ps-color:last-child {
  margin-right: 0;
}

.tooltip .tooltip-inner {
  font-size: 14px;
}

.form-group {
  margin-bottom: 2.5rem;
}

.form-group > label {
  margin-bottom: 1.5rem;
  font-weight: 400;
  color: #000000;
  line-height: 1em;
}

.form-group--inline {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.form-group--inline > label {
  margin-bottom: 0;
  font-weight: 700;
  min-width: 160px;
  max-width: 160px;
}

.form-group--inline .form-group__content {
  width: 100%;
}

@media (max-width: 1199px) {
  .form-group--inline {
    padding-left: 0;
  }
  .form-group--inline label {
    position: static;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  .form-group--inline.textarea label {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@media (max-width: 767px) {
  .form-group--inline {
    display: block;
  }
}

.form-group--number {
  display: inline-block;
  position: relative;
}

.form-group--number button {
  background: none;
  border: none;
  background-color: transparent;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  max-width: 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: 20px;
  line-height: 1em;
}

.form-group--number button.up {
  right: 10px;
}

.form-group--number button.down {
  left: 10px;
}

.form-group--number .form-control {
  border: 1px solid #ccc;
  height: 40px;
  padding: 0 25px;
  text-align: center;
  font-size: 14px;
  color: #000;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  border-radius: 50px;
  background-color: transparent;
}

.form-group--number .form-control::-webkit-input-placeholder {
  color: #000;
}

.form-group--number .form-control::-moz-placeholder {
  color: #000;
}

.form-group--number .form-control:-moz-placeholder {
  color: #000;
}

.form-group--number .form-control:-ms-input-placeholder {
  color: #000;
}

.form-group--select {
  position: relative;
}

.form-group--select i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 20px;
}

.form-group--select select {
  height: 40px;
  padding: 0 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 1px solid #ccc;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  border-radius: 50px;
}

.form-group--nest {
  display: flex;
  flex-flow: row nowrap;
}

.form-group--nest .ps-btn {
  border-radius: 0 4px 4px 0;
  padding-left: 25px;
  padding-right: 25px;
}

.form-group--nest input {
  border-radius: 4px 0 0 4px;
  border-color: #ccc;
  border-right: none;
}

.form-group--icon {
  position: relative;
}

.form-group--icon i {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 10px;
  font-size: 16px;
}

.form-group--icon .form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}

.ps-dropdown {
  position: relative;
  display: inline-block;
}

.ps-dropdown > a {
  position: relative;
  display: block;
  padding-right: 20px;
}

.ps-dropdown > a:after {
  content: "\e93a";
  font: normal normal normal 12px/1 linearicons;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
}

.ps-dropdown ul {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 100;
  min-width: 120px;
  max-width: 200px;
  width: 100%;
  padding: 4px 0;
  background-color: #fff;
  -webkit-box-shadow: 0 1px 1px 2px rgba(204, 204, 204, 0.2);
  -moz-box-shadow: 0 1px 1px 2px rgba(204, 204, 204, 0.2);
  -ms-box-shadow: 0 1px 1px 2px rgba(204, 204, 204, 0.2);
  box-shadow: 0 1px 1px 2px rgba(204, 204, 204, 0.2);
  visibility: hidden;
  opacity: 0;
  text-align: left;
}

.ps-dropdown ul li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.ps-dropdown ul li a {
  display: block;
  font-size: 1.3rem;
  color: #000;
  padding: 6px 10px;
}

.ps-dropdown:hover ul {
  visibility: visible;
  opacity: 1;
}

.ps-dropdown.open ul {
  visibility: visible;
  opacity: 1;
}

.ps-variant {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  z-index: 10;
  cursor: pointer;
  border: 1px solid #999;
}

.ps-variant__tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  padding: 5px 10px;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.9);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  color: #ffffff;
  font-size: 12px;
  transition: all .4s ease;
  visibility: hidden;
  opacity: 0;
}

.ps-variant__tooltip:before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  height: 0;
  width: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #000;
}

.ps-variant:last-child {
  margin-right: 0;
}

.ps-variant.ps-variant--size {
  position: relative;
  vertical-align: top;
  border-radius: 0;
  border-color: #d9d9d9;
}

.ps-variant.ps-variant--size .ps-variant__size {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #999;
  font-weight: 600;
}

.ps-variant.ps-variant--size.active .ps-variant__size {
  color: #000;
}

.ps-variant.ps-variant--color {
  width: 30px;
  height: 30px;
}

.ps-variant.ps-variant--color:before {
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.ps-variant.ps-variant--color.color--1:before {
  background-color: #000;
}

.ps-variant.ps-variant--color.color--2:before {
  background-color: #9e9e9e;
}

.ps-variant.ps-variant--color.color--3:before {
  background-color: #F06292;
}

.ps-variant.ps-variant--image {
  width: auto;
  height: auto;
  max-width: 50px;
  border-radius: 0;
  border-color: #d9d9d9;
}

.ps-variant.ps-variant--image img {
  position: relative;
}

.ps-variant:hover .ps-variant__tooltip {
  visibility: visible;
  opacity: 1;
}

.ps-variant.active:before {
  border-color: #000;
}

.ps-variant.active .ps-variant__tooltip {
  visibility: visible;
  opacity: 1;
}

.select2 {
  display: block;
}

.select2 .select2-selection--single {
  height: auto;
  outline: none;
  border-radius: 0;
}

.select2 .select2-selection--single .select2-selection__rendered {
  line-height: 20px;
  padding: 10px 20px;
}

.select2 .select2-selection--single .select2-selection__arrow {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 10px;
  width: 20px;
  height: 20px;
}

.select2 .select2-selection--single .select2-selection__arrow:before {
  content: '\f107';
  font-family: FontAwesome;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.select2 .select2-selection--single .select2-selection__arrow b {
  display: none;
}

.select2 .select2-selection--multiple {
  min-height: 0;
  border: none !important;
}

.select2 .select2-selection--multiple .select2-selection__rendered {
  margin-bottom: 0;
  padding: 0;
  padding-left: 10px;
  vertical-align: top;
}

.select2 .select2-selection--multiple .select2-selection__rendered input {
  margin-top: 0;
  height: 40px;
  padding: 0 10px;
}

.select2 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
  line-height: 20px;
  margin-right: 10px;
  margin-top: 10px;
}

.select2-container .select2-dropdown {
  border-color: #ccc;
}

.select2-container .select2-results li {
  color: #93969a;
}

.slimScrollRail, .slimScrollBar {
  border-radius: 0 !important;
}

.slimScrollBar {
  opacity: 1 !important;
  background-color: #222 !important;
}

.ps-progress {
  position: relative;
}

.ps-progress span {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  height: 100%;
}

.ps-post {
  margin-bottom: 30px;
}

.ps-post .ps-post__badge {
  width: 40px;
  height: 40px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background-color: #000;
}

.ps-post .ps-post__badge i {
  color: #ffffff;
  font-size: 20px;
}

.ps-post .ps-post__thumbnail {
  position: relative;
}

.ps-post .ps-post__thumbnail img {
  width: 100%;
}

.ps-post .ps-post__thumbnail .ps-post__badge {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 20;
}

.ps-post .ps-post__thumbnail .ps-post__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.ps-post .ps-post__thumbnail:hover .ps-post__overlay {
  background-color: rgba(255, 255, 255, 0.25);
}

.ps-post .ps-post__meta {
  display: block;
  margin-bottom: 20px;
}

.ps-post .ps-post__meta a {
  margin-right: 10px;
}

.ps-post .ps-post__title {
  display: block;
  margin: 0 0 20px;
  font-size: 20px;
  font-weight: 600;
}

.ps-post .ps-post__content {
  padding-top: 20px;
}

.ps-post .ps-post__content p {
  color: #999999;
}

.ps-post .ps-post__content p a {
  color: #000000;
}

.ps-post .ps-post__content p a:hover {
  color: #F7E5CD;
}

.ps-post--small-thumbnail {
  display: flex;
  flex-flow: row nowrap;
}

.ps-post--small-thumbnail .ps-post__thumbnail {
  width: 100%;
  max-width: 260px;
}

.ps-post--small-thumbnail .ps-post__thumbnail img {
  width: 100%;
}

.ps-post--small-thumbnail .ps-post__meta {
  margin-bottom: 10px;
}

.ps-post--small-thumbnail .ps-post__meta a:after {
  content: ',';
}

.ps-post--small-thumbnail .ps-post__meta a:last-child:after {
  display: none;
}

.ps-post--small-thumbnail .ps-post__top p {
  color: #666666;
}

.ps-post--small-thumbnail .ps-post__bottom {
  padding-top: 10px;
  border-top: 1px solid #e1e1e1;
}

.ps-post--small-thumbnail .ps-post__content {
  padding-top: 0;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .ps-post--small-thumbnail {
    flex-flow: column wrap;
  }
  .ps-post--small-thumbnail .ps-post__thumbnail {
    margin-bottom: 30px;
    max-width: 100%;
  }
  .ps-post--small-thumbnail .ps-post__content {
    padding-left: 0;
  }
  .ps-post--small-thumbnail .ps-post__bottom {
    margin-top: 10px;
  }
}

.ps-post--horizontal {
  margin-bottom: 50px;
  display: flex;
  flex-flow: row nowrap;
}

.ps-post--horizontal .ps-post__thumbnail {
  width: 100%;
  max-width: calc(100% - 400px);
}

.ps-post--horizontal .ps-post__thumbnail img {
  width: 100%;
}

.ps-post--horizontal .ps-post__content {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  background-color: #f2f2f2;
  padding: 60px;
  justify-content: space-between;
}

@media (max-width: 991px) {
  .ps-post--horizontal {
    flex-flow: row wrap;
  }
  .ps-post--horizontal .ps-post__thumbnail {
    max-width: 100%;
  }
  .ps-post--horizontal .ps-post__content {
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .ps-post--horizontal .ps-post__content {
    padding: 40px 30px;
  }
}

@media (max-width: 479px) {
  .ps-post--horizontal .ps-post__content {
    padding: 30px 20px;
  }
}

.ps-post--detail .ps-post__header {
  padding-top: 110px;
  padding-bottom: 90px;
  text-align: center;
}

.ps-post--detail .ps-post__header h1 {
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 30px;
  line-height: 1.2em;
}

.ps-post--detail .ps-post__header p {
  font-size: 16px;
  color: #666666;
}

.ps-post--detail .ps-post__header p a {
  margin-left: 10px;
}

.ps-post--detail .ps-post__header p a:after {
  content: ',';
}

.ps-post--detail .ps-post__header p a:last-child:after {
  display: none;
}

.ps-post--detail .ps-post__header p a:hover {
  color: #F7E5CD;
}

.ps-post--detail .ps-post__content {
  padding: 85px 0;
  max-width: 960px;
  margin: 0 auto;
}

.ps-post--detail .ps-post__content h4 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 30px;
  line-height: 1.4em;
}

.ps-post--detail .ps-post__content p {
  margin-bottom: 20px;
}

.ps-post--detail .ps-post__content p strong {
  color: #000;
  font-weight: 600;
}

.ps-post--detail .ps-post__content ul li {
  line-height: 1.8em;
  color: #666;
}

.ps-post--detail .ps-post__content .ps-blockquote {
  margin-bottom: 30px;
}

.ps-post--detail .ps-post__footer {
  padding-bottom: 85px;
  text-align: center;
}

.ps-post--detail .ps-post__tags {
  margin-bottom: 30px;
  font-weight: 600;
  color: #000;
}

.ps-post--detail .ps-post__tags a {
  margin-left: 10px;
  color: #666;
}

.ps-post--detail .ps-post__tags a:after {
  content: ',';
}

.ps-post--detail .ps-post__tags a:last-child:after {
  content: '';
}

.ps-post--detail .ps-post__tags a:hover {
  color: #F7E5CD;
}

.ps-post--detail .ps-post__social {
  text-align: center;
}

.ps-post--detail .ps-post__social a {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 45px;
  margin-right: 10px;
  vertical-align: top;
}

.ps-post--detail .ps-post__social a i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: #ffffff;
}

.ps-post--detail .ps-post__social a:last-child {
  margin-right: 0;
}

.ps-post--detail .ps-post__social a.facebook {
  background-color: #3b5999;
}

.ps-post--detail .ps-post__social a.twitter {
  background-color: #55acee;
}

.ps-post--detail .ps-post__social a.google {
  background-color: #dd4b39;
}

.ps-post--detail .ps-post__social a.linkedin {
  background-color: #0077B5;
}

.ps-post--detail .ps-post__social a.pinterest {
  background-color: #bd081c;
}

.ps-post--detail.sidebar .ps-post__header {
  padding: 0 0 30px;
  text-align: left;
}

@media (max-width: 991px) {
  .ps-post--detail .ps-post__header {
    padding: 90px 0;
  }
  .ps-post--detail .ps-post__header h1 {
    font-size: 36px;
  }
  .ps-post--detail .ps-post__header p {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .ps-post--detail .ps-post__header {
    padding: 60px 0;
  }
  .ps-post--detail .ps-post__header h1 {
    font-size: 32px;
  }
  .ps-post--detail .ps-post__header h1 br {
    display: none;
  }
}

@media (max-width: 479px) {
  .ps-post--detail .ps-post__header {
    padding: 45px 0;
  }
  .ps-post--detail .ps-post__header h1 {
    font-size: 24px;
  }
}

.ps-post--parallax .ps-post__header {
  position: relative;
  z-index: 10;
  padding: 180px 0;
  text-align: center;
}

.ps-post--parallax .ps-post__header:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.25);
}

.ps-post--parallax .ps-post__header h4 {
  margin-bottom: 40px;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 600;
}

.ps-post--parallax .ps-post__header h1 {
  margin-bottom: 20px;
  color: #ffffff;
  font-size: 48px;
  font-weight: 600;
  line-height: 1.3em;
}

.ps-post--parallax .ps-post__header p {
  color: #ffffff;
  font-size: 16px;
}

.pagination {
  margin: 0;
  display: inline-block;
  position: relative;
  vertical-align: top;
  font-size: 0;
}

.pagination li {
  display: inline-block;
  margin-right: 10px;
  vertical-align: top;
}

.pagination li > a {
  padding: 0 14px;
  position: relative;
  display: inline-block;
  z-index: 30;
  color: #999;
  line-height: 32px;
  border: none;
  background-color: #f5f5f5;
  color: #000;
  vertical-align: middle;
  font-size: 14px;
}

.pagination li > a i {
  font-size: 10px;
  font-weight: 600;
  margin-left: 4px;
}

.pagination li > a:hover {
  color: #000;
  background-color: #F7E5CD;
}

.pagination li.active {
  border: none;
}

.pagination li.active a {
  background-color: #F7E5CD;
  color: #000;
}

.pagination li:last-child {
  margin-right: 0;
}

.ps-pagination {
  padding-top: 70px;
  text-align: center;
}

.ps-pagination::after {
  clear: both;
  content: "";
  display: table;
}

@media (max-width: 767px) {
  .ps-pagination .pagination > li {
    margin-bottom: 10px;
  }
  .ps-pagination .pagination > li > a {
    min-width: 40px;
    line-height: 40px;
    font-size: 1.4rem;
  }
}

.ps-breadcrumb {
  padding: 20px 0;
  background-color: #f1f1f1;
}

.ps-breadcrumb .breadcrumb {
  display: inline-block;
  margin: 0;
  padding: 0;
  background-color: transparent;
}

.ps-breadcrumb .breadcrumb li {
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  color: #000;
}

.ps-breadcrumb .breadcrumb li:before {
  content: "/";
  margin: 0 5px;
}

.ps-breadcrumb .breadcrumb li:first-child {
  padding-left: 0;
}

.ps-breadcrumb .breadcrumb li:first-child:before {
  display: none;
}

.ps-breadcrumb .breadcrumb a {
  line-height: 20px;
  color: #09c;
}

.ps-breadcrumb .breadcrumb a:hover {
  color: #F7E5CD;
}

.ps-breadcrumb .breadcrumb a i {
  margin-right: 5px;
}

@media (max-width: 991px) {
  .ps-breadcrumb {
    position: relative;
    padding: 10px 0;
  }
  .ps-breadcrumb .breadcrumb li {
    font-size: 12px;
  }
  .ps-breadcrumb .breadcrumb a {
    font-size: 12px;
  }
}

.ps-breadcrumb--2 {
  text-align: center;
}

.ps-breadcrumb--2 .breadcrumb {
  display: inline-block;
  margin: 0;
  padding: 0;
  background-color: transparent;
}

.ps-breadcrumb--2 .breadcrumb li {
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  color: #F7E5CD;
}

.ps-breadcrumb--2 .breadcrumb li:before {
  content: "/";
  margin: 0 5px;
}

.ps-breadcrumb--2 .breadcrumb li:first-child {
  padding-left: 0;
}

.ps-breadcrumb--2 .breadcrumb li:first-child:before {
  display: none;
}

.ps-breadcrumb--2 .breadcrumb a {
  line-height: 20px;
  color: #000;
}

.ps-breadcrumb--2 .breadcrumb a:hover {
  color: #F7E5CD;
}

.ps-breadcrumb--2 .breadcrumb a i {
  margin-right: 5px;
}

.ps-block--download-app {
  background-color: #f8f8f8;
  padding: 80px 0 0;
}

.ps-block--download-app h3 {
  margin-bottom: 12px;
  font-size: 30px;
  font-weight: 300;
  margin-top: 0;
}

.ps-block--download-app p {
  margin-bottom: 30px;
  font-size: 16px;
  max-width: 480px;
}

.ps-block--download-app form {
  margin-bottom: 30px;
  max-width: 480px;
}

.ps-block--download-app form input {
  background-color: #ffffff;
}

.ps-block--download-app .download-link a {
  margin-right: 18px;
}

.ps-block--download-app .download-link a:last-child {
  margin-right: 0;
}

@media (max-width: 991px) {
  .ps-block--download-app .ps-block__thumbnail {
    margin-bottom: 30px;
  }
}

@media (max-width: 479px) {
  .ps-block--download-app h3 {
    font-size: 20px;
  }
  .ps-block--download-app p {
    font-size: 14px;
  }
  .ps-block--download-app .download-link {
    display: flex;
    flex-flow: row nowrap;
  }
  .ps-block--download-app .download-link a {
    width: 100%;
  }
}

.ps-block--category {
  margin-bottom: 30px;
  padding: 20px;
  border: 1px solid #bfbfbf;
  text-align: center;
  transition: all 0.4s ease;
}

.ps-block--category a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.ps-block--category p {
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 500;
}

.ps-block--category:hover {
  border-color: #F7E5CD;
}

.ps-block--category:hover p {
  color: #09c;
}

@media (max-width: 479px) {
  .ps-block--category {
    padding: 20px 10px;
  }
}

.ps-block--category-vertical h4 {
  font-weight: 400;
  font-size: 18px;
}

.ps-block--category-vertical .ps-block__content {
  padding: 18px 10px 15px;
}

.ps-block--category-vertical .ps-block__content ul li a {
  position: relative;
  display: inline-block;
  color: #666;
  line-height: 25px;
  position: relative;
}

.ps-block--category-vertical .ps-block__content ul li a:before {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #000;
  -webkit-transform: scale3d(0, 1, 1);
  -moz-transform: scale3d(0, 1, 1);
  -ms-transform: scale3d(0, 1, 1);
  -o-transform: scale3d(0, 1, 1);
  transform: scale3d(0, 1, 1);
  transition: transform 0.4s cubic-bezier(0.7, 0, 0.3, 1);
  -webkit-transform-origin: 100% 50%;
  -moz-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  -o-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}

.ps-block--category-vertical .ps-block__content ul li a:hover:before {
  -webkit-transform: scale3d(1, 1, 1);
  -moz-transform: scale3d(1, 1, 1);
  -ms-transform: scale3d(1, 1, 1);
  -o-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transform-origin: 0 50%;
  -moz-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
  -o-transform-origin: 0 50%;
  transform-origin: 0 50%;
}

.ps-block--category-2 {
  display: flex;
  margin-bottom: 30px;
  border: 1px solid #bfbfbf;
  transition: all 0.4s ease;
}

.ps-block--category-2 h4 {
  font-weight: 400;
  font-size: 18px;
}

.ps-block--category-2 .more i {
  padding-left: 6px;
}

.ps-block--category-2 .ps-block__thumbnail {
  max-width: 175px;
}

.ps-block--category-2 .ps-block__content {
  padding: 18px 10px 15px;
}

.ps-block--category-2 .ps-block__content ul li a {
  position: relative;
  display: inline-block;
  color: #666;
  line-height: 25px;
  position: relative;
}

.ps-block--category-2 .ps-block__content ul li a:before {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #000;
  -webkit-transform: scale3d(0, 1, 1);
  -moz-transform: scale3d(0, 1, 1);
  -ms-transform: scale3d(0, 1, 1);
  -o-transform: scale3d(0, 1, 1);
  transform: scale3d(0, 1, 1);
  transition: transform 0.4s cubic-bezier(0.7, 0, 0.3, 1);
  -webkit-transform-origin: 100% 50%;
  -moz-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  -o-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}

.ps-block--category-2 .ps-block__content ul li a:hover:before {
  -webkit-transform: scale3d(1, 1, 1);
  -moz-transform: scale3d(1, 1, 1);
  -ms-transform: scale3d(1, 1, 1);
  -o-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transform-origin: 0 50%;
  -moz-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
  -o-transform-origin: 0 50%;
  transform-origin: 0 50%;
}

.ps-block--category-2.ps-block--category-auto-part h4 {
  font-weight: 600;
  font-size: 16px;
}

.ps-block--category-2.ps-block--category-auto-part .ps-block__content ul li a {
  line-height: 20px;
}

.ps-block--category-2.ps-block--category-auto-part .ps-block__content ul li.more a {
  color: #1069c9;
}

.ps-block--category-2.ps-block--category-auto-part .ps-block__content ul li.more a i {
  font-size: 10px;
}

.ps-block--category-2:hover {
  border-color: #F7E5CD;
}

.ps-block--category-2:hover p {
  color: #09c;
}

@media (max-width: 1680px) {
  .ps-block--category-2 .ps-block__thumbnail {
    max-width: 100px;
    padding-top: 20px;
  }
}

.ps-block--user-header {
  display: flex;
  flex-flow: row nowrap;
  text-align: left;
}

.ps-block--user-header .ps-block__left {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.ps-block--user-header .ps-block__left i {
  font-size: 30px;
  line-height: 1em;
}

.ps-block--user-header .ps-block__right {
  padding-left: 10px;
}

.ps-block--user-header .ps-block__right a {
  display: block;
  font-weight: 700;
  line-height: 18px;
}

.ps-block--user-header .ps-block__right a:hover {
  color: #ffffff;
}

.ps-block--site-features {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 20px 40px;
  width: 100%;
  border: 1px solid #d9d9d9;
}

.ps-block--site-features .ps-block__left i {
  font-size: 40px;
  color: #F7E5CD;
}

.ps-block--site-features .ps-block__right {
  padding-left: 25px;
}

.ps-block--site-features .ps-block__right h4 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 0;
  color: #000;
}

.ps-block--site-features .ps-block__right p {
  margin-bottom: 0;
  color: #666;
}

.ps-block--site-features .ps-block__item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 10px 40px;
  border-right: 1px solid #dadada;
  max-width: 20%;
  width: 100%;
}

.ps-block--site-features .ps-block__item:first-child {
  padding-left: 0;
}

.ps-block--site-features .ps-block__item:last-child {
  border-right: none;
  padding-right: 0;
}

.ps-block--site-features.ps-block--site-features-2 {
  border: none;
  padding: 0;
}

.ps-block--site-features.ps-block--site-features-2 .ps-block__item {
  max-width: 25%;
  padding: 10px 30px;
}

@media (max-width: 1440px) {
  .ps-block--site-features.ps-block--site-features-2 .ps-block__item {
    flex-flow: row nowrap;
    border-right: 1px solid #dadada;
  }
  .ps-block--site-features.ps-block--site-features-2 .ps-block__item:last-child {
    border-right: none;
  }
  .ps-block--site-features.ps-block--site-features-2 .ps-block__left {
    margin-bottom: 15px;
  }
  .ps-block--site-features.ps-block--site-features-2 .ps-block__right {
    width: 100%;
    padding-left: 15px;
  }
}

@media (max-width: 991px) {
  .ps-block--site-features.ps-block--site-features-2 .ps-block__item {
    border-right: none;
  }
}

@media (max-width: 1680px) {
  .ps-block--site-features {
    padding: 30px;
  }
  .ps-block--site-features .ps-block__item {
    padding: 0 15px;
  }
}

@media (max-width: 1440px) {
  .ps-block--site-features .ps-block__item {
    flex-flow: row wrap;
    border-right: none;
  }
  .ps-block--site-features .ps-block__left {
    margin-bottom: 15px;
  }
  .ps-block--site-features .ps-block__right {
    width: 100%;
    padding-left: 0;
  }
}

@media (max-width: 1024px) {
  .ps-block--site-features {
    flex-flow: row wrap;
  }
  .ps-block--site-features .ps-block__item {
    margin-bottom: 30px;
    max-width: 25%;
    padding: 0;
  }
}

@media (max-width: 991px) {
  .ps-block--site-features {
    justify-content: flex-start;
  }
  .ps-block--site-features .ps-block__item {
    max-width: 50%;
    max-width: 33.33333%;
  }
  .ps-block--site-features.ps-block--site-features-2 .ps-block__item {
    max-width: 50%;
  }
}

@media (max-width: 420px) {
  .ps-block--site-features .ps-block__item {
    max-width: 100%;
    flex-flow: row nowrap;
  }
  .ps-block--site-features .ps-block__item .ps-block__right {
    padding-left: 20px;
  }
  .ps-block--site-features.ps-block--site-features-2 .ps-block__item {
    max-width: 100%;
  }
}

.ps-block--countdown-deal {
  display: flex;
  flex-flow: row nowrap;
}

.ps-block--countdown-deal .ps-block__left h3 {
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 400;
  margin-right: 70px;
}

.ps-block--countdown-deal figure {
  display: flex;
  align-items: center;
  background-color: #f14705;
  padding: 7px 20px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  border-radius: 3px;
  line-height: 1;
}

.ps-block--countdown-deal figure figcaption {
  color: #ffffff;
  font-weight: 600;
  margin-right: 20px;
}

.ps-block--countdown-deal .ps-countdown li {
  display: inline-block;
}

.ps-block--countdown-deal .ps-countdown li span {
  font-weight: 600;
}

.ps-block--countdown-deal .ps-countdown li:after {
  content: ':';
}

.ps-block--countdown-deal .ps-countdown li:last-child:after {
  display: none;
}

@media (max-width: 479px) {
  .ps-block--countdown-deal {
    flex-flow: row wrap;
  }
  .ps-block--countdown-deal .ps-block__left {
    margin-bottom: 10px;
  }
  .ps-block--countdown-deal .ps-block__left h3 {
    margin-right: 0;
  }
}

.ps-block--shop-features {
  margin-bottom: 40px;
}

.ps-block--shop-features .ps-block__header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 5px;
  border-bottom: 1px solid #f0f0f0;
}

.ps-block--shop-features .ps-block__header h3 {
  font-weight: 400;
  font-size: 24px;
  color: #000;
}

.ps-block--shop-features .ps-block__navigation a {
  display: inline-block;
  margin-right: 10px;
  color: #999;
}

.ps-block--shop-features .ps-block__navigation a:last-child {
  margin-right: 0;
}

.ps-block--shop-features .ps-block__navigation a:hover {
  color: #000;
}

.ps-block--average-rating .ps-block__header {
  margin-bottom: 20px;
}

.ps-block--average-rating .ps-block__header h3 {
  font-size: 58px;
  font-weight: 500;
  color: #690;
  line-height: 1;
}

.ps-block--average-rating .ps-block__header .br-wrapper {
  margin-bottom: 0;
}

.ps-block--average-rating .ps-block__header .br-wrapper a {
  font-size: 20px;
}

.ps-block--average-rating .ps-block__header span {
  display: block;
  color: #666;
}

.ps-block--average-rating .ps-block__star {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
  width: 100%;
  max-width: 360px;
}

.ps-block--average-rating .ps-block__star > span {
  min-width: 40px;
  color: #666;
}

.ps-block--average-rating .ps-block__star .ps-progress {
  position: relative;
  height: 10px;
  width: 100%;
  max-width: 230px;
  background-color: #f1f1f1;
}

.ps-block--average-rating .ps-block__star .ps-progress span {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  height: 100%;
  background-color: #690;
}

.ps-block--questions-answers h3 {
  margin-bottom: 30px;
  font-weight: 700;
  color: #000;
  font-size: 30px;
}

.ps-block--questions-answers .form-control {
  font-size: 18px;
}

.ps-block--testimonial {
  margin-top: 50px;
  position: relative;
  z-index: 1;
  background-color: #ffffff;
  padding: 0 40px;
}

.ps-block--testimonial .ps-block__header {
  position: relative;
  top: -45px;
  margin-bottom: -45px;
  z-index: 10;
}

.ps-block--testimonial .ps-block__header img {
  position: relative;
  max-height: 90px;
  border-radius: 50%;
  z-index: 10;
}

.ps-block--testimonial .ps-block__content {
  position: relative;
  padding: 60px 0 40px;
}

.ps-block--testimonial .ps-block__content > i {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 40px;
  color: #F7E5CD;
}

.ps-block--testimonial .ps-block__content h4 {
  font-size: 18px;
  font-weight: 600;
}

.ps-block--testimonial .ps-block__content h4 span {
  margin-left: 10px;
  font-size: 14px;
  color: #999999;
}

@media (max-width: 991px) {
  .ps-block--testimonial .ps-block__content {
    padding-top: 30px;
  }
}

@media (max-width: 767px) {
  .ps-block--testimonial .ps-block__content > i {
    display: none;
  }
}

@media (max-width: 479px) {
  .ps-block--testimonial {
    padding: 30px 20px;
  }
}

.ps-block--deal-hot {
  padding: 13px 30px 30px;
  border: 2px solid #F7E5CD;
}

.ps-block--deal-hot .ps-block__header {
  padding: 10px 0;
  margin-bottom: 30px;
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.ps-block--deal-hot .ps-block__header h3 {
  display: inline-block;
  line-height: 1;
  font-size: 20px;
  font-weight: 600;
}

.ps-block--deal-hot .ps-block__navigation a {
  position: relative;
  display: inline-block;
  margin-right: 15px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #ccc;
  vertical-align: top;
}

.ps-block--deal-hot .ps-block__navigation a i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 8px;
}

.ps-block--deal-hot .ps-block__navigation a:last-child {
  margin-right: 0;
}

.ps-block--deal-hot .ps-block__navigation a:hover {
  border-color: #dd2400;
}

.ps-block--deal-hot .ps-block__navigation a:hover i {
  color: #dd2400;
}

.ps-block--deal-hot .ps-product--hot-deal {
  margin-bottom: 0;
}

.ps-block--deal-hot .ps-product--detail .ps-product__thumbnail .ps-product__badge {
  width: 80px;
}

.ps-block--deal-hot .ps-product--detail .ps-product__thumbnail .ps-product__badge span {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media (max-width: 479px) {
  .ps-block--deal-hot {
    padding: 20px;
  }
}

@media screen and (min-width: 992px) {
  .ps-block--deal-hot {
    max-height: 500px;
  }
}

.ps-block--products-of-category {
  margin-bottom: 70px;
  display: flex;
  flex-flow: row nowrap;
}

.ps-block--products-of-category > * {
  width: 100%;
}

.ps-block--products-of-category .ps-block__categories {
  position: relative;
  padding: 30px 20px 40px 30px;
  background-color: #ffffff;
  max-width: 20%;
}

.ps-block--products-of-category .ps-block__categories h3 {
  margin-bottom: 35px;
  font-weight: 400;
  font-size: 24px;
}

.ps-block--products-of-category .ps-block__categories ul li a {
  display: block;
  padding: 3px 0;
  line-height: 20px;
  color: #000;
}

.ps-block--products-of-category .ps-block__categories ul li a:hover {
  color: #F7E5CD;
}

.ps-block--products-of-category .ps-block__categories .ps-block__more-link {
  position: absolute;
  bottom: 45px;
  left: 30px;
  font-style: italic;
  color: #666;
}

.ps-block--products-of-category .ps-block__slider {
  max-width: 24%;
}

.ps-block--products-of-category .ps-block__slider a {
  display: block;
}

.ps-block--products-of-category .ps-block__slider a img {
  width: 100%;
}

.ps-block--products-of-category .ps-block__product-box {
  display: flex;
  flex-flow: row wrap;
}

.ps-block--products-of-category .ps-block__product-box > * {
  max-width: 33.333333%;
}

.ps-block--products-of-category .ps-product--simple {
  background-color: #fff;
  border-right: 1px solid #e1e1e1;
  border-top: 1px solid #e1e1e1;
}

.ps-block--products-of-category .ps-product--simple:hover {
  border-color: #e1e1e1;
  border-left-color: transparent;
}

@media (max-width: 1199px) {
  .ps-block--products-of-category .ps-block__slider {
    display: none;
  }
}

@media (max-width: 991px) {
  .ps-block--products-of-category {
    flex-flow: row wrap;
  }
  .ps-block--products-of-category .ps-block__categories {
    max-width: 100%;
  }
  .ps-block--products-of-category .ps-block__categories h3 {
    margin-bottom: 20px;
  }
  .ps-block--products-of-category .ps-block__categories ul li {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .ps-block--products-of-category .ps-block__categories ul li:last-child {
    margin-right: 0;
  }
  .ps-block--products-of-category .ps-block__categories .ps-block__more-link {
    position: relative;
    bottom: 0;
    left: 0;
  }
  .ps-block--products-of-category .ps-block__product-box {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .ps-block--products-of-category {
    margin-bottom: 50px;
  }
  .ps-block--products-of-category .ps-block__product-box {
    border-bottom: 1px solid #e1e1e1;
    border-right: 1px solid #e1e1e1;
  }
  .ps-block--products-of-category .ps-block__product-box > * {
    max-width: 50%;
    flex-basis: 50%;
  }
  .ps-block--products-of-category .ps-product--simple {
    padding: 20px;
    border: 1px solid #e1e1e1;
    border-right: none;
    border-bottom: none;
  }
}

@media (max-width: 479px) {
  .ps-block--products-of-category {
    margin-bottom: 35px;
  }
  .ps-block--products-of-category .ps-product--simple {
    padding: 10px;
  }
}

.ps-block--recent-viewed .ps-block__header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding-bottom: 25px;
}

.ps-block--recent-viewed .ps-block__header h3 {
  font-size: 24px;
  font-weight: 400;
}

.ps-block--recent-viewed .ps-block__header a {
  text-decoration: underline;
}

.ps-block--recent-viewed .ps-block__header a:hover {
  color: #F7E5CD;
}

.ps-block--recent-viewed .ps-block__content {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  background-color: #fff;
  padding: 20px;
}

.ps-block--recent-viewed .ps-block__content a {
  margin-right: 20px;
  max-width: 7%;
  border: 2px solid transparent;
}

.ps-block--recent-viewed .ps-block__content a:hover {
  border-color: #F7E5CD;
}

.ps-block--categories-tabs {
  background-color: #fff;
}

.ps-block--categories-tabs .ps-block__header {
  padding: 0 80px;
}

.ps-block--categories-tabs .ps-tab-list a {
  display: block;
  padding: 20px 0;
  text-align: center;
  border-bottom: 2px solid transparent;
}

.ps-block--categories-tabs .ps-tab-list a i {
  font-size: 36px;
}

.ps-block--categories-tabs .ps-tab-list a span {
  display: block;
}

.ps-block--categories-tabs .ps-tab-list a:hover {
  border-color: #F7E5CD;
}

.ps-block--categories-tabs .ps-tab-list a:hover i {
  color: #F7E5CD;
}

.ps-block--categories-tabs .ps-tab-list a.active {
  border-color: #F7E5CD;
}

.ps-block--categories-tabs .ps-tab-list a.active i {
  color: #F7E5CD;
}

.ps-block--categories-tabs .ps-block__item {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  text-align: center;
}

.ps-block--categories-tabs .ps-block__item a {
  display: block;
  width: 150px;
  max-width: calc(100% / 8);
  margin-bottom: 40px;
  overflow: hidden;
}

.ps-block--categories-tabs .ps-block__item a img {
  max-height: 120px;
  transition: all 0.4s ease-out;
}

.ps-block--categories-tabs .ps-block__item a span {
  display: block;
  color: #666666;
}

.ps-block--categories-tabs .ps-block__item a:hover img {
  -webkit-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
}

.ps-block--categories-tabs .ps-tabs {
  border-top: 1px solid #e1e1e1;
}

.ps-block--categories-tabs .ps-tab {
  padding: 20px 60px 60px;
}

@media (max-width: 1680px) {
  .ps-block--categories-tabs .owl-slider .owl-nav .owl-prev {
    margin-left: -30px;
  }
  .ps-block--categories-tabs .owl-slider .owl-nav .owl-next {
    margin-right: -30px;
  }
}

@media (max-width: 1199px) {
  .ps-block--categories-tabs .ps-block__header {
    padding: 0 30px;
  }
  .ps-block--categories-tabs .ps-block__header .ps-tab-list a {
    border: none;
  }
  .ps-block--categories-tabs .ps-block__header .ps-tab-list a i {
    margin-bottom: 10px;
    font-size: 30px;
  }
  .ps-block--categories-tabs .ps-block__header .ps-tab-list a span {
    font-size: 12px;
  }
  .ps-block--categories-tabs .ps-tab {
    padding: 20px 30px;
  }
}

@media (max-width: 991px) {
  .ps-block--categories-tabs .ps-block__item {
    margin-bottom: 30px;
  }
  .ps-block--categories-tabs .ps-block__item a {
    max-width: calc(100% / 5);
  }
}

@media (max-width: 767px) {
  .ps-block--categories-tabs .ps-tab {
    padding: 20px 15px;
  }
  .ps-block--categories-tabs .ps-block__item {
    margin-bottom: 30px;
    padding: 0 10px;
  }
  .ps-block--categories-tabs .ps-block__item a {
    max-width: 25%;
  }
}

@media (max-width: 479px) {
  .ps-block--categories-tabs .ps-block__header {
    padding: 0 15px;
  }
  .ps-block--categories-tabs .ps-block__item a {
    max-width: 33.3333%;
  }
  .ps-block--categories-tabs .ps-tab {
    padding: 20px 10px;
  }
}

.ps-block--product-box {
  background-color: #ffffff;
}

.ps-block--product-box .owl-slider .owl-item img {
  width: 100%;
}

.ps-block--product-box .ps-block__header {
  border-bottom: 1px solid #e1e1e1;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 14px 20px;
}

.ps-block--product-box .ps-block__header h3 {
  margin-bottom: 0;
  display: inline-block;
  font-weight: 400;
  color: #000;
  font-size: 20px;
}

.ps-block--product-box .ps-block__header h3 i {
  margin-right: 10px;
}

.ps-block--product-box .ps-block__header ul li {
  display: inline-block;
  margin-right: 15px;
}

.ps-block--product-box .ps-block__header ul li:last-child {
  margin-right: 0;
}

.ps-block--product-box .ps-block__content {
  display: flex;
  flex-flow: row nowrap;
}

.ps-block--product-box .ps-block__content .ps-block__left {
  padding: 20px;
  max-width: calc(100% - 320px);
  border-right: 1px solid #e1e1e1;
}

.ps-block--product-box .ps-block__content .ps-block__right {
  max-width: 320px;
  padding: 20px;
}

.ps-block--product-box .ps-block__left .owl-slider {
  margin-bottom: 20px;
}

.ps-block--product-box .ps-block__right figure figcaption {
  margin-bottom: 20px;
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
  color: #000;
  border-bottom: 1px solid #e1e1e1;
}

.ps-block--product-box .ps-block__right .ps-product--horizontal {
  margin-bottom: 30px;
}

.ps-block--product-box .ps-block__right .ps-product--horizontal .ps-product__thumbnail {
  max-width: 60px;
}

.ps-block--product-box .ps-block__right .ps-product--horizontal .ps-product__rating {
  display: none;
}

.ps-block--product-box .ps-block__products .ps-tab-list {
  display: block;
  padding-bottom: 15px;
  margin-bottom: 20px;
  border-bottom: 1px solid #e1e1e1;
}

.ps-block--product-box .ps-block__products .ps-tab-list li {
  display: inline-block;
  margin-right: 20px;
}

.ps-block--product-box .ps-block__products .ps-tab-list li a {
  line-height: 20px;
  font-size: 16px;
  color: #666;
}

.ps-block--product-box .ps-block__products .ps-tab-list li.active a {
  color: #000;
}

.ps-block--product-box .ps-block__products .ps-tab-list li:last-child {
  margin-right: 0;
}

.ps-block--product-box .ps-block__products .row {
  margin: 0;
}

.ps-block--product-box .ps-block__products .row > * {
  padding: 0;
}

@media (max-width: 1199px) {
  .ps-block--product-box .ps-block__header {
    flex-flow: row wrap;
  }
  .ps-block--product-box .ps-block__header h3 {
    margin-bottom: 10px;
    display: block;
  }
}

@media (max-width: 991px) {
  .ps-block--product-box .ps-block__content {
    flex-flow: column wrap;
  }
  .ps-block--product-box .ps-block__content .ps-block__left,
  .ps-block--product-box .ps-block__content .ps-block__right {
    max-width: 100%;
  }
}

.ps-block--categories-box {
  margin-bottom: 30px;
  padding: 20px;
  background-color: #ffffff;
}

.ps-block--categories-box .ps-block__header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e1e1e1;
  align-items: center;
}

.ps-block--categories-box .ps-block__header h3 {
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 400;
  color: #000;
  line-height: 1em;
}

.ps-block--categories-box .ps-block__header ul li {
  display: inline-block;
  margin-right: 20px;
}

.ps-block--categories-box .ps-block__header ul li a {
  color: #000;
}

.ps-block--categories-box .ps-block__header ul li a:hover {
  color: #F7E5CD;
}

.ps-block--categories-box .ps-block__header ul li:last-child {
  margin-right: 0;
}

.ps-block--categories-box .ps-block__banner {
  width: 100%;
  max-width: calc(100% / 5 * 3);
}

.ps-block--categories-box .ps-block__item {
  position: relative;
  margin-bottom: 30px;
  padding: 20px;
  border: 1px solid transparent;
  transition: all 0.4s ease;
}

.ps-block--categories-box .ps-block__item a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.ps-block--categories-box .ps-block__item img {
  margin-bottom: 10px;
  max-height: 190px;
}

.ps-block--categories-box .ps-block__item p {
  margin-bottom: 0;
  color: #000;
  font-size: 16px;
  transition: all 0.4s ease;
}

.ps-block--categories-box .ps-block__item span {
  color: #666666;
}

.ps-block--categories-box .ps-block__item:hover {
  border-color: silver;
}

.ps-block--categories-box .ps-block__item:hover p {
  color: #F7E5CD;
}

.ps-block--categories-box .ps-block__content {
  display: flex;
  flex-flow: row wrap;
}

.ps-block--categories-box .ps-block__content .ps-block__item {
  width: 100%;
  max-width: 20%;
}

@media (max-width: 1199px) {
  .ps-block--categories-box .ps-block__banner {
    max-width: 100%;
    margin-bottom: 30px;
  }
  .ps-block--categories-box .ps-block__banner img {
    width: 100%;
  }
  .ps-block--categories-box .ps-block__item {
    margin-bottom: 0;
  }
  .ps-block--categories-box .ps-block__content .ps-block__item {
    max-width: 25%;
  }
}

@media (max-width: 991px) {
  .ps-block--categories-box .ps-block__content .ps-block__item {
    max-width: 33.333%;
  }
}

@media (max-width: 767px) {
  .ps-block--categories-box .ps-block__item {
    padding: 10px;
    text-align: center;
  }
  .ps-block--categories-box .ps-block__item p {
    margin-bottom: 0;
    font-size: 14px;
  }
  .ps-block--categories-box .ps-block__header h3 {
    font-size: 20px;
  }
}

@media (max-width: 580px) {
  .ps-block--categories-box .ps-block__content .ps-block__item {
    max-width: 50%;
    flex-basis: 50%;
  }
}

@media (max-width: 479px) {
  .ps-block--categories-box .ps-block__item {
    padding: 0;
    margin-bottom: 15px;
  }
  .ps-block--categories-box .ps-block__item p {
    font-size: 13px;
  }
  .ps-block--categories-box .ps-block__item span {
    font-size: 12px;
  }
  .ps-block--categories-box .ps-block__header {
    flex-flow: row wrap;
  }
  .ps-block--categories-box .ps-block__header h3 {
    display: block;
    margin-bottom: 10px;
    font-size: 18px;
  }
}

.ps-block--category-room {
  margin-bottom: 20px;
  text-align: center;
}

.ps-block--category-room .ps-block__thumbnail {
  overflow: hidden;
}

.ps-block--category-room .ps-block__thumbnail img {
  width: 100%;
  transition: all 0.4s ease;
}

.ps-block--category-room .ps-block__thumbnail:hover img {
  -webkit-transform: scale3d(1.05, 1.05, 1.05);
  -moz-transform: scale3d(1.05, 1.05, 1.05);
  -ms-transform: scale3d(1.05, 1.05, 1.05);
  -o-transform: scale3d(1.05, 1.05, 1.05);
  transform: scale3d(1.05, 1.05, 1.05);
}

.ps-block--category-room .ps-block__content {
  padding: 10px;
}

.ps-block--category-room a {
  font-size: 16px;
  color: #000;
}

.ps-block--category-room a:hover {
  color: #0066cc;
}

.ps-block--container-hightlight .ps-section__header {
  padding: 16px 0;
  text-align: center;
  background-color: #F7E5CD;
}

.ps-block--container-hightlight .ps-section__header h3 {
  font-size: 22px;
  margin-bottom: 0;
  font-weight: 600;
  color: #000;
  line-height: 30px;
}

@media (max-width: 767px) {
  .ps-block--container-hightlight .ps-section__header h3 {
    font-size: 16px;
  }
}

.ps-block--container-hightlight .ps-section__content {
  padding: 30px 20px;
  border: 2px solid #F7E5CD;
  border-top: none;
}

@media (min-width: 1680px) {
  .ps-block--container-hightlight .ps-section__content .row {
    margin: 0;
  }
  .ps-block--container-hightlight .ps-section__content .col-xl-3 {
    max-width: 20%;
    padding: 0;
  }
}

@media (max-width: 767px) {
  .ps-block--container-hightlight .row {
    margin: 0 -2px;
  }
  .ps-block--container-hightlight .row > * {
    padding: 0 2px;
  }
  .ps-block--container-hightlight .ps-product {
    margin-bottom: 4px;
  }
}

.ps-block--categories-grid {
  display: flex;
  flex-flow: row wrap;
  background-color: #fff;
}

.ps-block--categories-grid .ps-block--category-2 {
  margin-bottom: 0;
  width: 100%;
  max-width: calc(100% / 3);
  border: none;
  border-right: 1px solid rgba(141, 141, 141, 0.15);
  border-bottom: 1px solid rgba(141, 141, 141, 0.15);
}

.ps-block--categories-grid .ps-block--category-2 .ps-block__thumbnail {
  max-width: 100px;
  padding: 10px;
}

.ps-block--categories-grid .ps-block--category-2 .ps-block__content > a {
  color: #06c;
}

.ps-block--categories-grid .ps-block--category-2:nth-child(4), .ps-block--categories-grid .ps-block--category-2:nth-child(5), .ps-block--categories-grid .ps-block--category-2:nth-child(6) {
  border-bottom: none;
}

.ps-block--categories-grid .ps-block--category-2:nth-child(3), .ps-block--categories-grid .ps-block--category-2:nth-child(6) {
  border-right: none;
}

@media (max-width: 991px) {
  .ps-block--categories-grid {
    border-top: 1px solid rgba(141, 141, 141, 0.15);
    border-left: 1px solid rgba(141, 141, 141, 0.15);
  }
  .ps-block--categories-grid .ps-block--category-2 {
    max-width: 50%;
  }
  .ps-block--categories-grid .ps-block--category-2:nth-child(4), .ps-block--categories-grid .ps-block--category-2:nth-child(5), .ps-block--categories-grid .ps-block--category-2:nth-child(6) {
    border-bottom: 1px solid rgba(141, 141, 141, 0.15);
  }
  .ps-block--categories-grid .ps-block--category-2:nth-child(3), .ps-block--categories-grid .ps-block--category-2:nth-child(6) {
    border-right: 1px solid rgba(141, 141, 141, 0.15);
  }
}

@media (max-width: 375px) {
  .ps-block--categories-grid .ps-block--category-2 {
    max-width: 100%;
  }
}

.ps-block--menu-categories {
  position: relative;
  background-color: #ffffff;
  padding: 25px 20px;
}

.ps-block--menu-categories .ps-block__header {
  padding-bottom: 20px;
}

.ps-block--menu-categories .ps-block__header h3 {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 400;
  color: #000;
  text-transform: uppercase;
}

.ps-block--menu-categories .ps-list--menu-cateogories > li > a {
  display: block;
  padding: 10px 0;
  font-size: 16px;
  line-height: 26px;
  border-bottom: 1px solid #f0f0f0;
}

.ps-block--menu-categories .ps-list--menu-cateogories > li:last-child > a {
  border-bottom: none;
}

.ps-block--menu-categories .ps-list--menu-cateogories > li.menu-item-has-children .sub-menu {
  border: 1px solid transparent;
  -webkit-transform: translateX(30px);
  -moz-transform: translateX(30px);
  -ms-transform: translateX(30px);
  -o-transform: translateX(30px);
  transform: translateX(30px);
}

.ps-block--menu-categories .ps-list--menu-cateogories > li.menu-item-has-children:hover > .sub-menu {
  visibility: visible;
  opacity: 1;
  border: 1px solid #F7E5CD;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}

.ps-block--menu-categories .ps-list--menu-cateogories .sub-menu {
  top: 0;
  left: 100%;
  height: 100%;
  padding: 20px;
  z-index: 20;
  background-color: #fff;
}

.ps-block--menu-categories .ps-list--menu-cateogories .sub-menu > li > a {
  padding: 5px 0;
  font-size: 14px;
  color: #666;
  border-bottom: none;
}

.ps-block--menu-categories .ps-list--menu-cateogories .sub-menu > li > a:hover {
  background-color: #F7E5CD;
}

@media (max-width: 1199px) {
  .ps-block--menu-categories {
    display: none;
  }
}

.ps-block--author {
  display: flex;
  flex-flow: row nowrap;
  padding: 60px;
  background-color: #f0f0f0;
}

.ps-block--author > * {
  width: 100%;
}

.ps-block--author .ps-block__thumbnail {
  max-width: 130px;
}

.ps-block--author .ps-block__content {
  padding-left: 30px;
}

.ps-block--author .ps-block__content h4 {
  margin-bottom: 20px;
  color: #000;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.2em;
}

.ps-block--post--navigation {
  display: flex;
  justify-content: space-between;
  padding-bottom: 90px;
  border-bottom: 1px solid #e1e1e1;
}

.ps-block--post--navigation .ps-block__link {
  width: 50%;
}

.ps-block--post--navigation .ps-block__link:last-child {
  text-align: right;
}

.ps-block--post--navigation .ps-block__link:last-child i {
  margin-left: 10px;
  vertical-align: middle;
}

.ps-block--post--navigation .ps-block__arrow {
  display: block;
  margin-bottom: 10px;
  font-size: 24px;
}

.ps-block--post--navigation .ps-block__title {
  font-size: 18px;
  font-weight: 600;
  color: #000;
}

.ps-block--comment {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 0;
}

.ps-block--comment > * {
  width: 100%;
}

.ps-block--comment > .ps-block__thumbnail {
  max-width: 100px;
}

.ps-block--comment > .ps-block__thumbnail img {
  max-width: 70px;
}

.ps-block--comment > .ps-block__content {
  padding: 15px 0 30px 20px;
  border-top: 1px solid #e1e1e1;
}

.ps-block--comment > .ps-block__content h5 {
  margin-bottom: 16px;
  font-weight: 600;
  color: #333333;
  font-size: 14px;
}

.ps-block--comment > .ps-block__content h5 small {
  display: inline-block;
  font-size: 12px;
  color: #666666;
}

.ps-block--comment > .ps-block__content h5 small:before {
  content: '-';
  margin: 0 10px;
}

.ps-block--comment > .ps-block__content p {
  margin-bottom: 20px;
}

.ps-block--comment > .ps-block__content > .ps-block__reply {
  font-style: italic;
  color: #09c;
}

.ps-block--comment > .ps-block__content > .ps-block__reply:hover {
  color: #F7E5CD;
}

.ps-block--comment > .ps-block__content > .ps-block--comment {
  margin-top: 30px;
}

.ps-block--comment > .ps-block__content > .ps-block--comment > .ps-block__content {
  padding: 15px 0 30px 0;
  border-top: 1px solid #e1e1e1;
}

@media (max-width: 767px) {
  .ps-block--comment > .ps-block__thumbnail {
    max-width: 90px;
  }
  .ps-block--comment > .ps-block__content {
    padding: 10px 0 30px 0;
  }
}

@media (max-width: 479px) {
  .ps-block--comment {
    flex-flow: column wrap;
  }
  .ps-block--comment > .ps-block__content > .ps-block--comment {
    margin-left: 30px;
  }
}

.ps-block--icon-box {
  text-align: center;
}

.ps-block--icon-box i {
  display: inline-block;
  margin-bottom: 30px;
  font-size: 88px;
  color: #F7E5CD;
}

.ps-block--icon-box h4 {
  font-size: 48px;
  color: #000;
  font-weight: 500;
  letter-spacing: 0.05em;
}

.ps-block--icon-box p {
  font-size: 16px;
}

@media (max-width: 767px) {
  .ps-block--icon-box {
    margin-bottom: 20px;
    text-align: center;
  }
  .ps-block--icon-box i {
    font-size: 60px;
    margin-bottom: 20px;
  }
  .ps-block--icon-box h4 {
    font-size: 24px;
  }
  .ps-block--icon-box p {
    font-size: 14px;
  }
}

.ps-block--icon-box-2 {
  text-align: center;
}

.ps-block--icon-box-2 .ps-block__thumbnail {
  margin-bottom: 30px;
  min-height: 130px;
}

.ps-block--icon-box-2 .ps-block__desc {
  margin-bottom: 20px;
}

.ps-block--icon-box-2 img {
  max-height: 130px;
}

.ps-block--icon-box-2 h4 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
  line-height: 1.4em;
}

.ps-block--icon-box-2 p {
  font-size: 16px;
}

.ps-block--icon-box-2 a {
  text-decoration: underline;
  font-size: 16px;
}

.ps-block--milestone {
  position: relative;
  margin-bottom: 300px;
}

.ps-block--milestone .ps-block__dot {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ccc;
}

.ps-block--milestone .ps-block__dot:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #F7E5CD;
  visibility: hidden;
  opacity: 0;
}

.ps-block--milestone .ps-block__year-number {
  display: block;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
  color: #000;
}

.ps-block--milestone .ps-block__year {
  text-align: center;
}

.ps-block--milestone .ps-block__journey {
  position: absolute;
  top: 100%;
  left: 0;
  margin-bottom: 50px;
  min-width: 500px;
  max-width: 500px;
  padding: 20px;
  display: none;
  background-color: #ffffff;
  padding: 20px;
  margin-top: 50px;
}

.ps-block--milestone .ps-block__journey::after {
  clear: both;
  content: "";
  display: table;
}

.ps-block--milestone .ps-block__journey:before {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 0;
  height: 0;
  width: 0;
  border-bottom: 50px solid gray;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}

.ps-block--milestone .ps-block__journey .ps-block__thumbnail {
  float: left;
  max-width: 100px;
}

.ps-block--milestone .ps-block__journey .ps-block__thumbnail img {
  border-radius: 50%;
}

.ps-block--milestone .ps-block__journey .ps-block__content {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  float: right;
  min-height: 100px;
  width: calc(100% - 100px);
}

.ps-block--milestone .ps-block__journey .ps-block__content h4 {
  font-weight: 500;
  color: #000;
  font-size: 20px;
}

.ps-block--milestone.active .ps-block__dot:before {
  visibility: visible;
  opacity: 1;
}

.ps-block--milestone.active .ps-block__journey {
  display: block;
}

.ps-block--milestones {
  display: flex;
  justify-content: space-between;
}

.ps-block--milestones > * {
  width: 100%;
}

.ps-block--contact-info {
  max-width: 270px;
  margin: 0 auto 50px;
  text-align: center;
}

.ps-block--contact-info h4 {
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 20px;
  color: #000;
}

.ps-block--contact-info p a {
  display: block;
}

.ps-block--vendor-milestone {
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  padding-bottom: 90px;
}

.ps-block--vendor-milestone:before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  height: 100%;
  width: 2px;
  background-color: #F7E5CD;
}

.ps-block--vendor-milestone .ps-block__left,
.ps-block--vendor-milestone .ps-block__right {
  width: 100%;
}

.ps-block--vendor-milestone .ps-block__left {
  padding-right: 140px;
}

.ps-block--vendor-milestone .ps-block__left h4 {
  margin-bottom: 30px;
  font-size: 22px;
  font-weight: 600;
  color: #000;
  line-height: 1.2em;
}

.ps-block--vendor-milestone .ps-block__left ul {
  padding-left: 20px;
}

.ps-block--vendor-milestone .ps-block__left ul li {
  margin-bottom: 20px;
  font-size: 16px;
  color: #666;
}

.ps-block--vendor-milestone .ps-block__right {
  text-align: right;
}

.ps-block--vendor-milestone .ps-block__number {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100px;
  height: 100px;
  background-color: #ffffff;
  border: 2px solid #F7E5CD;
  border-radius: 50%;
}

.ps-block--vendor-milestone .ps-block__number span {
  font-size: 48px;
  color: #000;
}

.ps-block--vendor-milestone.reverse {
  flex-flow: row-reverse nowrap;
}

.ps-block--vendor-milestone.reverse .ps-block__left {
  padding-left: 140px;
  padding-right: 0;
}

.ps-block--vendor-milestone.reverse .ps-block__right {
  text-align: left;
}

.ps-block--vendor-milestone:last-child:before {
  display: none;
}

@media (max-width: 991px) {
  .ps-block--vendor-milestone .ps-block__left {
    padding-right: 80px;
  }
  .ps-block--vendor-milestone .ps-block__left h4 {
    font-size: 18px;
  }
  .ps-block--vendor-milestone .ps-block__left ul li {
    font-size: 14px;
  }
  .ps-block--vendor-milestone .ps-block__right {
    padding-left: 80px;
  }
  .ps-block--vendor-milestone .ps-block__number {
    width: 80px;
    height: 80px;
  }
  .ps-block--vendor-milestone .ps-block__number span {
    font-size: 24px;
  }
  .ps-block--vendor-milestone.reverse .ps-block__left {
    padding-left: 80px;
  }
  .ps-block--vendor-milestone.reverse .ps-block__right {
    padding-left: 0;
    padding-right: 80px;
  }
}

@media (max-width: 767px) {
  .ps-block--vendor-milestone {
    flex-flow: column-reverse wrap;
    padding-left: 100px;
  }
  .ps-block--vendor-milestone:before {
    left: 40px;
  }
  .ps-block--vendor-milestone .ps-block__number {
    left: 0;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  .ps-block--vendor-milestone .ps-block__left {
    padding: 0;
  }
  .ps-block--vendor-milestone .ps-block__left h4 {
    font-size: 18px;
  }
  .ps-block--vendor-milestone .ps-block__right {
    text-align: center;
    margin-bottom: 40px;
    padding: 40px 0 0;
  }
  .ps-block--vendor-milestone .ps-block__right img {
    max-height: 150px;
  }
  .ps-block--vendor-milestone.reverse {
    flex-flow: column-reverse wrap;
  }
  .ps-block--vendor-milestone.reverse .ps-block__left {
    padding: 0;
  }
  .ps-block--vendor-milestone.reverse .ps-block__right {
    padding: 0;
    text-align: center;
  }
}

@media (max-width: 479px) {
  .ps-block--vendor-milestone {
    padding-left: 60px;
  }
  .ps-block--vendor-milestone:before {
    left: 25px;
  }
  .ps-block--vendor-milestone .ps-block__number {
    width: 50px;
    height: 50px;
  }
  .ps-block--vendor-milestone .ps-block__number span {
    font-size: 20px;
  }
}

.ps-block--shopping-total {
  margin-bottom: 30px;
  padding: 30px 35px;
  background-color: #f1f1f1;
  border: 1px solid #bfbfbf;
}

.ps-block--shopping-total .ps-block__product li {
  margin-bottom: 10px;
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.ps-block--shopping-total .ps-block__product span {
  display: block;
  font-size: 16px;
  line-height: 24px;
}

.ps-block--shopping-total .ps-block__product .ps-block__shop {
  color: #000;
}

.ps-block--shopping-total .ps-block__product .ps-block__shipping {
  color: #666;
}

.ps-block--shopping-total .ps-block__product .ps-block__estimate strong {
  font-weight: 600;
}

.ps-block--shopping-total .ps-block__product .ps-block__estimate a {
  display: block;
  margin-left: 40px;
  font-size: 14px;
}

.ps-block--shopping-total h3 {
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 600;
}

.ps-block--shopping-total h3 span {
  color: #ff3300;
  font-weight: 600;
  float: right;
}

.ps-block--shopping-total .ps-block__header {
  display: block;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
}

.ps-block--shopping-total .ps-block__header p {
  font-size: 16px;
}

.ps-block--shopping-total .ps-block__header p span {
  float: right;
}

.ps-block--bought-toggether {
  margin-bottom: 30px;
  padding: 20px;
  border: 1px solid #e1e1e1;
}

.ps-block--bought-toggether h4 {
  margin-bottom: 40px;
}

.ps-block--bought-toggether .ps-block__items {
  display: flex;
  flex-flow: row wrap;
}

.ps-block--bought-toggether .ps-block__items .ps-block__item {
  max-width: 20%;
}

.ps-block--bought-toggether .ps-block__item {
  position: relative;
  padding-right: 50px;
}

.ps-block--bought-toggether .ps-block__item:first-child:after {
  content: '+';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 10px;
  font-weight: 400;
  font-size: 26px;
  color: #666;
}

.ps-block--bought-toggether .ps-block__item.ps-block__total:after {
  display: none;
}

.ps-block--bought-toggether .ps-block__total p strong {
  color: red;
  font-size: 20px;
  font-weight: 500;
}

.ps-block--bought-toggether .ps-block__total .ps-btn {
  padding: 10px 20px;
  margin-bottom: 10px;
  font-size: 14px;
  min-width: 180px;
  text-align: center;
  font-weight: 500;
}

.ps-block--bought-toggether .ps-block__total .ps-btn--outline {
  color: #000;
  border: 1px solid #ccc;
}

.ps-block--bought-toggether .ps-block__total .ps-btn--outline:hover {
  background-color: #F7E5CD;
  border-color: #F7E5CD;
}

.ps-block--bought-toggether .ps-btn--outline {
  background-color: #fff;
}

.ps-block--bought-toggether .ps-product--simple .ps-product__price {
  font-size: 16px;
  font-weight: 400;
}

.ps-block--bought-toggether .ps-block__footer .ps-checkbox {
  margin-bottom: 10px;
}

.ps-block--bought-toggether .ps-block__footer .ps-checkbox label strong {
  color: #000;
  font-weight: 600;
}

.ps-block--bought-toggether .ps-block__footer .ps-checkbox label span {
  color: red;
  font-weight: 500;
}

@media (max-width: 1199px) {
  .ps-block--bought-toggether .ps-block__items .ps-block__item {
    max-width: 33.333%;
  }
}

@media (max-width: 767px) {
  .ps-block--bought-toggether .ps-block__items {
    margin-bottom: 20px;
  }
  .ps-block--bought-toggether .ps-block__items .ps-block__item {
    max-width: 50%;
  }
}

@media (max-width: 479px) {
  .ps-block--bought-toggether .ps-block__item {
    padding-right: 0;
  }
  .ps-block--bought-toggether .ps-block__item:first-child:after {
    display: none;
  }
}

.ps-block--instagram {
  position: relative;
  z-index: 10;
  display: block;
}

.ps-block--instagram:before, .ps-block--instagram:after {
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s ease;
}

.ps-block--instagram:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
}

.ps-block--instagram:before {
  content: '\f16d';
  font-family: FontAwesome;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 20;
  color: #ffffff;
  font-size: 30px;
}

.ps-block--instagram:hover:before, .ps-block--instagram:hover:after {
  visibility: visible;
  opacity: 1;
}

.ps-block--instagram:hover:after {
  transition-delay: 0.25s;
}

.ps-block--header-hotline {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.ps-block--header-hotline .ps-block__left i {
  font-size: 26px;
  color: #000;
}

.ps-block--header-hotline .ps-block__right {
  padding-left: 20px;
}

.ps-block--header-hotline .ps-block__right p {
  margin-bottom: 0;
  line-height: 1.4em;
}

.ps-block--header-hotline .ps-block__right p strong {
  display: block;
  font-size: 16px;
  color: #000;
}

.ps-block--header-hotline.inline p {
  margin-bottom: 0;
  font-size: 16px;
  color: #000;
}

.ps-block--header-hotline.inline p i {
  margin-right: 10px;
}

.ps-block--header-hotline.inline p strong {
  font-weight: 600;
  color: #000;
}

.ps-block--promotion-header {
  padding: 10px 0;
}

.ps-block--promotion-header p {
  margin-bottom: 0;
}

.ps-block--promotion-header .container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.ps-block--promotion-header .container > * {
  margin-right: 50px;
}

.ps-block--promotion-header .ps-btn {
  background-color: #f14705;
  color: #ffffff;
  font-size: 14px;
  padding: 8px 20px;
}

.ps-block--promotion-header .ps-btn:hover {
  background-color: #F7E5CD;
}

.ps-block--promotion-header .ps-block__left {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.ps-block--promotion-header .ps-block__left h3 {
  line-height: 1;
  font-size: 48px;
  color: #f14705;
  font-weight: 300;
}

.ps-block--promotion-header .ps-block__left h4 {
  font-weight: 400;
  font-size: 24px;
  margin: 0;
}

.ps-block--promotion-header .ps-block__left figure {
  padding-left: 20px;
}

.ps-block--promotion-header .ps-block__center span {
  display: block;
  font-weight: 400;
  font-size: 18px;
  color: #f14705;
}

@media (max-width: 1199px) {
  .ps-block--promotion-header {
    display: none;
  }
}

.ps-block--ourteam {
  position: relative;
  z-index: 10;
  overflow: hidden;
}

.ps-block--ourteam img {
  width: 100%;
}

.ps-block--ourteam:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(252, 184, 0, 0.95);
  z-index: 10;
  transition: all 0.4s ease;
  visibility: hidden;
  opacity: 0;
}

.ps-block--ourteam .ps-block__content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 30px 20px;
  z-index: 30;
  transition: all 0.4s ease;
  -webkit-transform: translateY(50px);
  -moz-transform: translateY(50px);
  -ms-transform: translateY(50px);
  -o-transform: translateY(50px);
  transform: translateY(50px);
  visibility: hidden;
  opacity: 0;
}

.ps-block--ourteam .ps-block__content h4 {
  font-weight: 600;
}

.ps-block--ourteam .ps-block__content p {
  color: #000;
}

.ps-block--ourteam .ps-block__content ul li {
  display: inline-block;
  margin-right: 10px;
}

.ps-block--ourteam .ps-block__content ul li a {
  font-size: 16px;
}

.ps-block--ourteam .ps-block__content ul li a:hover {
  color: #ffffff;
}

.ps-block--ourteam .ps-block__content ul li:last-child {
  margin-right: 0;
}

.ps-block--ourteam.blank {
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.ps-block--ourteam.blank:before {
  display: none;
}

.ps-block--ourteam.blank a {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 18px;
}

.ps-block--ourteam.blank a:hover {
  color: #F7E5CD;
}

.ps-block--ourteam:hover:before {
  visibility: visible;
  opacity: 1;
}

.ps-block--ourteam:hover .ps-block__content {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}

.ps-collection {
  display: block;
}

.ps-collection img {
  width: 100%;
}

@media (max-width: 767px) {
  .ps-collection {
    margin-bottom: 30px;
  }
}

.ps-block--vendor .ps-block__divider {
  display: block;
  padding-top: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #d5d5d5;
}

.ps-block--vendor .ps-block__thumbnail img {
  width: 100%;
}

.ps-block--vendor .ps-block__container {
  padding: 25px;
  background-color: #f1f1f1;
}

.ps-block--vendor .ps-block__container .ps-block__header h4 {
  font-size: 20px;
  color: #000;
  font-weight: 500;
  text-transform: uppercase;
}

.ps-block--vendor .ps-block__container .ps-block__header p strong {
  color: #669900;
  font-weight: 600;
}

.ps-block--vendor .ps-block__container p {
  color: #666;
}

.ps-block--vendor .ps-block__container p strong {
  font-weight: 500;
  color: #000;
}

.ps-block--vendor .ps-block__container figure {
  margin-bottom: 20px;
}

.ps-block--vendor .ps-block__container figure figcaption {
  display: block;
  margin-bottom: 10px;
  font-weight: 500;
  color: #000;
}

.ps-block--vendor .ps-block__container .ps-block__footer p {
  margin-bottom: 16px;
}

.ps-block--vendor .ps-block__container .ps-block__footer p strong {
  font-size: 20px;
  font-weight: 600;
  display: block;
  color: #000;
}

.ps-block--vendor-filter {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 10px 10px;
  background-color: #f5f5f5;
}

.ps-block--vendor-filter .ps-block__left {
  display: flex;
  align-items: center;
}

.ps-block--vendor-filter > * {
  width: 100%;
  max-width: 50%;
}

.ps-block--vendor-filter .ps-block__left {
  padding: 0 5px;
}

.ps-block--vendor-filter ul li {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}

.ps-block--vendor-filter ul li a {
  font-size: 16px;
  color: #666;
}

.ps-block--vendor-filter ul li a:hover {
  color: #000;
}

.ps-block--vendor-filter ul li.active a {
  font-weight: 500;
  color: #000;
}

.ps-block--vendor-filter ul li:last-child {
  margin-right: 16px;
}

.ps-block--vendor-filter form {
  width: 100%;
  position: relative;
}

.ps-block--vendor-filter form input {
  height: 30px;
  background-color: #ffffff;
}

.ps-block--vendor-filter form button {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 10px;
  border: none;
  background-color: transparent;
}

@media (max-width: 479px) {
  .ps-block--vendor-filter {
    flex-flow: row wrap;
  }
  .ps-block--vendor-filter > * {
    max-width: 100%;
  }
  .ps-block--vendor-filter .ps-block__left {
    padding-bottom: 10px;
  }
}

.ps-block--vendor-dashboard {
  margin-bottom: 40px;
}

.ps-block--vendor-dashboard .ps-block__header {
  margin-bottom: 30px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e1e1e1;
}

.ps-block--vendor-dashboard .ps-block__header h3 {
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 600;
  color: #000;
}

.ps-block--vendor-status {
  margin-bottom: 60px;
}

.ps-block--vendor-status figcaption {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 600;
  color: #000;
}

.ps-block--vendor-status .ps-block__footer {
  padding-top: 20px;
}

.ps-block--vendor-status .ps-block__footer a {
  color: #000;
}

.ps-block--vendor-status .ps-block__footer a:hover {
  color: #F7E5CD;
}

@media (max-width: 479px) {
  .ps-block--vendor-status {
    margin-bottom: 30px;
  }
}

.ps-block--icon i {
  margin-bottom: 30px;
  display: inline-block;
  color: #F7E5CD;
  font-size: 40px;
}

.ps-block--icon h5 {
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  color: #000;
}

.ps-block--icon p {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 1.2em;
}

@media (min-width: 768px) {
  .ps-block--icon p {
    font-size: 16px;
  }
  .ps-block--icon h5 {
    font-size: 18px;
  }
}

.ps-block--top-photographer {
  max-height: 480px;
  padding: 45px 20px;
  overflow: auto;
}

.ps-block--top-photographer .ps-block__header {
  padding-bottom: 60px;
}

.ps-block--top-photographer .ps-block__header p {
  font-size: 14px;
  color: #666;
}

.ps-block--top-photographer .ps-block__header h3 {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 24px;
  color: #000;
  line-height: 1em;
}

.ps-block--top-photographer .ps-block__author {
  display: flex;
  justify-content: space-between;
  padding-bottom: 18px;
}

.ps-block--top-photographer .ps-block__author .ps-block__author-thumbnail {
  width: 56px;
  height: 56px;
}

.ps-block--top-photographer .ps-block__author .ps-block__author-thumbnail img {
  border-radius: 50%;
}

.ps-block--top-photographer .ps-block__author figure {
  width: 100%;
  padding-left: 20px;
}

.ps-block--top-photographer .ps-block__author figure a {
  font-weight: 600;
  color: #000;
}

.ps-block--top-photographer .ps-block__author figure a:hover {
  color: #c81919;
}

.ps-block--top-photographer .ps-block__footer a {
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: underline;
}

.ps-block--top-photographer .ps-block__footer a i {
  font-size: 14px;
  margin-left: 6px;
}

.ps-block--top-photographer .ps-block__footer a:hover {
  color: #c81919;
}

@media (min-width: 1680px) {
  .ps-block--top-photographer {
    padding: 45px;
  }
  .ps-block--top-photographer .ps-block__header {
    font-size: 32px;
  }
  .ps-block--top-photographer .ps-block__header p {
    font-size: 16px;
  }
}

.ps-block--testimonial-bg {
  display: flex;
  padding: 60px 40px;
}

.ps-block--testimonial-bg h3 {
  margin-bottom: 35px;
  font-weight: 600;
}

.ps-block--testimonial-bg figure figcaption {
  font-weight: 600;
  font-size: 18px;
  color: #000;
}

.ps-block--testimonial-bg figure p {
  margin-bottom: 0;
  color: #999;
}

.ps-block--testimonial-bg .ps-block__content {
  max-width: 70%;
}

.ps-block--testimonial-bg.dark h3 {
  color: #fff;
}

.ps-block--testimonial-bg.dark figure figcaption {
  color: #fff;
}

.ps-block--testimonial-bg.dark figure p {
  color: #8c8c8c;
}

@media (min-width: 992px) {
  .ps-block--testimonial-bg {
    padding: 80px 30px;
  }
  .ps-block--testimonial-bg h3 {
    font-size: 30px;
    line-height: 1.2em;
    font-weight: 600;
  }
  .ps-block--testimonial-bg .ps-block__content {
    max-width: 70%;
  }
}

@media (min-width: 1200px) {
  .ps-block--testimonial-bg h3 {
    font-size: 36px;
  }
}

@media (min-width: 1440px) {
  .ps-block--testimonial-bg {
    padding: 80px 70px;
  }
}

.ps-block--store {
  margin-bottom: 20px;
  box-shadow: 0 0 4px 0 #ccc;
}

.ps-block--store h4 {
  margin-bottom: 30px;
  font-size: 21px;
  color: #525252;
  font-weight: 600;
}

.ps-block--store .ps-block__thumbnail {
  min-height: 210px;
}

.ps-block--store .br-wrapper {
  display: inline-block;
}

.ps-block--store .br-wrapper a {
  font-size: 13px;
  color: #F7E5CD;
}

.ps-block--store .ps-block__author {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  transition: all 0.4s ease;
  transform: translateY(-50px);
}

.ps-block--store .ps-block__author .ps-block__user {
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.ps-block--store .ps-block__author .ps-block__user img {
  border-radius: 50%;
}

.ps-block--store .ps-block__author .ps-btn {
  padding: 0 30px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  color: #17a2b8;
  background-color: #fff;
  border-bottom: 1px solid #17a2b8;
}

.ps-block--store .ps-block__contact li {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: 10px;
}

.ps-block--store .ps-block__contact li i {
  margin-right: 10px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #525252;
  color: #ffffff;
}

.ps-block--store .ps-block__inquiry {
  display: inline-block;
  background-color: #fff;
}

.ps-block--store .ps-block__inquiry a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  height: 35px;
  color: #ffffff;
  background-color: #525252;
}

.ps-block--store .ps-block__inquiry a i {
  margin-right: 10px;
}

.ps-block--store .ps-block__content {
  padding: 0 20px 20px;
  border-top: 3px solid #17a2b8;
}

.ps-block--store-2 {
  box-shadow: 0px 0px 25px 0px #ddd;
}

.ps-block--store-2 .br-wrapper {
  display: inline-block;
}

.ps-block--store-2 .br-wrapper a {
  font-size: 13px;
  color: #F7E5CD;
}

.ps-block--store-2 h4 {
  margin-bottom: 0;
  color: #526b6f;
}

.ps-block--store-2 .ps-block__rating {
  margin-bottom: 10px;
}

.ps-block--store-2 .ps-block__content {
  padding: 30px 20px 50px;
  border-bottom: 1px solid #eee;
}

.ps-block--store-2 .ps-block__author {
  position: relative;
  display: flex;
  flex-flow: row-reverse nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  transition: all 0.4s ease;
}

.ps-block--store-2 .ps-block__author .ps-block__user {
  display: block;
  width: 68px;
  height: 68px;
  border-radius: 50%;
  border: 3px solid #fff;
  position: relative;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  box-shadow: 0px 0px 30px -6px #afafaf;
}

.ps-block--store-2 .ps-block__author .ps-block__user img {
  border-radius: 50%;
}

.ps-block--store-2 .ps-block__author .ps-btn {
  padding: 0 16px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: #fff;
  background-color: #f05025;
  font-size: 14px;
}

.ps-block--store-banner .ps-block__content {
  position: relative;
  min-height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ps-block--store-banner .ps-block__content h3 {
  font-size: 48px;
  position: relative;
  padding-left: 90px;
  font-weight: 500;
  text-transform: uppercase;
}

.ps-block--store-banner .ps-block__content h3:before {
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 80px;
  height: 1px;
  background-color: #fff;
}

.ps-block--store-banner .ps-block__inquiry {
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 5px 10px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.9);
  color: #17a2b8;
}

.ps-block--store-banner .ps-block__inquiry:hover {
  background-color: #17a2b8;
  color: #ffffff;
}

.ps-block--store-banner .ps-block__user {
  background-color: #2f2f2f;
  padding: 30px 5%;
}

.ps-block--store-banner .ps-block__user .ps-block__user-avatar {
  display: block;
  text-align: center;
  margin-bottom: 30px;
}

.ps-block--store-banner .ps-block__user .ps-block__user-avatar img {
  width: 150px;
  height: 150px;
  margin-bottom: 10px;
  border-radius: 50%;
}

.ps-block--store-banner .ps-block__user .ps-block__user-avatar .br-wrapper {
  display: block;
}

.ps-block--store-banner .ps-block__user .ps-block__user-avatar .br-wrapper a {
  font-size: 13px;
  color: #F7E5CD;
}

.ps-block--store-banner .ps-block__user p {
  font-size: 16px;
  color: #ffffff;
}

.ps-block--store-banner .ps-block__user p i {
  margin-right: 1rem;
  color: #999999;
}

.ps-block--store-banner .ps-block__user .ps-block__user-content {
  padding-bottom: 0;
}

@media (min-width: 768px) {
  .ps-block--store-banner .ps-block__user {
    display: flex;
    flex-flow: row nowrap;
  }
  .ps-block--store-banner .ps-block__user .ps-block__user-avatar {
    position: relative;
    top: -85px;
    margin-bottom: -85px;
  }
  .ps-block--store-banner .ps-block__user .ps-block__user-content {
    padding-left: 30px;
    padding-bottom: 75px;
  }
}

@media (min-width: 992px) {
  .ps-block--store-banner .ps-block__content h3 {
    font-size: 70px;
    color: #ffffff;
  }
}

.ps-block--user-account {
  position: relative;
}

.ps-block--user-account i {
  font-size: 30px;
  line-height: 1em;
}

.ps-block--user-account .ps-list--arrow li a {
  padding-left: 0;
  color: #000;
}

.ps-block--user-account .ps-list--arrow li a:before {
  display: none;
}

.ps-block--user-account .ps-list--arrow li a:hover {
  color: #F7E5CD;
}

.ps-block--user-account .ps-block__heading {
  font-weight: 600;
  color: #000;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 10px;
}

.ps-block--user-account .ps-block__footer {
  padding-top: 10px;
  margin-bottom: 0;
  border-top: 1px solid #eaeaea;
}

.ps-block--user-account .ps-block__content {
  position: absolute;
  min-width: 240px;
  right: 0;
  z-index: 30;
  padding-top: 10px;
  transform: translate(0 20px) scale3d(1, 1, 0);
  transition: all 0.4s ease;
  visibility: hidden;
  opacity: 0;
}

.ps-block--user-account:hover .ps-block__content {
  visibility: visible;
  opacity: 1;
  transform: translate(0 20px) scale3d(1, 1, 1);
  background-color: #fff;
  padding: 10px 15px;
}

.ps-block--checkout-order figure {
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid #eaeaea;
}

.ps-block--checkout-order figure figcaption {
  display: flex;
  justify-content: space-between;
  font-weight: 400;
}

.ps-block--checkout-order figure figcaption strong {
  font-size: 14px;
  color: #000;
  font-weight: 600;
  text-transform: uppercase;
}

.ps-block--checkout-order figure figcaption small {
  font-size: 16px;
}

.ps-block--checkout-order .ps-block__items > a {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ps-block--checkout-order .ps-block__items > a strong {
  font-weight: 600;
}

.ps-block--checkout-order .ps-block__items > a strong span {
  color: #666;
  margin-left: 10px;
}

.ps-block--checkout-order .ps-block__items > a small {
  font-size: 14px;
  color: #000;
}

.ps-block--checkout-order .ps-block__shipping {
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.ps-block--checkout-order .ps-block__total h3 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  font-size: 16px;
  color: #666;
}

.ps-block--checkout-order .ps-block__total h3 strong {
  color: #000;
}

.ps-block--checkout-order .ps-block__content {
  padding: 30px 20px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 2px solid #eaeaea;
}

.ps-block--checkout-order .ps-block__payment-methods .ps-radio {
  margin-bottom: 10px;
}

.ps-block--checkout-order .ps-block__payment-methods .ps-radio > label {
  color: #000;
}

.ps-block--checkout-order .ps-block__payment-methods p {
  margin-top: 20px;
  font-size: 16px;
}

.ps-block--checkout-order .ps-block__payment-methods p a {
  color: #000;
}

.ps-block--checkout-order .ps-block__payment-methods p a:hover {
  color: #F7E5CD;
}

.ps-block--checkout-order .ps-block__footer button {
  height: 60px;
}

.ps-block--shipping figure {
  margin-bottom: 0;
}

.ps-block--shipping figure a {
  text-align: right;
}

.ps-block--shipping figure strong {
  text-align: right;
}

.ps-block--shipping .ps-block__panel {
  margin-bottom: 30px;
  padding: 10px 20px;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
}

@media screen and (min-width: 480px) {
  .ps-block--shipping .ps-block__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (min-width: 768px) {
  .ps-block--shipping figure {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ps-block--shipping figure > * {
    flex-basis: 100%;
  }
  .ps-block--shipping figure small {
    font-size: 14px;
    max-width: 10em;
  }
  .ps-block--shipping figure p {
    margin-bottom: 0;
  }
  .ps-block--shipping figure a {
    align-self: flex-end;
    color: #06c;
    max-width: 10em;
    text-align: right;
  }
}

.ps-block--payment-method {
  margin-bottom: 50px;
  padding: 20px;
  background-color: #eaeaea;
}

.ps-block--payment-method .ps-tab-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
}

.ps-block--payment-method .ps-tab-list li {
  margin-right: 10px;
}

.ps-block--payment-method .ps-tab-list li .ps-btn {
  background-color: #bfbfbf;
}

.ps-block--payment-method .ps-tab-list li .ps-btn:hover {
  background-color: #F7E5CD;
}

.ps-block--payment-method .ps-tab-list li:last-child {
  margin-right: 0;
}

.ps-block--payment-method .ps-tab-list li.active .ps-btn {
  background-color: #F7E5CD;
  color: #fff;
}

.ps-block--payment-method .ps-block__header {
  padding-top: 10px;
  padding-bottom: 30px;
}

.ps-block--payment-method .form-control {
  background-color: #fff;
}

.ps-block--payment-method .ps-btn:hover {
  color: #fff;
}

.ps-block--payment-success {
  background-color: #fff;
}

.ps-block--payment-success h3 {
  font-size: 48px;
  color: #669900;
}

.ps-block--payment-success p {
  font-size: 18px;
}

.ps-block--payment-success p a {
  color: #06c;
}

@media screen and (min-width: 992px) {
  .ps-block--payment-success h3 {
    font-size: 64px;
  }
  .ps-block--payment-success p {
    font-size: 20px;
  }
}

.ps-block--medicine-brand {
  text-align: center;
}

.ps-block--medicine-brand img {
  border-radius: 50%;
  margin-bottom: 20px;
  max-height: 80px;
}

.ps-block--medicine-brand a {
  font-weight: 600;
  font-size: 16px;
  color: #000;
}

.ps-block--medicine-brand a:hover {
  color: #28afb1;
}

@media screen and (min-width: 1200px) {
  .ps-block--medicine-brand img {
    max-height: 100%;
  }
}

.ps-block--testimonial-medicine .ps-block__content {
  padding-bottom: 20px;
}

.ps-block--testimonial-medicine .ps-block__content p {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  line-height: 1.2em;
}

.ps-block--testimonial-medicine .ps-block__author {
  display: flex;
  flex-flow: row nowrap;
}

.ps-block--testimonial-medicine .ps-block__author .ps-block__author__thumbnail img {
  max-width: 50px;
}

.ps-block--testimonial-medicine .ps-block__author figure {
  padding-left: 20px;
}

.ps-block--testimonial-medicine .ps-block__author figure figcaption {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  line-height: 1.4em;
}

.ps-block--testimonial-medicine .ps-block__author figure span {
  color: #666;
}

@media screen and (min-width: 1200px) {
  .ps-block--testimonial-medicine .ps-block__content p {
    font-size: 30px;
  }
}

.ps-panel--sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 400px;
  height: 100vh;
  overflow-y: auto;
  z-index: 10001;
  background-color: #fff;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
  transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1) 0s;
}

.ps-panel--sidebar .ps-panel__header {
  position: relative;
  text-align: center;
  padding: 15px 20px;
  background-color: #F7E5CD;
}

.ps-panel--sidebar .ps-panel__header h3 {
  margin-bottom: 0;
  font-weight: 600;
  color: #ffffff;
  font-size: 1.6rem;
  line-height: 20px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.ps-panel--sidebar .ps-panel__header .ps-btn--close {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 10px;
}

.ps-panel--sidebar .ps-panel__header .ps-btn--close:before, .ps-panel--sidebar .ps-panel__header .ps-btn--close:after {
  background-color: #ffffff;
  height: 60%;
}

.ps-panel--sidebar .ps-panel__content {
  padding-top: 10px;
  padding-bottom: 70px;
}

.ps-panel--sidebar.active {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}

@media (max-width: 479px) {
  .ps-panel--sidebar {
    width: 100%;
    max-width: 100%;
  }
}

.ps-panel--search-result {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 999;
  background-color: #fff;
  border: 1px solid #eaeaea;
  padding: 10px 20px;
  transition: all 0.4s ease;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale3d(1, 1, 0);
  -moz-transform: scale3d(1, 1, 0);
  -ms-transform: scale3d(1, 1, 0);
  -o-transform: scale3d(1, 1, 0);
  transform: scale3d(1, 1, 0);
}

.ps-panel--search-result .ps-panel__content {
  max-height: 400px;
  overflow-y: auto;
}

.ps-panel--search-result .ps-panel__footer {
  padding: 10px 0;
  text-align: center;
  border-top: 1px solid #eaeaea;
}

.ps-panel--search-result.active {
  -webkit-transform: scale3d(1, 1, 1);
  -moz-transform: scale3d(1, 1, 1);
  -ms-transform: scale3d(1, 1, 1);
  -o-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
  visibility: visible;
}

.ps-panel--search-result .ps-product {
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 10px;
}

.ps-panel--search-result .ps-product:hover {
  border-bottom: 1px solid #eaeaea;
}

.ps-panel--search-result .ps-product:last-child {
  border: none;
}

.widget {
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.widget .widget-title {
  margin-bottom: 2.5rem;
  font-weight: 700;
}

.widget:last-child {
  border-bottom: none;
}

.widget_sidebar {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #e5e5e5;
}

.widget_sidebar .widget-title {
  font-family: "Work Sans", sans-serif;
  display: block;
  margin-bottom: 2.5rem;
  font-size: 1.6rem;
  font-weight: 600;
  color: #222222;
  text-transform: uppercase;
}

.widget_sidebar ul li a {
  display: block;
  position: relative;
  padding: 5px 0;
  font-size: 1.4rem;
  line-height: 1.8em;
  color: #666;
}

.widget_sidebar ul li a:before {
  content: '\f105';
  font-family: FontAwesome;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 10px;
  left: 0;
  line-height: 1em;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.widget_sidebar ul li a:hover {
  padding-left: 10px;
  color: #000;
}

.widget_sidebar ul li a:hover:before {
  visibility: visible;
  opacity: 1;
  -webkit-transition-delay: 0.2s;
  -moz-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.widget_shop {
  padding: 25px 20px;
  background-color: #f5f5f5;
}

.widget_shop .widget-title {
  margin-bottom: 25px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 400;
  color: #000;
}

.widget_shop .ps-checkbox {
  margin-bottom: 10px;
}

.widget_shop .ps-checkbox > label {
  color: #000;
  line-height: 16px;
  padding-left: 25px;
}

.widget_shop .ps-checkbox > label:before {
  width: 16px;
  height: 16px;
  border-radius: 2px;
}

.widget_shop .ps-checkbox > label:after {
  top: 3px;
  left: 7px;
  width: 4px;
  height: 8px;
  border-width: 1px;
  border-color: #F7E5CD;
}

.widget_shop .ps-checkbox .fa-star {
  margin-right: 5px;
  color: #ccc;
}

.widget_shop .ps-checkbox .fa-star.rate {
  color: #f2b309;
}

.widget_shop .ps-checkbox small {
  color: #999999;
  font-size: 14px;
}

.widget_shop .ps-checkbox input[type=checkbox]:checked ~ label {
  font-weight: 500;
  color: #F7E5CD;
}

.widget_shop .ps-checkbox input[type=checkbox]:checked ~ label:before {
  border-color: #F7E5CD;
  background-color: #ffffff;
}

.widget_shop .ps-checkbox.ps-checkbox--color > label {
  margin: 0;
  padding: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  vertical-align: top;
}

.widget_shop .ps-checkbox.ps-checkbox--color > label:before {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.widget_shop .ps-slider {
  display: block;
  margin-bottom: 10px;
  margin-left: 10px;
  height: 4px;
  background-color: #cecece;
  position: relative;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  border: none;
}

.widget_shop .ui-slider-range {
  top: 0;
  left: 0;
  height: 100%;
  background-color: #000;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  border: none;
}

.widget_shop .ui-slider-handle {
  display: inline-block;
  top: 50%;
  height: 16px;
  width: 16px;
  outline: none;
  cursor: pointer;
  border: none;
  background-color: #000;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.widget_shop .ps-slider__meta {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 1.8em;
  color: #999;
}

.widget_shop .ps-slider__meta span {
  margin-left: 5px;
  margin-right: 5px;
}

.widget_shop .ps-filter__btn {
  background-color: #888888;
  color: #fff;
}

.widget_shop .ps-filter__btn:hover {
  background-color: #222222;
}

.widget_shop figure {
  margin-top: 15px;
  padding-bottom: 15px;
  padding-top: 15px;
  border-bottom: 1px solid #ccc;
}

.widget_shop figure:last-child {
  border-bottom: none;
}

.widget_shop .sizes a {
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 2px 20px;
  border: 1px solid #ccc;
  background-color: #ffffff;
}

.widget_shop .sizes a:hover {
  border-color: #F7E5CD;
}

.widget_search {
  margin-bottom: 25px;
  border-bottom: none;
}

.widget_size a {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 1rem 2rem;
  font-size: 1.4rem;
  line-height: 20px;
  color: #555;
  background-color: #f6f6f6;
}

.widget_size a:hover {
  background-color: #a7a7a7;
  color: #ffffff;
}

.widget_size .ps-checkbox--size label {
  margin-bottom: 10px;
  padding: 0;
  background-color: transparent;
}

.widget_filter .ps-slider {
  display: block;
  margin-bottom: 10px;
  margin-left: 10px;
  height: 5px;
  background-color: #cecece;
  position: relative;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  border: none;
}

.widget_filter .ui-slider-range {
  top: 0;
  left: 0;
  height: 100%;
  background-color: #F7E5CD;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  border: none;
}

.widget_filter .ui-slider-handle {
  display: inline-block;
  top: 50%;
  height: 14px;
  width: 14px;
  outline: none;
  cursor: pointer;
  background-color: #F7E5CD;
  border: none;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.widget_filter .ps-slider__meta {
  margin-bottom: 10px;
  font-size: 13px;
  line-height: 1.8em;
  color: #666;
  text-align: center;
}

.widget_filter .ps-slider__meta span {
  margin-left: 5px;
  margin-right: 5px;
}

.widget_filter .ps-filter__btn {
  background-color: #888888;
  color: #fff;
}

.widget_filter .ps-filter__btn:hover {
  background-color: #222222;
}

.widget_tags a {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 5px 15px;
  font-size: 14px;
  line-height: 15px;
  color: #303030;
  background-color: transparent;
  border: 1px solid #000;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  border-radius: 50px;
}

.widget_tags a:hover {
  background-color: #222222;
  color: #fff;
}

.widget_footer .widget-title {
  font-size: 16px;
  margin-bottom: 30px;
  color: #000;
  font-weight: 600;
}

.widget_contact-us .widget_content h3 {
  color: #F7E5CD;
}

.widget_contact-us .widget_content .ps-list--social {
  padding-top: 25px;
}

.widget_features {
  margin-bottom: 30px;
  background-color: #f1f1f1;
  padding: 20px;
}

.widget_features p {
  position: relative;
  margin-bottom: 20px;
  padding-left: 50px;
  color: #000;
  line-height: 1.4em;
}

.widget_features p i {
  position: absolute;
  top: 0;
  left: 0;
  margin-right: 10px;
  font-size: 26px;
  color: #000;
}

.widget_sell-on-site p {
  margin-bottom: 0;
  color: #000;
  vertical-align: middle;
}

.widget_sell-on-site p i {
  font-size: 18px;
}

.widget_sell-on-site p a {
  color: #06c;
}

.widget_same-brand {
  border: 1px solid #e1e1e1;
}

.widget_same-brand h3 {
  padding: 14px 20px;
  background-color: #f4f4f4;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  margin-top: 0;
  color: #000;
}

.widget_same-brand .widget__content {
  padding: 20px;
}

.widget_same-brand .ps-product {
  margin-bottom: 30px;
  border: 1px solid #eaeaea;
  transition: all .4s ease;
}

.widget_same-brand .ps-product .ps-product__content {
  display: block;
}

.widget_same-brand .ps-product:hover {
  border-bottom: 1px solid silver;
}

.widget_same-brand .ps-product:hover .ps-product__content {
  display: block;
  visibility: visible;
  opacity: 1;
  height: auto;
}

.widget_same-brand .ps-product:hover .ps-product__content.hover {
  display: none;
}

.widget_best-sale {
  padding: 15px 10px;
  border: 1px solid #d9d9d9;
  border-bottom: 5px solid red;
  max-height: 500px;
}

.widget_best-sale .widget-title {
  font-weight: 600;
  color: #000;
  font-size: 20px;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 15px;
}

.widget_best-sale .ps-product--horizontal {
  margin-bottom: 15px;
}

.widget_best-sale .ps-product--horizontal .ps-product__thumbnail {
  max-width: 60px;
}

.widget_best-sale .ps-product--horizontal .ps-product__rating {
  display: none;
}

.widget_best-sale .ps-product--horizontal .ps-product__price {
  font-size: 14px;
}

.widget_best-sale .ps-product--horizontal .ps-product__price del {
  font-size: 12px;
}

.widget--blog .widget__title {
  margin-bottom: 35px;
  margin-top: 0;
  font-size: 20px;
  font-weight: 600;
  color: #000;
}

.widget--blog.widget--search .ps-form--widget-search input {
  height: 40px;
}

.widget--blog.widget--categories ul li a {
  display: block;
  padding: 6px 0;
  line-height: 20px;
  color: #000000;
}

.widget--blog.widget--categories ul li a:hover {
  color: #F7E5CD;
}

.widget--blog.widget--recent-post .widget__content a {
  display: block;
  margin-bottom: 10px;
  color: #000;
  font-weight: 600;
  font-size: 16px;
}

.widget--blog.widget--recent-post .widget__content a:hover {
  color: #F7E5CD;
}

.widget--blog.widget--recent-comments p a {
  color: #000;
}

.widget--blog.widget--recent-comments p a:hover {
  color: #F7E5CD;
}

.widget--blog.widget--tags a {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  vertical-align: top;
  color: #999;
}

.widget--blog.widget--tags a:after {
  content: ',';
}

.widget--blog.widget--tags a:hover {
  color: #F7E5CD;
}

.widget--vendor {
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid #d8d8d8;
}

.widget--vendor .widget-title {
  margin-bottom: 35px;
  font-size: 20px;
  color: #000;
  font-weight: 600;
}

.widget--vendor .form-control {
  background-color: #fff;
  border: none;
}

.widget--vendor .select2 {
  min-width: 100%;
}

.widget--vendor .select2 .select2-selection {
  border: none;
}

.widget--vendor .select2 .select2-selection__rendered {
  font-size: 16px;
  color: #666;
  padding-top: 15px;
  padding-bottom: 15px;
}

.widget--vendor .ps-list--arrow a {
  color: #000;
}

.widget--vendor .ps-list--arrow a:before {
  content: "\f105";
  font-size: 20px;
}

.widget--open-time ul li {
  margin-bottom: 25px;
}

.widget--open-time ul li strong {
  display: block;
  font-weight: 600;
  color: #000;
}

.widget--open-time ul li span {
  color: #666;
}

.widget--open-time ul li:last-child {
  margin-bottom: 0;
}

.ps-widget--account-dashboard .ps-widget__header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 20px;
}

.ps-widget--account-dashboard .ps-widget__header img {
  flex-basis: 60px;
  max-width: 60px;
  border-radius: 50%;
}

.ps-widget--account-dashboard .ps-widget__header figure {
  flex-basis: 100%;
  padding-left: 10px;
}

.ps-widget--account-dashboard .ps-widget__header figure figcaption {
  font-size: 16px;
  color: #666;
}

.ps-widget--account-dashboard .ps-widget__header figure p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

.ps-widget--account-dashboard .ps-widget__content ul {
  border: 1px solid #d1d1d1;
}

.ps-widget--account-dashboard .ps-widget__content ul li {
  border-bottom: 1px solid #d1d1d1;
}

.ps-widget--account-dashboard .ps-widget__content ul li a {
  display: block;
  padding: 15px 20px;
  line-height: 20px;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  text-transform: capitalize;
}

.ps-widget--account-dashboard .ps-widget__content ul li a i {
  margin-right: 10px;
}

.ps-widget--account-dashboard .ps-widget__content ul li a:hover {
  background-color: #F7E5CD;
  color: #fff;
}

.ps-widget--account-dashboard .ps-widget__content ul li:last-child {
  border-bottom: none;
}

.ps-widget--account-dashboard .ps-widget__content ul li.active {
  background-color: #F7E5CD;
}

.ps-widget--account-dashboard .ps-widget__content ul li.active a {
  color: #fff;
}

#nonlinear {
  width: 90%;
  margin: 10px auto;
}

.noUi-connect {
  background-color: #F7E5CD;
}

.noUi-horizontal {
  height: 8px;
}

.noUi-handle:before,
.noUi-handle:after {
  display: none;
}

.noUi-horizontal .noUi-handle {
  width: 16px;
  height: 16px;
  left: -17px;
  top: -5px;
}

html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
  right: -7px;
}

.noUi-handle {
  border: 3px solid #F7E5CD;
  border-radius: 50%;
  box-shadow: none;
}

.owl-carousel {
  position: relative;
}

.owl-carousel .owl-item img {
  width: auto;
}

.owl-carousel .ps-product {
  margin-bottom: 10px;
}

.ps-carousel--nav {
  position: relative;
  z-index: 10;
}

.ps-carousel--nav .owl-nav {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  width: 100%;
  height: 0;
}

.ps-carousel--nav .owl-nav > * {
  display: inline-block;
  position: relative;
  vertical-align: top;
  width: 40px;
  height: 40px;
  color: #cccccc;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background-color: transparent;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.ps-carousel--nav .owl-nav > * i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 30px;
}

.ps-carousel--nav .owl-nav > *:hover {
  color: #000;
}

.ps-carousel--nav .owl-nav .owl-prev {
  margin-left: -70px;
}

.ps-carousel--nav .owl-nav .owl-prev i {
  padding-right: 2px;
}

.ps-carousel--nav .owl-nav .owl-next {
  float: right;
  margin-right: -70px;
}

.ps-carousel--nav .owl-nav .owl-next i {
  padding-left: 2px;
}

.ps-carousel--nav .owl-dots {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: none !important;
  text-align: center;
}

.ps-carousel--nav .owl-dots .owl-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 8px;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.ps-carousel--nav .owl-dots .owl-dot:last-child {
  margin-right: 0;
}

.ps-carousel--nav .owl-dots .owl-dot.active {
  background-color: #F7E5CD;
}

.ps-carousel--nav.inside .owl-nav {
  z-index: 20;
  height: 0;
}

.ps-carousel--nav.inside .owl-nav > * {
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.ps-carousel--nav.inside .owl-prev {
  margin-left: 3rem;
}

.ps-carousel--nav.inside .owl-prev i {
  padding-right: 5px;
}

.ps-carousel--nav.inside .owl-next {
  float: right;
  margin-right: 3rem;
}

.ps-carousel--nav.inside .owl-next i {
  padding-left: 5px;
}

@media (max-width: 1680px) {
  .ps-carousel--nav .owl-nav .owl-prev {
    margin-left: -40px;
  }
  .ps-carousel--nav .owl-nav .owl-next {
    margin-right: -40px;
  }
}

@media (max-width: 1199px) {
  .ps-carousel--nav {
    margin-bottom: 3rem;
    padding-bottom: 30px;
  }
  .ps-carousel--nav .owl-nav {
    display: none;
  }
  .ps-carousel--nav .owl-dots {
    display: block !important;
  }
}

.ps-carousel--animate .slick-dots {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  text-align: center;
}

.ps-carousel--animate .slick-dots li {
  display: inline-block;
  vertical-align: top;
  line-height: 0;
  margin-right: 10px;
}

.ps-carousel--animate .slick-dots li button {
  display: inline-block;
  border: 0;
  padding: 0;
  font-size: 0;
  width: 12px;
  height: 12px;
  vertical-align: top;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
}

.ps-carousel--animate .slick-dots li.slick-active button {
  background-color: #000;
}

.ps-carousel--animate .slick-dots li:last-child {
  margin-right: 0;
}

@media (max-width: 479px) {
  .ps-carousel--animate .slick-dots {
    bottom: 5px;
  }
  .ps-carousel--animate .slick-dots li button {
    width: 8px;
    height: 8px;
  }
}

.ps-carousel--responsive {
  position: relative;
  z-index: 10;
}

.ps-carousel--responsive .owl-dots {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: none !important;
  text-align: center;
}

.ps-carousel--responsive .owl-dots .owl-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 6px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.3);
}

.ps-carousel--responsive .owl-dots .owl-dot:last-child {
  margin-right: 0;
}

.ps-carousel--responsive .owl-dots .owl-dot.active {
  background-color: #F7E5CD;
}

@media (max-width: 1199px) {
  .ps-carousel--responsive {
    margin-bottom: 3rem;
    padding-bottom: 30px;
  }
  .ps-carousel--responsive .owl-nav {
    display: none;
  }
  .ps-carousel--responsive .owl-dots {
    display: block !important;
  }
}

.ps-carousel--product-box {
  overflow: hidden;
}

.ps-carousel--product-box .owl-nav {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  height: 0;
  display: flex;
  justify-content: space-between;
}

.ps-carousel--product-box .owl-nav > * {
  position: relative;
  background-color: rgba(0, 0, 0, 0.5);
  width: 30px;
  height: 40px;
}

.ps-carousel--product-box .owl-nav > * > i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #ffffff;
}

.ps-carousel--product-box .owl-nav .owl-prev {
  padding-right: 2px;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
  transition: all .25s ease;
}

.ps-carousel--product-box .owl-nav .owl-next {
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
  transition: all .25s ease;
}

.ps-carousel--product-box .owl-nav .owl-next i {
  padding-left: 2px;
  transition: all .25s ease;
}

.ps-carousel--product-box .owl-dots {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  text-align: center;
}

.ps-carousel--product-box .owl-dots .owl-dot {
  display: inline-block;
  margin-right: 10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
}

.ps-carousel--product-box .owl-dots .owl-dot:hover, .ps-carousel--product-box .owl-dots .owl-dot.active {
  background-color: #ffffff;
}

.ps-carousel--product-box .owl-dots .owl-dot:last-child {
  margin-right: 0;
}

.ps-carousel--product-box:hover .owl-nav .owl-prev {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}

.ps-carousel--product-box:hover .owl-nav .owl-next {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}

.ps-carousel--nav-inside {
  overflow: hidden;
}

.ps-carousel--nav-inside .owl-nav {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  height: 0;
  display: flex;
  justify-content: space-between;
}

.ps-carousel--nav-inside .owl-nav > * {
  position: relative;
  background-color: rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.ps-carousel--nav-inside .owl-nav > * > i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #000;
}

.ps-carousel--nav-inside .owl-nav .owl-prev {
  padding-right: 2px;
}

.ps-carousel--nav-inside .owl-nav .owl-next {
  transition: all .25s ease;
}

.ps-carousel--nav-inside .owl-nav .owl-next i {
  padding-left: 2px;
}

.ps-carousel--nav-inside .owl-dots {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  text-align: center;
  display: none;
  visibility: hidden;
  opacity: 0;
}

.ps-carousel--nav-inside .owl-dots .owl-dot {
  display: inline-block;
  margin-right: 10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
}

.ps-carousel--nav-inside .owl-dots .owl-dot:hover, .ps-carousel--nav-inside .owl-dots .owl-dot.active {
  background-color: #ffffff;
}

.ps-carousel--nav-inside .owl-dots .owl-dot:last-child {
  margin-right: 0;
}

.ps-carousel--nav-inside.second .owl-nav > * {
  width: 48px;
  height: 48px;
  background-color: rgba(0, 0, 0, 0.3);
}

.ps-carousel--nav-inside.second .owl-nav > * i {
  font-size: 18px;
  color: #ffffff;
}

.ps-carousel--nav-inside.second .owl-nav > *:hover {
  background-color: #000;
}

.ps-carousel--nav-inside.second .owl-nav .owl-prev {
  margin-left: 20px;
}

.ps-carousel--nav-inside.second .owl-nav .owl-next {
  margin-right: 20px;
}

@media (max-width: 1199px) {
  .ps-carousel--nav-inside .owl-nav {
    display: none;
  }
  .ps-carousel--nav-inside .owl-dots {
    display: block;
    visibility: visible;
    opacity: 1;
  }
}

.ps-carousel--dots .owl-dots {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  text-align: center;
}

.ps-carousel--dots .owl-dots .owl-dot {
  display: inline-block;
  margin-right: 10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
}

.ps-carousel--dots .owl-dots .owl-dot:hover, .ps-carousel--dots .owl-dots .owl-dot.active {
  background-color: #F7E5CD;
}

.ps-carousel--dots .owl-dots .owl-dot:last-child {
  margin-right: 0;
}

.masonry-wrapper {
  margin: 0;
}

.masonry-wrapper .grid-item,
.masonry-wrapper .grid-sizer {
  width: 100%;
}

.masonry-wrapper .grid-item .grid-item__inner .grid-item__content-wrapper {
  background-color: #9EC3C3;
}

.masonry-wrapper.ac_masonry-grid .grid-item__content-wrapper {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.setting {
  margin: 0;
  margin: -5px;
}

.setting .grid-item,
.setting .grid-sizer {
  width: 100%;
}

.setting .grid-item .grid-item__inner .grid-item__content-wrapper {
  background-color: #ccc;
}

.setting.ac_masonry-grid .grid-item__content-wrapper {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.setting.ac_masonry-grid .grid-item__inner {
  padding-top: 60%;
}

.setting.ac_masonry-grid .grid-item.extra-large .grid-item__inner, .setting.ac_masonry-grid .grid-item.wide .grid-item__inner {
  padding-top: 30%;
}

.setting.ac_masonry-grid .grid-item.high .grid-item__inner {
  padding-top: 120%;
}

.setting .grid-item .grid-item__content-wrapper {
  margin: 5px;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
}

.setting .grid-item,
.setting .grid-sizer {
  width: 100%;
}

@media (min-width: 768px) {
  .setting .grid-item,
  .setting .grid-sizer {
    width: 50%;
  }
  .setting .grid-item.wide, .setting .grid-item.large {
    width: 100%;
  }
  .setting .grid-item.extra-large {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .setting .grid-item,
  .setting .grid-sizer {
    width: 33.33333%;
  }
  .setting .grid-item.wide, .setting .grid-item.large {
    width: 66.66667%;
  }
  .setting .grid-item.extra-large {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .setting .grid-item,
  .setting .grid-sizer {
    width: 33.33333%;
  }
  .setting .grid-item.wide, .setting .grid-item.large {
    width: 66.66667%;
  }
  .setting .grid-item.extra-large {
    width: 100%;
  }
}

.ps-badge {
  position: absolute;
  padding: 5px 15px;
  top: 1rem;
  left: 1rem;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

.ps-badge i {
  font-size: 1.4rem;
  color: #fff;
  font-weight: 500;
  line-height: 20px;
  font-style: normal;
}

.ps-badge--red {
  background-color: #ea4040;
}

.ps-product {
  position: relative;
  padding: 20px 20px 0;
  border: 1px solid transparent;
  border-bottom: none;
}

.ps-product .ps-product__rating .br-wrapper {
  display: inline-block;
}

.ps-product .ps-product__rating .br-wrapper a {
  font-size: 13px;
  color: #F7E5CD;
}

.ps-product .ps-product__rating span {
  color: #666;
  margin-left: 10px;
}

.ps-product .ps-product__price {
  position: relative;
  font-size: 16px;
  color: #000;
}

.ps-product .ps-product__price.sale {
  color: #f30;
}

.ps-product .ps-product__price.sale del {
  color: #666;
}

.ps-product .ps-product__actions {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  max-width: 200px;
  padding: 10px 0;
  background-color: #fff;
  transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1) 0s;
}

.ps-product .ps-product__actions li {
  margin-right: 10px;
}

.ps-product .ps-product__actions li a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 50%;
}

.ps-product .ps-product__actions li a:hover {
  background-color: #F7E5CD;
  color: #ffffff;
}

.ps-product .ps-product__actions:last-child {
  margin-right: 0;
}

.ps-product .ps-product__vendor {
  display: block;
  padding-bottom: 8px;
  margin-bottom: 13px;
  line-height: 1.2;
  font-size: 12px;
  color: #000;
  text-transform: uppercase;
  border-bottom: 1px solid #e1e1e1;
}

.ps-product .ps-product__badge {
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 5px 10px;
  border-radius: 4px;
  background-color: #f14705;
}

.ps-product .ps-product__badge.out-stock {
  background-color: #000;
}

.ps-product .ps-product__badge.hot {
  background-color: #ff8c46;
  color: #ffffff;
}

.ps-product .ps-product__title {
  margin: 0;
  display: block;
  padding: 0 0 5px;
  font-size: 14px;
  line-height: 1.2em;
  color: #06c;
  --max-lines: 2;
  max-height: calc(1.2em * var(--max-lines));
  overflow: hidden;
  padding-right: 1rem;
}

.ps-product .ps-product__title:hover {
  color: #F7E5CD;
}

.ps-product .ps-product__progress-bar {
  display: block;
  width: 100%;
}

.ps-product .ps-product__progress-bar .ps-progress__value {
  margin-bottom: 5px;
  position: relative;
  display: block;
  height: 11px;
  background-color: #e4e4e4;
}

.ps-product .ps-product__progress-bar .ps-progress__value > span {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  height: 100%;
  background-color: #F7E5CD;
  width: 50%;
}

.ps-product .ps-product__thumbnail {
  position: relative;
  overflow: hidden;
}

.ps-product .ps-product__thumbnail img {
  width: 100%;
}

.ps-product .ps-product__thumbnail .ps-product__actions {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 100%);
  -moz-transform: translate(-50%, 100%);
  -ms-transform: translate(-50%, 100%);
  -o-transform: translate(-50%, 100%);
  transform: translate(-50%, 100%);
}

.ps-product .ps-product__content > p {
  color: #000;
}

.ps-product .ps-product__content.hover {
  display: block;
  position: absolute;
  top: 100%;
  right: -1px;
  width: calc(100% + 2px);
  padding: 0 20px 20px;
  border: 1px solid silver;
  border-top: none;
  visibility: hidden;
  opacity: 0;
}

.ps-product .ps-product__container {
  padding-top: 10px;
}

.ps-product.ps-product--inner {
  padding: 0;
  border: none;
}

.ps-product:hover {
  border-color: silver;
}

.ps-product:hover .ps-product__thumbnail .ps-product__actions {
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.ps-product:hover .ps-product__content {
  visibility: hidden;
  opacity: 0;
  height: 0;
}

.ps-product:hover .ps-product__content.hover {
  height: auto;
  visibility: visible;
  opacity: 1;
}

.ps-product:hover.ps-product--inner {
  border: none;
}

.ps-product:hover.ps-product--inner .ps-product__content {
  display: block;
  visibility: visible;
  opacity: 1;
}

@media (max-width: 767px) {
  .ps-product {
    padding: 0;
  }
}

.ps-product--simple .ps-product__vendor {
  display: none;
}

.ps-product--simple .ps-product__content {
  padding-bottom: 10px;
}

.ps-product--simple .ps-product__actions {
  display: none;
}

.ps-product--simple:hover {
  border-color: transparent;
}

.ps-product--simple:hover .ps-product__content {
  display: block;
  visibility: visible;
  opacity: 1;
  height: auto;
}

.ps-product--wide {
  margin-bottom: 40px;
  display: flex;
  flex-flow: row nowrap;
  border: 1px solid transparent;
  border-bottom: 1px solid #f0f0f0;
  padding: 0;
}

.ps-product--wide > * {
  width: 100%;
}

.ps-product--wide .ps-product__price {
  font-size: 18px;
  font-weight: 600;
}

.ps-product--wide .ps-product__vendor {
  text-transform: none;
  font-size: 14px;
  border: none;
}

.ps-product--wide .ps-product__title {
  display: block;
  margin-bottom: 6px;
}

.ps-product--wide .ps-product__title:hover {
  text-decoration: none;
}

.ps-product--wide .ps-product__desc {
  position: relative;
}

.ps-product--wide .ps-product__desc li {
  padding-left: 15px;
  position: relative;
  color: #999999;
  line-height: 1.8em;
}

.ps-product--wide .ps-product__desc li:before {
  content: '';
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  top: 8px;
  left: 0;
  background-color: #999;
}

.ps-product--wide .ps-product__shopping {
  flex-basis: 180px;
}

.ps-product--wide .ps-product__shopping .ps-btn {
  width: 100%;
  text-align: center;
  font-weight: 600;
  padding: 11px 20px;
}

.ps-product--wide .ps-product__shopping .ps-product__actions {
  max-width: 100%;
  align-items: center;
}

.ps-product--wide .ps-product__shopping .ps-product__actions li {
  width: 100%;
  margin-right: 0;
}

.ps-product--wide .ps-product__shopping .ps-product__actions li a {
  width: auto;
  height: auto;
  border-radius: 0;
}

.ps-product--wide .ps-product__shopping .ps-product__actions li a i {
  margin-right: 5px;
}

.ps-product--wide .ps-product__shopping .ps-product__actions li a:hover {
  background-color: transparent;
  color: #F7E5CD;
}

.ps-product--wide .ps-product__thumbnail {
  min-width: 210px;
  flex-basis: 210px;
}

.ps-product--wide .ps-product__container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 20px;
}

.ps-product--wide:hover {
  border: 1px solid #ccc;
}

.ps-product--wide:hover .ps-product__content {
  display: block;
  height: auto;
  opacity: 1;
  visibility: visible;
}

@media (max-width: 1024px) {
  .ps-product--wide .ps-product__shopping {
    margin-top: 20px;
  }
  .ps-product--wide .ps-product__container {
    flex-flow: row wrap;
  }
}

@media (max-width: 479px) {
  .ps-product--wide {
    flex-flow: row wrap;
  }
  .ps-product--wide .ps-product__thumbnail {
    margin-bottom: 30px;
    max-width: 100%;
  }
}

.ps-product--horizontal {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 30px;
  padding-right: 15px;
}

.ps-product--horizontal .ps-product__rating .br-wrapper {
  display: inline-block;
}

.ps-product--horizontal .ps-product__rating .br-wrapper a {
  font-size: 13px;
  color: #F7E5CD;
}

.ps-product--horizontal .ps-product__rating span {
  color: #666;
  margin-left: 10px;
}

.ps-product--horizontal .ps-product__title {
  margin: 0;
  padding: 0 0 5px;
  font-size: 14px;
  line-height: 1.2em;
  color: #06c;
}

.ps-product--horizontal .ps-product__title:hover {
  color: #F7E5CD;
}

.ps-product--horizontal .ps-product__price {
  font-size: 16px;
  color: #000;
}

.ps-product--horizontal .ps-product__price.sale {
  color: #f30;
}

.ps-product--horizontal .ps-product__price.sale del {
  color: #666;
}

.ps-product--horizontal .ps-product__thumbnail {
  max-width: 100px;
  width: 100%;
}

.ps-product--horizontal .ps-product__content {
  width: 100%;
  padding-left: 20px;
}

@media (max-width: 1440px) {
  .ps-product--horizontal .ps-product__content {
    padding-right: 0;
  }
}

@media (max-width: 767px) {
  .ps-product--horizontal .ps-product__thumbnail {
    max-width: 75px;
  }
  .ps-product--horizontal .ps-product__content {
    padding-left: 10px;
  }
}

.ps-product--cart {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.ps-product--cart > * {
  flex-basic: 100%;
}

.ps-product--cart .ps-product__thumbnail {
  max-width: 100px;
}

.ps-product--cart .ps-product__content {
  padding-left: 30px;
}

.ps-product--cart .ps-product__content a {
  font-size: 16px;
  color: #0066cc;
}

.ps-product--cart .ps-product__content a:hover {
  color: #F7E5CD;
}

.ps-product--cart .ps-product__content p strong {
  font-weight: 500;
}

.ps-product--detail {
  margin-bottom: 5rem;
}

.ps-product--detail h1 {
  margin-bottom: 10px;
  font-size: 24px;
  color: #000;
  font-weight: 400;
  line-height: 1.2;
}

.ps-product--detail .slick-slide {
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  box-shadow: none;
}

.ps-product--detail .slick-slide:focus, .ps-product--detail .slick-slide:active, .ps-product--detail .slick-slide:hover {
  outline: none;
}

.ps-product--detail .ps-product__variants .item {
  margin-bottom: 10px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
}

.ps-product--detail .ps-product__variants .item img {
  opacity: 0.5;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.ps-product--detail .ps-product__variants .item .ps-video {
  position: relative;
  z-index: 10;
}

.ps-product--detail .ps-product__variants .item .ps-video:before {
  content: '\f04b';
  font-family: FontAwesome;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: white;
  z-index: 20;
}

.ps-product--detail .ps-product__variants .item:last-child {
  margin-bottom: 0;
}

.ps-product--detail .ps-product__variants .item.slick-current {
  border-color: #F7E5CD;
}

.ps-product--detail .ps-product__variants .item.slick-current img {
  opacity: 1;
}

.ps-product--detail .ps-product__variants .item:hover img {
  width: 100%;
  opacity: 1;
}

.ps-product--detail .ps-product__gallery {
  position: relative;
}

.ps-product--detail .ps-product__gallery .slick-arrow {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 100;
  width: 35px;
  height: 35px;
  color: #000;
  font-size: 18px;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  visibility: hidden;
  opacity: 0;
}

.ps-product--detail .ps-product__gallery .slick-arrow i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.ps-product--detail .ps-product__gallery .slick-arrow:first-child {
  left: 20px;
}

.ps-product--detail .ps-product__gallery .slick-arrow:last-child {
  right: 10px;
}

.ps-product--detail .ps-product__gallery .slick-arrow:hover {
  background-color: #F7E5CD;
  color: #fff;
}

.ps-product--detail .ps-product__gallery:hover .item:before {
  visibility: visible;
  opacity: 1;
}

.ps-product--detail .ps-product__gallery:hover .slick-arrow {
  visibility: visible;
  opacity: 1;
}

.ps-product--detail .ps-product__video a {
  vertical-align: middle;
}

.ps-product--detail .ps-product__video a:hover.ps-btn--black {
  background-color: #F7E5CD;
  border-color: #F7E5CD;
}

.ps-product--detail .ps-product__video i {
  font-size: 20px;
  font-weight: bold;
  vertical-align: middle;
}

.ps-product--detail .ps-product__badge {
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 30;
  padding: 0 5px;
  background-color: #fe9931;
}

.ps-product--detail .ps-product__badge i {
  font-style: normal;
  color: #ffffff;
  text-align: center;
  line-height: 1.2em;
  font-size: 1.3rem;
  letter-spacing: 0.2em;
}

.ps-product--detail .ps-product__info {
  text-align: left;
}

.ps-product--detail .ps-product__meta {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  margin-bottom: 20px;
  border-bottom: 1px solid #e1e1e1;
}

.ps-product--detail .ps-product__meta p {
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  margin-right: 10px;
  padding-right: 10px;
  line-height: 20px;
}

.ps-product--detail .ps-product__meta p a {
  color: #06c;
}

.ps-product--detail .ps-product__meta p:after {
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  width: 1px;
  height: 14px;
  background-color: #cccccc;
}

.ps-product--detail .ps-product__meta .ps-product__rating {
  display: flex;
  flex-flow: row nowrap;
}

.ps-product--detail .ps-product__meta .ps-product__rating .br-wrapper a {
  font-size: 14px;
}

.ps-product--detail .ps-product__meta .ps-product__rating span {
  margin-left: 10px;
  color: #666;
}

.ps-product--detail .ps-product__price {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 600;
  color: #000;
}

.ps-product--detail .ps-product__price.sale {
  color: #ec0101;
}

.ps-product--detail .ps-product__price.sale del {
  margin-right: 10px;
  font-size: 22px;
  color: #666;
}

.ps-product--detail .ps-product__price del {
  font-style: normal;
  color: #bcbcbc;
  font-size: 1.3rem;
}

.ps-product--detail .ps-product__price.sale del {
  margin-right: 0;
  font-size: 16px;
  font-weight: 500;
}

.ps-product--detail .ps-product__price.sale small {
  font-size: 16px;
  font-weight: 500;
}

.ps-product--detail .ps-product__desc {
  margin-bottom: 20px;
  border-bottom: 1px solid #e1e1e1;
}

.ps-product--detail .ps-product__desc p {
  color: #000;
}

.ps-product--detail .ps-product__desc p a {
  text-transform: uppercase;
  font-weight: 600;
  color: #09c;
  font-size: 16px;
}

.ps-product--detail .ps-product__desc p strong {
  font-weight: 600;
}

.ps-product--detail .ps-product__desc .ps-tag--in-stock {
  font-size: 14px;
}

.ps-product--detail .ps-product__desc .ps-tag--out-stock {
  color: red;
  text-transform: none;
  font-size: 14px;
}

.ps-product--detail .ps-product__desc ul {
  list-style-type: none;
}

.ps-product--detail .ps-product__desc ul li {
  color: #666;
}

.ps-product--detail .ps-product__desc ul li:before {
  top: 6px;
  background-color: #999;
  width: 6px;
  height: 6px;
}

.ps-product--detail .ps-product__shopping {
  display: flex;
  margin-bottom: 3rem;
  padding-bottom: 30px;
  flex-flow: row nowrap;
  align-items: flex-end;
  border-bottom: 1px solid #e1e1e1;
}

.ps-product--detail .ps-product__shopping > * {
  margin-right: 30px;
}

.ps-product--detail .ps-product__shopping figure figcaption {
  margin-bottom: 4px;
}

.ps-product--detail .ps-product__shopping .form-group--number {
  max-width: 115px;
}

.ps-product--detail .ps-product__shopping .form-group--number input {
  border-radius: 0;
  border-color: rgba(0, 0, 0, 0.25);
}

.ps-product--detail .ps-product__shopping .form-group--number i {
  color: rgba(0, 0, 0, 0.25);
}

.ps-product--detail .ps-product__shopping .ps-btn {
  font-weight: 600;
  padding-left: 30px;
  padding-right: 30px;
  vertical-align: top;
  max-height: 50px;
  font-size: 18px;
}

.ps-product--detail .ps-product__shopping .ps-btn.ps-btn--black {
  color: #fff;
}

.ps-product--detail .ps-product__shopping .ps-btn--gray {
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
}

.ps-product--detail .ps-product__shopping .ps-product__favorite {
  position: relative;
  display: inline-block;
  margin-right: 0;
  width: 40px;
  height: 40px;
  vertical-align: top;
  border: 1px solid #000;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.ps-product--detail .ps-product__shopping .ps-product__favorite i {
  padding-top: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.ps-product--detail .ps-product__shopping .ps-product__favorite:hover {
  border-color: #F7E5CD;
}

.ps-product--detail .ps-product__shopping .ps-product__actions {
  margin-right: 0;
}

.ps-product--detail .ps-product__shopping .ps-product__actions a {
  display: inline-block;
  margin-right: 20px;
  font-size: 26px;
  color: #999;
}

.ps-product--detail .ps-product__shopping .ps-product__actions a:last-child {
  margin-right: 0;
}

.ps-product--detail .ps-product__shopping.extend {
  align-items: flex-start;
  flex-flow: column wrap;
}

.ps-product--detail .ps-product__shopping.extend > * {
  margin-right: 0;
}

.ps-product--detail .ps-product__shopping.extend .ps-product__btn-group {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-end;
  max-width: 420px;
}

.ps-product--detail .ps-product__shopping.extend .ps-product__btn-group .ps-btn {
  min-width: 180px;
  text-align: center;
}

.ps-product--detail .ps-product__shopping.extend > .ps-btn {
  width: 100%;
  text-align: center;
}

@media screen and (max-width: 1365px) {
  .ps-product--detail .ps-product__shopping {
    flex-flow: row wrap;
  }
  .ps-product--detail .ps-product__shopping figure,
  .ps-product--detail .ps-product__shopping .ps-btn {
    margin-right: 10px;
  }
}

@media (max-width: 1679px) {
  .ps-product--detail .ps-product__shopping {
    flex-flow: row wrap;
  }
  .ps-product--detail .ps-product__shopping > * {
    margin-right: 10px;
  }
  .ps-product--detail .ps-product__shopping .ps-btn {
    margin-right: 10px;
    text-align: center;
  }
}

.ps-product--detail .ps-product__links {
  margin-bottom: 3rem;
}

.ps-product--detail .ps-product__links a {
  display: inline-block;
  margin-right: 20px;
  font-weight: 600;
  color: #000;
}

.ps-product--detail .ps-product__links a:hover {
  color: #F7E5CD;
}

.ps-product--detail .ps-product__specification {
  margin-bottom: 10px;
}

.ps-product--detail .ps-product__specification .report {
  text-decoration: underline;
}

.ps-product--detail .ps-product__specification .in-stock {
  color: #8bc500;
  font-weight: 600;
}

.ps-product--detail .ps-product__specification p {
  color: #000;
  margin-bottom: 0;
}

.ps-product--detail .ps-product__specification p strong {
  font-weight: 400;
  margin-right: 5px;
}

.ps-product--detail .ps-product__specification p a {
  color: #666;
}

.ps-product--detail .ps-product__specification p a:hover {
  color: #F7E5CD;
}

.ps-product--detail .ps-product__specification p.categories a {
  color: #09c;
}

.ps-product--detail .ps-product__sharing {
  padding-top: 2rem;
  margin-bottom: 0;
}

.ps-product--detail .ps-product__sharing p {
  line-height: 30px;
  color: #000;
  font-weight: 500;
}

.ps-product--detail .ps-product__sharing a {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  vertical-align: top;
}

.ps-product--detail .ps-product__sharing a i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: #ffffff;
}

.ps-product--detail .ps-product__sharing a:last-child {
  margin-right: 0;
}

.ps-product--detail .ps-product__sharing a:hover {
  color: #fff;
  background-color: #F7E5CD;
}

.ps-product--detail .ps-product__sharing a.facebook {
  background-color: #3b5999;
}

.ps-product--detail .ps-product__sharing a.twitter {
  background-color: #55acee;
}

.ps-product--detail .ps-product__sharing a.google {
  background-color: #dd4b39;
}

.ps-product--detail .ps-product__sharing a.linkedin {
  background-color: #0077B5;
}

.ps-product--detail .ps-product__bundle {
  margin-bottom: 2rem;
}

.ps-product--detail .ps-product__bundle h4 {
  font-weight: 600;
  font-size: 2.1rem;
}

.ps-product--detail .ps-product__bundle .form-group {
  margin-bottom: 15px;
}

.ps-product--detail .ps-product__bundle p {
  margin-bottom: 15px;
}

.ps-product--detail .ps-product__bundle p strong {
  font-weight: 600;
  color: #696969;
}

.ps-product--detail .ps-product__bundle .ps-checkbox {
  margin-bottom: 10px;
}

.ps-product--detail .ps-product__bundle-item p {
  margin-bottom: 0;
}

.ps-product--detail .ps-product__bundle-item img {
  margin-bottom: 10px;
  max-width: 100px;
}

.ps-product--detail .ps-product__bundle-item span {
  padding: 0 5px;
}

@media (max-width: 479px) {
  .ps-product--detail .ps-product__bundle-item img {
    max-width: 50px;
  }
}

.ps-product--detail .ps-product__group img {
  max-width: 80px;
}

.ps-product--detail .ps-product__group .form-group--number {
  min-width: 100px;
}

.ps-product--detail .ps-product__group .form-group--number .form-control {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
}

.ps-product--detail .ps-product__group table tr td {
  padding: 10px;
}

.ps-product--detail .ps-product__group table tr td a {
  color: #000;
}

.ps-product--detail .ps-product__group table tr td a:hover {
  color: #666;
}

.ps-product--detail .ps-product__group table tr td:nth-child(2) {
  min-width: 230px;
}

.ps-product--detail .ps-gallery__item {
  display: block;
}

.ps-product--detail .ps-product__variations {
  margin-bottom: 2rem;
}

.ps-product--detail .ps-product__variations figure {
  margin-bottom: 15px;
}

.ps-product--detail .ps-product__variations figure figcaption {
  margin-bottom: 15px;
  font-size: 14px;
  color: #666;
  font-weight: 400;
}

.ps-product--detail .ps-product__variations figure figcaption strong {
  color: #000;
}

.ps-product--detail .ps-product__progress-bar {
  display: block;
  width: 100%;
}

.ps-product--detail .ps-product__progress-bar .ps-progress__value {
  margin-bottom: 5px;
  position: relative;
  display: block;
  height: 20px;
  background-color: #e4e4e4;
}

.ps-product--detail .ps-product__progress-bar .ps-progress__value > span {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  height: 100%;
  background-color: #F7E5CD;
  width: 50%;
}

.ps-product--detail .ps-product__progress-bar p {
  margin-bottom: 0;
}

.ps-product--detail .ps-product__progress-bar p > b {
  color: #690;
}

.ps-product--detail .ps-product__countdown {
  margin-bottom: 25px;
  padding: 20px;
  background-color: #f4f4f4;
}

.ps-product--detail .ps-product__countdown figure {
  display: flex;
  margin-bottom: 20px;
}

.ps-product--detail .ps-product__countdown figure > * {
  width: 100%;
}

.ps-product--detail .ps-product__countdown figure figcaption {
  max-width: 260px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  color: #000;
  padding-right: 20px;
}

.ps-product--detail .ps-product__countdown .ps-countdown li {
  position: relative;
  display: inline-block;
  padding-right: 20px;
  margin-right: 10px;
  text-align: center;
}

.ps-product--detail .ps-product__countdown .ps-countdown li:after {
  content: ':';
  color: #666;
  font-size: 20px;
  position: absolute;
  top: 20px;
  right: 0;
  font-weight: 600;
}

.ps-product--detail .ps-product__countdown .ps-countdown li span {
  display: flex;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  font-size: 30px;
  color: red;
  font-weight: 600;
}

.ps-product--detail .ps-product__countdown .ps-countdown li p {
  margin-bottom: 0;
}

.ps-product--detail .ps-product__countdown .ps-countdown li:last-child {
  margin-right: 0;
}

.ps-product--detail .ps-product__countdown .ps-countdown li:last-child:after {
  display: none;
}

@media (max-width: 1680px) {
  .ps-product--detail .ps-product__countdown figure {
    flex-flow: column wrap;
  }
  .ps-product--detail .ps-product__countdown figure figcaption {
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .ps-product--detail .ps-product__countdown figure figcaption {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.6em;
  }
  .ps-product--detail .ps-product__countdown .ps-countdown {
    display: flex;
    max-width: 320px;
    justify-content: space-between;
  }
  .ps-product--detail .ps-product__countdown .ps-countdown li span {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }
}

@media (max-width: 479px) {
  .ps-product--detail .ps-product__countdown .ps-countdown li p {
    display: none;
  }
}

.ps-product--detail .ps-product__thumbnail {
  width: 100%;
  display: flex;
  flex-flow: row-reverse nowrap;
  align-content: flex-start;
}

.ps-product--detail .ps-product__thumbnail > * {
  width: 100%;
}

.ps-product--detail .ps-product__thumbnail .ps-product__badge {
  width: auto;
}

.ps-product--detail .ps-product__thumbnail figure {
  position: relative;
  display: block;
  max-width: calc(100% - 60px);
  padding-left: 10px;
}

.ps-product--detail .ps-product__thumbnail .ps-product__variants {
  max-width: 60px;
  width: 100%;
  min-width: 60px;
}

.ps-product--detail .ps-product__thumbnail .ps-product__video {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 20;
}

.ps-product--detail .ps-product__thumbnail .ps-wrapper {
  position: relative;
}

.ps-product--detail .ps-product__heading {
  margin-bottom: 40px;
  margin-top: 10px;
  padding-bottom: 15px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
  border-bottom: 1px solid #e1e1e1;
}

.ps-product--detail .ps-product__thumbnail[data-vertical='false'] {
  -webkit-flex-flow: row wrap;
  -moz-flex-flow: row wrap;
  flex-flow: row wrap;
}

.ps-product--detail .ps-product__thumbnail[data-vertical='false'] figure {
  max-width: 100%;
  padding-left: 0;
}

.ps-product--detail .ps-product__thumbnail[data-vertical='false'] .ps-product__variants {
  max-width: 100%;
  text-align: center;
  width: 100%;
}

.ps-product--detail .ps-product__thumbnail[data-vertical='false'] .ps-product__variants .item {
  margin-bottom: 0;
  margin-right: 10px;
}

.ps-product--detail .ps-product__thumbnail[data-vertical='false'] .ps-product__variants .item.slick-active:last-child {
  margin-right: 0;
}

.ps-product--detail .ps-product__thumbnail[data-vertical='false'] .ps-product__variants .slick-list {
  margin-right: -10px;
}

.ps-product--detail .ps-product__thumbnail[data-vertical='false'] .ps-product__variants .slick-arrow {
  font-size: 20px;
  line-height: 20px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.ps-product--detail .ps-product__thumbnail[data-vertical='false'] .ps-product__variants .slick-arrow:first-child {
  left: -20px;
}

.ps-product--detail .ps-product__thumbnail[data-vertical='false'] .ps-product__variants .slick-arrow:first-child i:before {
  content: '\f104';
}

.ps-product--detail .ps-product__thumbnail[data-vertical='false'] .ps-product__variants .slick-arrow:last-child {
  right: 0px;
}

.ps-product--detail .ps-product__thumbnail[data-vertical='false'] .ps-product__variants .slick-arrow:last-child i:before {
  content: '\f105';
}

.ps-product--detail .ps-product__thumbnail[data-vertical='false'] .ps-product__variants .slick-arrow:hover i {
  color: #000;
}

.ps-product--detail .ps-product__thumbnail[data-vertical='false'] .ps-product__gallery {
  margin-bottom: 10px;
}

.ps-product--detail .ps-product__thumbnail[data-vertical='false'] .ps-product__gallery img {
  width: 100%;
}

.ps-product--detail .ps-product__header {
  display: flex;
  flex-flow: row nowrap;
}

.ps-product--detail .ps-product__header > * {
  width: 100%;
}

.ps-product--detail .ps-product__header .ps-product__thumbnail {
  max-width: 43%;
}

.ps-product--detail .ps-product__header .ps-product__info {
  max-width: 57%;
  padding-left: 30px;
}

.ps-product--detail > .ps-product__content {
  padding-top: 100px;
}

.ps-product--detail .ps-tab-list {
  position: relative;
  display: block;
  margin: 0 0 50px;
  padding-left: 0;
  border-bottom: 1px solid #e1e1e1;
}

.ps-product--detail .ps-tab-list li {
  display: inline-block;
}

.ps-product--detail .ps-tab-list li a {
  position: relative;
  display: block;
  color: #999999;
  font-size: 20px;
  padding: 15px 20px;
  line-height: 20px;
  font-weight: 600;
}

.ps-product--detail .ps-tab-list li a:before {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #F7E5CD;
  -webkit-transform: scale3d(0, 1, 1);
  -moz-transform: scale3d(0, 1, 1);
  -ms-transform: scale3d(0, 1, 1);
  -o-transform: scale3d(0, 1, 1);
  transform: scale3d(0, 1, 1);
  -webkit-transform-origin: 100% 50%;
  -moz-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  -o-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  transition: transform 0.75s cubic-bezier(0.77, 0, 0.175, 1);
}

.ps-product--detail .ps-tab-list li a:hover {
  color: #000;
}

.ps-product--detail .ps-tab-list li a:hover:before {
  -webkit-transform: scale3d(1, 1, 1);
  -moz-transform: scale3d(1, 1, 1);
  -ms-transform: scale3d(1, 1, 1);
  -o-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transform-origin: 0 50%;
  -moz-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
  -o-transform-origin: 0 50%;
  transform-origin: 0 50%;
}

.ps-product--detail .ps-tab-list li:last-child {
  margin-right: 0;
}

.ps-product--detail .ps-tab-list li.active a {
  color: #000;
  border-color: #000;
}

.ps-product--detail .ps-tab-list li.active a:before {
  -webkit-transform: scale3d(1, 1, 1);
  -moz-transform: scale3d(1, 1, 1);
  -ms-transform: scale3d(1, 1, 1);
  -o-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transform-origin: 0 50%;
  -moz-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
  -o-transform-origin: 0 50%;
  transform-origin: 0 50%;
}

.ps-product--detail .ps-tab p {
  margin-bottom: 25px;
}

.ps-product--detail .ps-product .ps-product__price {
  font-size: 16px;
  font-weight: 400;
}

.ps-product--detail.ps-product--thumbnail-right .ps-product__thumbnail {
  -webkit-flex-flow: row nowrap;
  -moz-flex-flow: row nowrap;
  flex-flow: row nowrap;
}

.ps-product--detail.ps-product--thumbnail-right .ps-product__thumbnail figure {
  padding-left: 0;
  padding-right: 10px;
}

@media (max-width: 1199px) {
  .ps-product--detail .ps-product__thumbnail {
    -webkit-flex-flow: column wrap;
    -moz-flex-flow: column wrap;
    flex-flow: column wrap;
  }
  .ps-product--detail .ps-product__thumbnail figure {
    margin-bottom: 30px;
    padding: 0;
    width: 100%;
    max-width: 100%;
  }
  .ps-product--detail .ps-product__thumbnail .ps-product__variants {
    position: relative;
    padding: 0 40px;
    float: none;
    width: 100%;
    max-width: 100%;
  }
  .ps-product--detail .ps-product__thumbnail .ps-product__variants .item {
    border: none;
    margin-right: 10px;
    margin-bottom: 0;
  }
  .ps-product--detail .ps-product__thumbnail .ps-product__variants .slick-arrow {
    display: inline-block;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 16px;
  }
  .ps-product--detail .ps-product__thumbnail .ps-product__variants .slick-arrow:first-child {
    left: 0;
  }
  .ps-product--detail .ps-product__thumbnail .ps-product__variants .slick-arrow:last-child {
    right: 0;
  }
  .ps-product--detail.ps-product--thumbnail-right .ps-product__thumbnail {
    -webkit-flex-flow: row wrap;
    -moz-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .ps-product--detail.ps-product--thumbnail-right .ps-product__thumbnail figure {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 991px) {
  .ps-product--detail .ps-product__header .ps-product__thumbnail {
    padding-right: 0;
  }
  .ps-product--detail .ps-product__header .ps-product__info {
    padding-left: 3rem;
  }
  .ps-product--detail .ps-product__shopping .form-group--number {
    max-width: 100px;
  }
  .ps-product--detail .ps-product__thumbnail {
    padding-right: 0;
  }
  .ps-product--detail > .ps-product__content {
    padding-top: 75px;
  }
  .ps-product--detail > .ps-product__content .tab-list li {
    margin-right: 3rem;
  }
  .ps-product--detail > .ps-product__content .tab-list li a {
    font-size: 1.4rem;
  }
}

@media (max-width: 767px) {
  .ps-product--detail .ps-product__header {
    -webkit-flex-flow: column wrap;
    -moz-flex-flow: column wrap;
    flex-flow: column wrap;
  }
  .ps-product--detail .ps-product__header .ps-product__thumbnail {
    max-width: 100%;
    margin-bottom: 3rem;
  }
  .ps-product--detail .ps-product__header .ps-product__info {
    width: 100%;
    padding-left: 0;
    max-width: 100%;
  }
  .ps-product--detail h1 {
    font-size: 2.4rem;
  }
  .ps-product--detail .ps-product__sharing {
    text-align: left;
  }
  .ps-product--detail > .ps-product__content {
    padding-top: 50px;
  }
  .ps-product--detail > .ps-product__content .ps-tab-list li {
    display: block;
    margin-right: 0;
  }
  .ps-product--detail > .ps-product__content .ps-tab-list li a {
    display: block;
    padding: 5px 0;
    font-size: 18px;
  }
  .ps-product--detail > .ps-product__content .ps-tab-list li a:before {
    display: none;
  }
}

@media (max-width: 479px) {
  .ps-product--detail .ps-product__meta p {
    float: none;
    margin-bottom: 1rem;
  }
  .ps-product--detail .ps-product__meta > a {
    display: block;
    float: none;
    padding-left: 0;
  }
  .ps-product--detail .ps-product__shopping {
    display: block;
  }
  .ps-product--detail .ps-product__shopping figure {
    width: 100%;
  }
  .ps-product--detail .ps-product__shopping figure .form-group--number {
    width: 100%;
    max-width: 100%;
  }
  .ps-product--detail .ps-product__shopping .ps-btn {
    display: none;
    width: 100%;
    margin-right: 0;
  }
  .ps-product--detail .ps-product__shopping .ps-btn {
    display: none;
  }
  .ps-product--detail .ps-product__actions {
    padding-top: 10px;
  }
}

@media (max-width: 375px) {
  .ps-product--detail .ps-product__shopping .ps-form--number {
    max-width: 100%;
    width: 100%;
    margin-bottom: 1rem;
  }
  .ps-product--detail .ps-product__shopping .ps-btn {
    padding-left: 20px;
    padding-right: 20px;
  }
  .ps-product--detail .ps-product__shopping button {
    width: 100%;
  }
}

.ps-product--hot-deal .ps-product__thumbnail .ps-product__variants {
  max-width: 52px;
  min-width: 52px;
}

.ps-product--hot-deal .ps-product__thumbnail .ps-product__variants .item {
  border: 1px solid #ccc;
}

.ps-product--hot-deal .ps-product__thumbnail .ps-product__variants .item img {
  opacity: 1;
}

.ps-product--hot-deal .ps-product__thumbnail .ps-product__variants .item.slick-current {
  border-color: #dd2400;
}

.ps-product--hot-deal .ps-product__thumbnail > figure {
  padding-left: 20px;
  max-width: calc(100% - 52px);
}

.ps-product--hot-deal .ps-product__badge {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #96b101;
}

.ps-product--hot-deal .ps-product__badge span {
  font-weight: 500;
  color: #ffffff;
  font-size: 13px;
  line-height: 1.2em;
}

.ps-product--hot-deal .ps-product__info h5 {
  font-size: 12px;
  margin-bottom: 7px;
  color: #000000;
  font-weight: 400;
  text-transform: uppercase;
}

.ps-product--hot-deal .ps-product__info .ps-product__name {
  font-weight: 600;
  color: #000;
  font-size: 18px;
}

.ps-product--hot-deal .ps-product__price {
  margin-bottom: 10px;
}

.ps-product--hot-deal .ps-product__processs-bar .ps-progress {
  margin-bottom: 10px;
  height: 17px;
  background: #e4e4e4;
}

.ps-product--hot-deal .ps-product__processs-bar .ps-progress span {
  background-color: #dd2400;
}

.ps-product--hot-deal .ps-product__processs-bar p strong {
  color: #690;
}

.ps-product--hot-deal .ps-product__meta {
  border-bottom: none;
  display: block;
}

.ps-product--hot-deal .ps-product__meta a {
  color: #0066cc;
}

.ps-product--hot-deal .ps-product__expires {
  border-top: 1px solid #e4e4e4;
  padding-top: 20px;
}

.ps-product--hot-deal .ps-product__expires p {
  margin-bottom: 0;
  line-height: 1.8em;
  color: #000;
}

.ps-product--hot-deal .ps-product__expires .ps-countdown {
  margin-bottom: 10px;
}

.ps-product--hot-deal .ps-product__specification p {
  margin-right: 0;
  padding-right: 0;
}

.ps-product--hot-deal .ps-product__specification p:after {
  display: none;
}

.ps-product--hot-deal .ps-countdown {
  display: flex;
  flex-flow: row nowrap;
}

.ps-product--hot-deal .ps-countdown li {
  position: relative;
  padding-right: 30px;
  text-align: center;
}

.ps-product--hot-deal .ps-countdown li:after {
  content: ':';
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 25px;
  color: #999999;
}

.ps-product--hot-deal .ps-countdown li span {
  display: inline-block;
  margin-bottom: 10px;
  width: 56px;
  height: 56px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #dd2400;
  font-weight: 600;
  font-size: 20px;
}

.ps-product--hot-deal .ps-countdown li:last-child {
  padding-right: 0;
}

.ps-product--hot-deal .ps-countdown li:last-child:after {
  display: none;
}

.ps-product--hot-deal .ps-product__header .ps-product__thumbnail {
  padding-right: 0;
}

.ps-product--hot-deal .ps-product__header .ps-product__info {
  padding-left: 30px;
}

@media (max-width: 1199px) {
  .ps-product--hot-deal .ps-product__thumbnail .ps-product__variants {
    min-width: 100%;
    max-width: 100%;
  }
  .ps-product--hot-deal .ps-product__thumbnail figure {
    max-width: 100%;
    padding-left: 0;
  }
}

@media (max-width: 991px) {
  .ps-product--hot-deal .ps-product__header .ps-product__info {
    padding-left: 10px;
  }
  .ps-product--hot-deal .ps-countdown li span {
    width: 40px;
    height: 40px;
    font-size: 14px;
  }
}

@media (max-width: 479px) {
  .ps-product--hot-deal .ps-product__name {
    font-size: 16px;
  }
  .ps-product--hot-deal .ps-product__expires > p {
    margin-bottom: 10px;
  }
  .ps-product--hot-deal .ps-countdown li:after {
    top: 0;
    font-size: 20px;
  }
  .ps-product--hot-deal .ps-countdown li span {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
  .ps-product--hot-deal .ps-countdown p {
    font-size: 10px;
  }
}

.ps-product--full-content .ps-product__price-right {
  padding: 30px;
  background-color: #f1f1f1;
}

.ps-product--full-content .ps-product__price-right .ps-product__shopping {
  display: block;
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.ps-product--full-content .ps-product__price-right .ps-product__shopping > * {
  margin-right: 0;
}

.ps-product--full-content .ps-product__price-right .ps-product__shopping figure {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e1e1e1;
}

.ps-product--full-content .ps-product__price-right .ps-product__shopping figure .form-group--number {
  max-width: 100%;
}

.ps-product--full-content .ps-product__price-right .ps-product__shopping figure .form-group--number input {
  background-color: #ffffff;
}

.ps-product--full-content .ps-product__price-right .ps-product__shopping .ps-btn {
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
  font-size: 16px;
}

.ps-product--full-content .ps-product__price-right .ps-product__actions {
  border: none;
}

.ps-product--full-content .ps-product__price-right .ps-product__actions a {
  display: block;
  margin-right: 0;
  font-size: 14px;
  text-align: center;
  color: #666;
}

.ps-product--full-content .ps-product__price-right .ps-product__actions a:hover {
  color: #000;
}

.ps-product--full-content .ps-product__top {
  display: flex;
  flex-flow: row nowrap;
}

.ps-product--full-content .ps-product__top .ps-product__header {
  max-width: calc(100% - 270px);
  padding-right: 30px;
}

.ps-product--full-content .ps-product__top .ps-product__price-right {
  width: 100%;
  max-width: 270px;
}

@media (max-width: 991px) {
  .ps-product--full-content .ps-product__top {
    flex-direction: column;
  }
  .ps-product--full-content .ps-product__top .ps-product__header {
    margin-bottom: 30px;
    max-width: 100%;
  }
  .ps-product--full-content .ps-product__top .ps-product__price-right {
    max-width: 100%;
  }
}

.ps-product--box .ps-product__box {
  background-color: #fff;
  padding: 30px 20px;
}

.ps-product--cart-mobile {
  display: flex;
  flex-flow: row nowrap;
}

.ps-product--cart-mobile > * {
  width: 100%;
}

.ps-product--cart-mobile .ps-product__thumbnail {
  max-width: 56px;
}

.ps-product--cart-mobile .ps-product__content {
  position: relative;
  padding-right: 30px;
  padding-left: 20px;
}

.ps-product--cart-mobile .ps-product__content .ps-product__remove {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 18px;
  color: #666;
}

.ps-product--cart-mobile .ps-product__content a {
  color: #06c;
}

.ps-product--cart-mobile .ps-product__content a:hover {
  color: #F7E5CD;
}

.ps-product--cart-mobile .ps-product__content p strong {
  color: #000;
  font-weight: 500;
}

.ps-product--cart-mobile .ps-product__content small {
  font-size: 14px;
  color: #000;
}

.ps-product--photo {
  position: relative;
  overflow: hidden;
}

.ps-product--photo > a {
  display: block;
}

.ps-product--photo > a:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.05);
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.9));
  background-image: linear-gradient(to bottom,rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.9));
  transition: all 0.4s ease;
  visibility: hidden;
  opacity: 0;
}

.ps-product--photo .ps-product__actions {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  max-width: 200px;
  transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1) 0s;
}

.ps-product--photo .ps-product__actions li {
  margin-right: 10px;
}

.ps-product--photo .ps-product__actions li a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  color: #ffffff;
}

.ps-product--photo .ps-product__actions li a:hover {
  background-color: #c81919;
  color: #ffffff;
}

.ps-product--photo .ps-product__actions:last-child {
  margin-right: 0;
}

.ps-product--photo .ps-product__content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  z-index: 20;
  transform: translateY(100%);
  transition: all 0.4s ease;
  visibility: hidden;
  opacity: 0;
}

.ps-product--photo .ps-product__content span {
  color: #fff;
}

.ps-product--photo:hover > a:before {
  visibility: visible;
  opacity: 1;
}

.ps-product--photo:hover .ps-product__content {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.ps-product--header-sticky {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.ps-product--header-sticky > * {
  flex-basis: 100%;
}

.ps-product--header-sticky .ps-product__title {
  display: block;
  line-height: 1.4em;
  font-size: 20px;
  line-height: 1;
  font-weight: 400;
  margin: 0 0 5px;
}

.ps-product--header-sticky .ps-tab-list li {
  display: inline-block;
  margin-right: 20px;
}

.ps-product--header-sticky .ps-tab-list li a {
  font-weight: 600;
  color: #666;
}

.ps-product--header-sticky .ps-tab-list li a:hover {
  color: #F7E5CD;
}

.ps-product--header-sticky .ps-tab-list li.active a {
  color: #F7E5CD;
}

.ps-product--header-sticky .ps-tab-list li:last-child {
  margin-right: 0;
}

.ps-product--header-sticky .ps-product__price span {
  font-size: 22px;
  color: #f30;
  font-weight: 600;
}

.ps-product--header-sticky .ps-product__price del {
  display: block;
  color: #666;
  font-size: 14px;
}

.ps-product--header-sticky .ps-product__thumbnail {
  max-width: 60px;
}

.ps-product--header-sticky .ps-product__shopping {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ps-product--header-sticky .ps-product__shopping .ps-product__price {
  padding-right: 30px;
}

.ps-product--header-sticky .ps-product__shopping .ps-btn {
  padding: 10px 30px;
}

.ps-product--header-sticky .ps-product__wrapper {
  padding-left: 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.ps-product--quickview {
  display: none;
  margin-bottom: 0;
  max-height: 500px;
  overflow: auto;
}

.ps-product--quickview .owl-slider img {
  width: 100%;
  overflow: hidden;
}

.ps-product--quickview .owl-slider .owl-nav .owl-prev {
  margin-left: 0;
}

.ps-product--quickview .owl-slider .owl-nav .owl-next {
  margin-right: 0;
}

.ps-product--quickview .ps-product__images {
  position: relative;
}

.ps-product--quickview .ps-product__images .slick-arrow {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 100;
  width: 35px;
  height: 35px;
  color: #000;
  font-size: 18px;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  visibility: hidden;
  opacity: 0;
}

.ps-product--quickview .ps-product__images .slick-arrow i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.ps-product--quickview .ps-product__images .slick-arrow:first-child {
  left: 20px;
}

.ps-product--quickview .ps-product__images .slick-arrow:last-child {
  right: 10px;
}

.ps-product--quickview .ps-product__images .slick-arrow:hover {
  background-color: #F7E5CD;
  color: #fff;
}

.ps-product--quickview .ps-product__images:hover .item:before {
  visibility: visible;
  opacity: 1;
}

.ps-product--quickview .ps-product__images:hover .slick-arrow {
  visibility: visible;
  opacity: 1;
}

.ps-product--quickview .ps-product__shopping {
  border-bottom: 0;
  padding-bottom: 0;
}

@media screen and (min-width: 1200px) {
  .ps-product--quickview {
    display: block;
  }
}

.ps-product--search-result {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border: none;
}

.ps-product--search-result > * {
  flex-basis: 100%;
}

.ps-product--search-result .ps-product__title {
  padding-bottom: 0;
  margin-bottom: 0;
}

.ps-product--search-result .ps-product__price {
  font-size: 12px;
  margin-bottom: 0;
}

.ps-product--search-result .ps-product__thumbnail {
  max-width: 60px;
  min-width: 60px;
}

.ps-product--search-result .ps-product__content {
  padding-left: 30px;
}

.ps-product--search-result:hover {
  border: none;
}

@media (max-width: 479px) {
  .ps-product--search-result {
    flex-flow: row nowrap;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eaeaea;
  }
  .ps-product--search-result .ps-product__thumbnail {
    margin-bottom: 0;
  }
  .ps-product--search-result .ps-product__content {
    padding-left: 10px;
  }
}

.lg-backdrop {
  z-index: 10001;
}

.lg-outer {
  z-index: 10002;
}

#product-quickview .modal-dialog {
  max-width: 960px;
}

#product-quickview .modal-dialog .modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}

#product-quickview .modal-dialog .modal-content {
  position: relative;
  padding: 40px 0 0 20px;
}

.ps-product--medicine .ps-product__container {
  position: relative;
}

.ps-product--medicine .ps-product__add-to-cart {
  visibility: hidden;
  opacity: 0;
  padding-bottom: 20px;
}

.ps-product--medicine .ps-product__add-to-cart .ps-btn {
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 500;
  color: #fff;
  background-color: #28afb1;
}

.ps-product--medicine .ps-product__add-to-cart .ps-btn:hover {
  background-color: #239a9c;
  color: #fff !important;
}

.ps-product--medicine .ps-product__progress-bar .ps-progress__value > span {
  background-color: #28afb1;
}

.ps-product--medicine:hover {
  border-bottom: 1px solid silver;
}

.ps-product--medicine:hover .ps-product__add-to-cart {
  visibility: visible;
  opacity: 1;
}

.ps-product--medicine:hover .ps-product__content {
  display: block;
  height: auto;
  visibility: visible;
  opacity: 1;
}

.ps-banner {
  position: relative;
}

.ps-banner .ps-banner__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.ps-banner--1 {
  position: relative;
}

.ps-banner--1 p {
  color: #0099CC;
  font-size: 16px;
}

.ps-banner--1 h4 {
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: 500;
  color: #000;
  line-height: 1.1em;
}

.ps-banner--1 h4 strong {
  color: #669900;
}

.ps-banner--1 .ps-btn {
  padding: 11px 24px;
  font-weight: 600;
}

.ps-banner--1 .ps-banner__content {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  padding: 0 240px;
}

@media (max-width: 1680px) {
  .ps-banner--1 .ps-banner__content {
    padding: 0 50px;
  }
}

.ps-filter {
  margin-bottom: 50px;
}

.ps-filter__header::after {
  clear: both;
  content: "";
  display: table;
}

.ps-filter__header .form-group {
  display: inline-block;
  margin-right: 8rem;
  margin-bottom: 0;
}

.ps-filter__header .form-group label {
  margin-right: 3rem;
  margin-bottom: 0;
  vertical-align: middle;
}

.ps-filter__header .form-group .ps-select {
  min-width: 150px;
  vertical-align: middle;
}

.ps-filter__header .form-group .ps-select .selectize-input {
  position: relative;
  vertical-align: top;
  padding: 10px 15px;
  border: none;
  outline: none;
  box-shadow: none;
}

.ps-filter__header .form-group .ps-select .selectize-dropdown {
  cursor: pointer;
}

.ps-filter__header figure {
  float: right;
}

.ps-filter__header figure .ps-pagination {
  display: inline-block;
}

.ps-filter__header figure .ps-pagination p {
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
  margin-right: 30px;
}

.ps-filter__header figure .ps-pagination .pagination {
  vertical-align: middle;
}

.ps-filter__header figure .ps-pagination .pagination li a {
  width: 40px;
  line-height: 40px;
}

.ps-filter__trigger {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.ps-filter__trigger > p {
  display: inline-block;
  margin-bottom: 0;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 40px;
  color: #000;
}

.ps-filter__trigger i {
  color: #999999;
}

.ps-filter__icon {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  vertical-align: top;
}

.ps-filter__icon span, .ps-filter__icon:before, .ps-filter__icon:after {
  position: absolute;
  right: 10px;
  height: 2px;
  width: 20px;
  background-color: #303030;
  z-index: 100;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.ps-filter__icon span {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

.ps-filter__icon:before, .ps-filter__icon:after {
  content: '';
}

.ps-filter__icon:before {
  top: 12px;
}

.ps-filter__icon:after {
  bottom: 12px;
}

.ps-filter__icon:hover {
  cursor: pointer;
}

.ps-filter__icon.active span {
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
  visibility: hidden;
  opacity: 0;
}

.ps-filter__icon.active:before, .ps-filter__icon.active:after {
  top: 50%;
  bottom: auto;
  width: 20px;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  background-color: #fc354c;
}

.ps-filter__icon.active:before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ps-filter__icon.active:after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.ps-filter__result {
  text-align: right;
}

.ps-filter__result p {
  margin-bottom: 0;
  line-height: 40px;
}

.ps-filter__content {
  display: none;
  padding: 0;
}

.ps-filter__content::after {
  clear: both;
  content: "";
  display: table;
}

.ps-filter__column {
  float: left;
  width: 25%;
  padding: 0 15px;
}

.ps-filter__column > h3 {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 10px;
  font-size: 1.8rem;
  color: #333;
  font-weight: 600;
  letter-spacing: .05em;
  text-transform: none;
}

.ps-filter__column > h3:before {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 100px;
  height: 1px;
  background-color: #c3c3c3;
}

.ps-filter.active .ps-filter__content {
  display: block;
}

@media (max-width: 991px) {
  .ps-filter__column {
    width: 50%;
    max-width: 300px;
  }
}

@media (max-width: 767px) {
  .ps-filter__result {
    text-align: left;
  }
}

@media (max-width: 479px) {
  .ps-filter__column {
    width: 100%;
    max-width: 300px;
  }
}

.ps-filter--sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 460px;
  height: 100vh;
  overflow-y: auto;
  z-index: 99999;
  background-color: #fff;
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
  transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1) 0s;
}

.ps-filter--sidebar .ps-filter__header {
  position: relative;
  padding: 15px 20px;
  background-color: #f5f5f5;
}

.ps-filter--sidebar .ps-filter__header h3 {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 20px;
  text-transform: capitalize;
}

.ps-filter--sidebar .ps-filter__header .ps-btn--close {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 10px;
}

.ps-filter--sidebar .ps-filter__header .ps-btn--close:before, .ps-filter--sidebar .ps-filter__header .ps-btn--close:after {
  background-color: #000;
}

.ps-filter--sidebar .ps-filter__content {
  display: block;
  padding: 2rem;
}

.ps-filter--sidebar .widget_shop {
  background-color: #ffffff;
  padding: 0;
}

.ps-filter--sidebar .widget_shop .widget-title {
  padding-bottom: 0;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 16px;
}

.ps-filter--sidebar .widget_shop .widget-title:before {
  display: none;
}

.ps-filter--sidebar .widget_shop .form-group--select .form-control {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
}

.ps-filter--sidebar .widget_shop.widget_filter {
  text-align: center;
}

.ps-filter--sidebar .widget_shop.widget_filter .widget-title {
  text-align: left;
}

.ps-filter--sidebar .widget_shop.widget_filter .ps-slider {
  margin-bottom: 2rem;
}

.ps-filter--sidebar .widget_shop.widget_filter .ps-btn--black {
  padding: 8px 35px;
  font-weight: 600;
  text-transform: none;
}

.ps-filter--sidebar.active {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}

.ps-filter--shopping {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 3rem 0 0;
}

.ps-filter--shopping .ps-filter__trigger {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  vertical-align: top;
}

.ps-filter--shopping .ps-filter__trigger i {
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.ps-filter--shopping .ps-filter__column-switch a {
  display: inline-block;
  height: 26px;
  border: 1px solid #878787;
  margin-right: 20px;
}

.ps-filter--shopping .ps-filter__column-switch a:before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 10px;
  height: 20px;
  background-color: #878787;
  transition: all .3s;
}

.ps-filter--shopping .ps-filter__column-switch a.two {
  width: 29px;
}

.ps-filter--shopping .ps-filter__column-switch a.two:before {
  box-shadow: 13px 0 0 #878787, 13px 0 0 #878787;
}

.ps-filter--shopping .ps-filter__column-switch a.two:hover:before, .ps-filter--shopping .ps-filter__column-switch a.two.active:before {
  box-shadow: 13px 0 0 #222, 13px 0 0 #222;
}

.ps-filter--shopping .ps-filter__column-switch a.three {
  width: 42px;
}

.ps-filter--shopping .ps-filter__column-switch a.three:before {
  box-shadow: 13px 0 0 #878787, 26px 0 0 #878787;
}

.ps-filter--shopping .ps-filter__column-switch a.three:hover:before, .ps-filter--shopping .ps-filter__column-switch a.three.active:before {
  box-shadow: 13px 0 0 #222, 26px 0 0 #222;
}

.ps-filter--shopping .ps-filter__column-switch a.four {
  width: 55px;
}

.ps-filter--shopping .ps-filter__column-switch a.four:before {
  box-shadow: 13px 0 0 #878787, 26px 0 0 #878787, 39px 0 0 #878787;
}

.ps-filter--shopping .ps-filter__column-switch a.four:hover:before, .ps-filter--shopping .ps-filter__column-switch a.four.active:before {
  box-shadow: 13px 0 0 #222, 26px 0 0 #222, 39px 0 0 #222;
}

.ps-filter--shopping .ps-filter__column-switch a.six {
  width: 81px;
}

.ps-filter--shopping .ps-filter__column-switch a.six:before {
  box-shadow: 13px 0 0 #878787, 26px 0 0 #878787, 39px 0 0 #878787, 52px 0 0 #878787, 65px 0 0 #878787;
}

.ps-filter--shopping .ps-filter__column-switch a.six:hover:before, .ps-filter--shopping .ps-filter__column-switch a.six.active:before {
  box-shadow: 13px 0 0 #222, 26px 0 0 #222, 39px 0 0 #222, 52px 0 0 #222, 65px 0 0 #222;
}

.ps-filter--shopping .ps-filter__column-switch a.active {
  border-color: #222;
}

.ps-filter--shopping .ps-filter__column-switch a.active:before {
  background-color: #222222;
}

.ps-filter--shopping .ps-filter__column-switch a:hover {
  border-color: #222;
}

.ps-filter--shopping .ps-filter__column-switch a:hover:before {
  background-color: #222222;
}

.ps-filter--shopping .ps-filter__column-switch a:last-child {
  margin-right: 0;
}

@media (max-width: 767px) {
  .ps-filter--shopping .ps-filter__column-switch {
    display: none;
  }
}

#shop-filter-lastest a {
  padding: 10px 20px;
  font-size: 16px;
}

@media (min-width: 1200px) {
  .table-responsive {
    overflow-x: initial;
  }
}

.ps-table thead > tr > th {
  font-family: "Work Sans", sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: #515356;
  text-transform: uppercase;
  border-bottom: 1px solid #e5e5e5;
}

.ps-table tbody > tr > td {
  vertical-align: middle;
  padding: 20px 30px;
  border: 1px solid #ddd;
  color: #666;
}

@media (max-width: 768px) {
  .ps-table--responsive {
    border: none;
    max-width: 100vw;
  }
  .ps-table--responsive .ps-product--cart {
    flex-flow: column nowrap;
    align-items: flex-end;
    margin-left: 30px;
  }
  .ps-table--responsive .ps-product--cart .ps-product__thumbnail {
    display: none;
  }
  .ps-table--responsive .ps-product--cart .ps-product__content {
    padding-left: 0;
  }
  .ps-table--responsive thead {
    display: none;
  }
  .ps-table--responsive tbody {
    display: block;
  }
  .ps-table--responsive tbody tr {
    display: block;
    margin-bottom: 10px;
    border: 1px solid #ddd;
  }
  .ps-table--responsive tbody tr td[data-label] {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    text-transform: capitalize;
    border-top: 1px solid #ddd;
    min-width: 100px;
  }
  .ps-table--responsive tbody tr td[data-label]:before {
    content: attr(data-label) ":";
    font-weight: 600;
  }
  .ps-table--responsive tbody tr td[data-label]:first-child {
    border-top: none;
  }
}

.ps-table--specification tbody tr td:first-child {
  background-color: #f4f4f4;
  font-weight: 500;
  color: #000;
  width: 150px;
}

.ps-table--faqs {
  background-color: #fff;
}

.ps-table--faqs h4 {
  color: #F7E5CD;
}

.ps-table--faqs tr td {
  border: none;
  padding: 30px 15px;
}

.ps-table--faqs tr td.question {
  color: #000;
  font-size: 16px;
  width: 300px;
  font-weight: 500;
}

.ps-table--faqs tr td.heading {
  width: 270px;
}

.ps-table--compare {
  table-layout: fixed;
}

.ps-table--compare tbody tr td {
  padding: 20px 30px;
}

.ps-table--compare tbody tr td .price.sale {
  color: #ec0101;
}

.ps-table--compare tbody tr td .price.sale del {
  color: #000;
}

.ps-table--compare tbody tr td .ps-btn {
  width: 100%;
  text-align: center;
}

.ps-table--compare tbody tr td .in-stock {
  color: #89aa01;
  font-weight: 600;
}

.ps-table--compare tbody tr td .out-stock {
  font-weight: 600;
}

.ps-table--compare tbody tr td .sold-by {
  text-decoration: underline;
  color: #0066cc;
}

.ps-table--compare tbody tr td .sold-by:hover {
  color: #F7E5CD;
}

.ps-table--compare tbody tr td.heading {
  color: #000000;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  background-color: #f4f4f4;
}

@media (max-width: 1199px) {
  .ps-table--compare {
    table-layout: initial;
  }
  .ps-table--compare tbody tr td {
    min-width: 300px;
  }
}

.ps-table--whishlist thead tr th {
  background-color: #f2f2f2;
  text-transform: uppercase;
  color: #000;
  text-align: center;
  font-weight: 600;
}

.ps-table--whishlist thead tr th:nth-child(2) {
  text-align: left;
}

.ps-table--whishlist tbody tr td {
  padding: 30px 10px;
  vertical-align: middle;
  font-size: 16px;
}

.ps-table--whishlist tbody tr td:last-child {
  text-align: right;
}

@media (max-width: 991px) {
  .ps-table--whishlist tbody tr td {
    min-width: 200px;
  }
  .ps-table--whishlist tbody tr td:first-child {
    max-width: 50px;
    min-width: 50px;
    text-align: center;
  }
  .ps-table--whishlist tbody tr td:nth-child(2) {
    min-width: 300px;
  }
}

.ps-table--shopping-cart thead tr th {
  padding: 15px 20px;
  text-transform: uppercase;
  color: #000;
  text-align: center;
  font-weight: 600;
  background-color: #f2f2f2;
  border: none;
}

.ps-table--shopping-cart thead tr th:first-child {
  text-align: left;
}

.ps-table--shopping-cart tbody tr td {
  padding: 20px 10px;
  vertical-align: middle;
  font-size: 16px;
}

.ps-table--shopping-cart tbody tr td .form-group--number {
  max-width: 100px;
  display: inline-block;
}

.ps-table--shopping-cart tbody tr td .form-group--number input {
  border-radius: 0;
}

.ps-table--shopping-cart tbody tr td .ps-product--cart {
  max-width: 500px;
}

.ps-table--shopping-cart tbody tr td:last-child {
  text-align: right;
}

.ps-table--shopping-cart tbody tr td:last-child a {
  font-size: 20px;
}

.ps-table--product-groupped .ps-tag--out-stock {
  color: red;
  font-weight: 500;
}

.ps-table--product-groupped .ps-btn {
  padding: 15px 20px;
  border-radius: 0;
}

.ps-table--product-groupped .title {
  font-size: 16px;
}

.ps-table--product-groupped tbody tr {
  vertical-align: middle;
}

.ps-table--product-groupped tbody tr td {
  border: none;
  vertical-align: middle;
}

.ps-table--product-groupped tbody tr td:first-child {
  width: 150px;
}

@media (max-width: 767px) {
  .ps-table--product-groupped .title {
    font-size: 14px;
  }
  .ps-table--product-groupped tbody tr td {
    min-width: 200px;
  }
}

@media (max-width: 1680px) {
  .ps-table--product-groupped .ps-tag--out-stock span {
    display: block;
  }
}

.ps-table--vendor thead tr th {
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 600;
  color: #000;
  background-color: #f4f4f4;
}

.ps-table--vendor tbody tr td {
  padding: 8px 16px;
  color: #000;
}

.ps-table--vendor tbody tr td a {
  color: #000;
  color: #0066cc;
}

.ps-table--vendor tbody tr td a:hover {
  color: #F7E5CD;
}

.ps-table--vendor tbody tr td strong {
  text-transform: uppercase;
  color: #000;
}

.ps-table--vendor-status tbody tr td {
  color: #000;
}

.ps-table--vendor-status tbody tr td strong {
  color: #000;
  text-transform: uppercase;
  font-weight: 600;
}

.ps-table--vendor-status tbody tr td:first-child {
  background-color: #f4f4f4;
}

.ps-table--notification tbody tr td:first-child {
  max-width: 250px;
  width: 50%;
}

.ps-loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #fff;
  flex-flow: row nowrap;
  -webkit-transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
  -moz-transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
  transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
}

.ps-loading__content {
  height: 100vh;
  display: flex;
  flex-flow: row nowrap;
}

.ps-loading__content > * {
  display: block;
  width: 25%;
  -webkit-transition: -webkit-transform 1s cubic-bezier(0.7, 0, 0.3, 1);
  -moz-transition: -moz-transform 1s cubic-bezier(0.7, 0, 0.3, 1);
  transition: transform 1s cubic-bezier(0.7, 0, 0.3, 1);
  -webkit-transform-origin: 0 50%;
  -moz-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
  -o-transform-origin: 0 50%;
  transform-origin: 0 50%;
  background-color: #fcfaf6;
}

.ps-loading.loaded {
  visibility: hidden;
  opacity: 0;
  background-color: transparent;
  -webkit-transition-delay: 0.7s;
  -moz-transition-delay: 0.7s;
  transition-delay: 0.7s;
}

.ps-loading.loaded .ps-loading__content > * {
  -webkit-transform: scale3d(0, 1, 1);
  -moz-transform: scale3d(0, 1, 1);
  -ms-transform: scale3d(0, 1, 1);
  -o-transform: scale3d(0, 1, 1);
  transform: scale3d(0, 1, 1);
  -webkit-transition-delay: 0.5s;
  -moz-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  overflow: hidden;
}

#loader-wrapper .loader-section {
  position: fixed;
  top: 0;
  width: 51%;
  height: 100%;
  background: #ffffff;
  z-index: 999;
}

#loader-wrapper .loader-section.section-left {
  left: 0;
}

#loader-wrapper .loader-section.section-right {
  right: 0;
}

#loader-wrapper img {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -50px;
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  z-index: 999999999;
}

.loaded #loader-wrapper img {
  opacity: 0;
  transition: all 0.3s ease-out;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Loaded styles */
.loaded #loader-wrapper .loader-section.section-left {
  transform: translateX(-100%);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loaded #loader-wrapper .loader-section.section-right {
  transform: translateX(100%);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loaded #loader-wrapper {
  visibility: hidden;
  transform: translateY(-100%);
  transition: all 0.3s 1s ease-out;
}

.ps-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.ps-modal__container {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 2rem 4rem;
  z-index: 10;
  background-color: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  max-width: 945px;
  width: 100%;
}

.ps-modal__close {
  position: absolute;
  top: 0;
  right: 0;
  -webkit-transform: translate(50%, -50%);
  -moz-transform: translate(50%, -50%);
  -ms-transform: translate(50%, -50%);
  -o-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  display: inline-block;
  width: 50px;
  height: 50px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background-color: #5e6b9d;
}

.ps-modal__close i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: #ffffff;
}

.ps-modal.active {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 991px) {
  .ps-modal__container {
    max-width: 90%;
  }
  .ps-modal__close {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    background-color: transparent;
    width: 30px;
    height: 30px;
  }
  .ps-modal__close i {
    color: #5e6b9d;
  }
}

@media (max-width: 767px) {
  .ps-modal {
    overflow: auto;
  }
  .ps-modal__container {
    position: relative;
    top: 0;
    left: 0;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    margin: 3rem auto;
    overflow: auto;
  }
}

@media (max-width: 479px) {
  .ps-modal__container {
    max-width: 300px;
  }
}

#login .ps-modal__container {
  max-width: 600px;
}

#login .ps-modal__container .ps-btn--close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.ps-popup {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 99999;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.ps-popup__content {
  position: relative;
  max-width: 830px;
  width: 100%;
  -webkit-transition: tranform 0.4s ease;
  -moz-transition: tranform 0.4s ease;
  transition: tranform 0.4s ease;
}

.ps-popup__close {
  display: inline-block;
  position: absolute;
  width: 40px;
  height: 40px;
  top: 10px;
  right: 10px;
  background-color: #000;
  border: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
}

.ps-popup__close i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: #ffffff;
}

.ps-popup__close:hover {
  background-color: #222222;
}

.ps-popup.active {
  visibility: visible;
  opacity: 1;
  -webkit-transition-delay: 0.5s;
  -moz-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

@media (max-width: 991px) {
  .ps-popup {
    padding: 100px 30px 20px;
  }
  .ps-popup .ps-popup__content {
    position: relative;
    top: 0;
    left: 0;
    max-width: 800px;
    margin: 0 auto;
  }
  .ps-popup.active .ps-popup__content {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@media (max-width: 479px) {
  .ps-popup {
    padding-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.ps-accordion {
  margin-bottom: 20px;
}

.ps-accordion__header {
  position: relative;
  background-color: #f6f6f8;
  padding: 10px 40px 10px 20px;
  cursor: pointer;
}

.ps-accordion__header p {
  margin-bottom: 0;
  line-height: 20px;
  font-weight: 700;
  color: #000;
}

.ps-accordion__header span {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  width: 40px;
  height: 40px;
  background-color: #222;
}

.ps-accordion__header span i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #ffffff;
}

.ps-accordion__content {
  padding: 20px;
}

.ps-tab {
  display: none;
}

.ps-tab.active {
  display: block;
}

/*3. COMPONENTS */
.ps-cart--mini {
  position: relative;
}

.ps-cart--mini .ps-cart__items {
  position: relative;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-bottom: none;
}

.ps-cart--mini .ps-cart__items:before {
  content: '';
  display: inline-block;
  position: absolute;
  top: -8px;
  right: 30px;
  width: 16px;
  height: 16px;
  border-left: 1px solid #e1e1e1;
  border-top: 1px solid #e1e1e1;
  background-color: #ffffff;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

.ps-cart--mini .ps-cart__items .ps-product--cart-mobile {
  margin-bottom: 30px;
}

.ps-cart--mini .ps-cart__content {
  position: absolute;
  min-width: 370px;
  right: -30px;
  z-index: 30;
  padding-top: 10px;
  -webkit-transform: translate(0 20px);
  -moz-transform: translate(0 20px);
  -ms-transform: translate(0 20px);
  -o-transform: translate(0 20px);
  transform: translate(0 20px);
  transition: all .4s ease;
  visibility: hidden;
  opacity: 0;
}

.ps-cart--mini .ps-cart__footer {
  padding: 10px 20px 20px;
  background-color: #ffffff;
  border: 1px solid #e1e1e1;
  border-top: 0;
}

.ps-cart--mini .ps-cart__footer h3 {
  display: block;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
}

.ps-cart--mini .ps-cart__footer h3 strong {
  float: right;
  color: red;
}

.ps-cart--mini figure {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.ps-cart--mini figure .ps-btn {
  padding: 12px 25px;
}

.ps-cart--mini:hover .ps-cart__content {
  -webkit-transform: translate(0, 0px);
  -moz-transform: translate(0, 0px);
  -ms-transform: translate(0, 0px);
  -o-transform: translate(0, 0px);
  transform: translate(0, 0px);
  visibility: visible;
  opacity: 1;
}

.ps-cart--mobile .ps-cart__content {
  padding: 20px 10px 0;
  min-height: calc(100vh - 250px);
}

.ps-cart--mobile .ps-cart__footer {
  padding: 30px 20px;
  border-top: 1px solid #e1e1e1;
}

.ps-cart--mobile .ps-cart__footer h3 {
  display: block;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
}

.ps-cart--mobile .ps-cart__footer h3 strong {
  float: right;
  color: red;
}

.ps-cart--mobile figure {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.ps-cart--mobile figure .ps-btn {
  padding: 12px 25px;
}

.ps-form--newsletter h3 {
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 24px;
}

.ps-form--newsletter p {
  margin-bottom: 0;
}

.ps-form--newsletter .form-group--nest button {
  padding-left: 45px;
  padding-right: 45px;
}

@media (max-width: 1199px) {
  .ps-form--newsletter p {
    margin-bottom: 10px;
  }
}

.ps-form--quick-search {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
}

.ps-form--quick-search .form-control {
  background-color: #fff;
  color: #000;
  height: 42px;
  border: none;
  border-radius: 0;
}

.ps-form--quick-search .form-group--icon {
  background-color: #ffffff;
  border-radius: 4px 0 0 4px;
}

.ps-form--quick-search .form-group--icon i {
  z-index: 1;
}

.ps-form--quick-search select.form-control {
  border-right: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
  padding: 0 10px;
  text-indent: 10px;
  position: relative;
  z-index: 2;
  background-color: transparent;
}

.ps-form--quick-search select.form-control::-webkit-input-placeholder {
  color: #666666;
}

.ps-form--quick-search select.form-control::-moz-placeholder {
  color: #666666;
}

.ps-form--quick-search select.form-control:-moz-placeholder {
  color: #666666;
}

.ps-form--quick-search select.form-control:-ms-input-placeholder {
  color: #666666;
}

.ps-form--quick-search button {
  background-color: #000;
  color: #ffffff;
  border: none;
  font-weight: 700;
  padding: 0 24px;
  border-radius: 0 4px 4px 0;
}

.ps-form--widget-search {
  position: relative;
  margin-bottom: 20px;
}

.ps-form--widget-search input {
  height: 34px;
  background-color: #ffffff;
  border-radius: 0;
}

.ps-form--widget-search button {
  border: none;
  background-color: transparent;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 10px;
}

.ps-form--review h4 {
  margin-bottom: 20px;
  display: block;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2em;
  text-transform: uppercase;
}

.ps-form--review p sup {
  margin-left: 5px;
  color: #ec0101;
}

.ps-form--review .form-group__rating {
  display: flex;
  align-items: center;
}

.ps-form--review .form-group__rating label {
  margin-bottom: 0;
  line-height: 20px;
  margin-right: 15px;
}

.ps-form--review .form-group__rating .br-wrapper {
  line-height: 20px;
}

.ps-form--review .form-group__rating .br-wrapper a {
  font-size: 14px;
}

.ps-form--review .form-group__rating .br-wrapper.br-theme-fontawesome-stars .br-widget {
  height: auto;
  line-height: 20px;
}

.ps-form--review .form-control {
  border-radius: 0;
}

.ps-form--review button {
  border-radius: 0;
  font-weight: 600;
}

.ps-form--post-comment h3 {
  margin-bottom: 40px;
  font-weight: 600;
  font-size: 24px;
  color: #000;
}

.ps-form--post-comment .submit {
  text-align: center;
}

.ps-form--post-comment .submit .ps-btn {
  padding: 0 30px;
  height: 50px;
}

.ps-form--contact-us .form-control {
  background-color: #ffffff;
}

.ps-form--contact-us .submit {
  text-align: center;
}

.ps-form--contact-us .submit button {
  height: 50px;
  padding: 0 30px;
}

.ps-form--account {
  max-width: 430px;
  margin: 0 auto;
  padding-top: 130px;
}

.ps-form--account .ps-tab-list {
  text-align: center;
  margin-bottom: 30px;
}

.ps-form--account .ps-tab-list li {
  display: inline-block;
  padding: 0 15px;
}

.ps-form--account .ps-tab-list li a {
  font-size: 30px;
  color: #666;
  font-weight: 600;
}

.ps-form--account .ps-tab-list li.active a {
  color: #000;
}

.ps-form--account .form-forgot {
  position: relative;
}

.ps-form--account .form-forgot input {
  padding-right: 100px;
}

.ps-form--account .form-forgot > a {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 10px;
  color: #06c;
}

.ps-form--account .form-forgot > a:hover {
  color: #F7E5CD;
}

.ps-form--account .ps-checkbox > label {
  color: #000;
}

.ps-form--account .ps-list--social {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 0 -5px;
}

.ps-form--account .ps-list--social li {
  padding: 0 5px;
  width: 100%;
  text-align: center;
  margin-right: 0;
}

.ps-form--account .ps-list--social li a {
  display: block;
  line-height: 20px;
  padding: 15px 0;
}

.ps-form--account .ps-list--social li a i {
  color: #ffffff;
  text-align: center;
}

.ps-form--account .ps-list--social li a.facebook {
  background-color: #3b5999;
}

.ps-form--account .ps-list--social li a.google {
  background-color: #dd4b39;
}

.ps-form--account .ps-list--social li a.twitter {
  background-color: #55acee;
}

.ps-form--account .ps-list--social li a.instagram {
  background-color: #e4405f;
}

.ps-form--account .ps-form__content {
  padding: 30px 30px 0;
}

.ps-form--account .ps-form__content h5 {
  margin: 0 0 25px;
  font-size: 16px;
  font-weight: 400;
}

.ps-form--account .ps-form__footer {
  padding: 30px 30px;
}

.ps-form--account .ps-tab {
  background-color: #ffffff;
}

.ps-form--order-tracking {
  max-width: 630px;
  margin: 0 auto 50px;
}

.ps-form--order-tracking .form-group {
  margin-bottom: 30px;
}

.ps-form--order-tracking .form-group > label {
  font-size: 18px;
  font-weight: 600;
  color: #000;
}

.ps-form--order-tracking .form-group input {
  border-color: #bfbfbf;
}

.ps-form--order-tracking .form-group input::-webkit-input-placeholder {
  color: #a9a9a9;
}

.ps-form--order-tracking .form-group input::-moz-placeholder {
  color: #a9a9a9;
}

.ps-form--order-tracking .form-group input:-moz-placeholder {
  color: #a9a9a9;
}

.ps-form--order-tracking .form-group input:-ms-input-placeholder {
  color: #a9a9a9;
}

@media (max-width: 991px) {
  .ps-form--order-tracking .form-group > label {
    font-size: 16px;
    font-weight: 500;
  }
}

.ps-form--search-mobile input {
  height: 40px;
  background-color: #ffffff;
  border: none;
  border-radius: 0;
}

.ps-form--search-mobile button {
  width: 40px;
  height: 40px;
  background-color: #000;
  border: none;
}

.ps-form--search-mobile button i {
  color: #ffffff;
}

.ps-form--subscribe-popup {
  padding: 60px 50px;
}

.ps-form--subscribe-popup h4 {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 400;
}

.ps-form--subscribe-popup h4 strong {
  color: #F7E5CD;
}

.ps-form--subscribe-popup p {
  margin-bottom: 20px;
}

.ps-form--subscribe-popup input {
  margin-bottom: 20px;
  height: 40px;
  border: 2px solid #c9c9c9;
}

.ps-form--subscribe-popup button {
  height: 40px;
  width: auto;
  padding: 0 30px;
}

.ps-form--subscribe-popup .ps-checkbox > label {
  color: #000;
}

.ps-form--subscribe-popup .ps-checkbox > label:before {
  border-radius: 3px;
}

.ps-form--subscribe-popup .ps-form__content {
  max-width: 360px;
  text-align: center;
}

.ps-form--vendor-datetimepicker {
  margin-bottom: 24px;
}

.ps-form--vendor-datetimepicker .input-group {
  border: 1px solid #d0d0d0;
  border: none;
  margin-bottom: 10px;
}

.ps-form--vendor-datetimepicker .input-group:after {
  content: '\e789';
  font-family: 'Linearicons';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 10px;
  font-size: 16px;
}

.ps-form--vendor-datetimepicker .input-group input {
  height: 40px;
}

.ps-form--vendor-datetimepicker .input-group .input-group-text {
  padding: 0 20px;
  font-size: 14px;
  min-width: 60px;
  text-align: center;
  font-weight: 500;
  color: #000;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.ps-form--vendor-datetimepicker button {
  background-color: #88aa00;
  border-radius: 0;
  height: 40px;
  padding: 0 30px;
  color: #ffffff;
}

.ps-form--vendor-datetimepicker button i {
  vertical-align: middle;
  margin-right: 4px;
}

.ps-form--vendor-datetimepicker button:hover {
  background-color: #9cc400;
}

.ps-form--photo-search .select2 {
  position: relative;
}

.ps-form--photo-search .select2:before {
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 1px;
  height: 50px;
  background-color: #dfdfdf;
  z-index: 20;
}

.ps-form--photo-search .select2 .select2-selection {
  position: relative;
  height: 60px;
  display: flex;
  align-items: center;
  border: none;
  min-width: 190px;
  border-radius: 0 6px 6px 0;
}

.ps-form--photo-search .select2 .select2-selection .select2-selection__rendered {
  width: 100%;
  font-weight: 600;
  color: #000;
}

.ps-form--photo-search .form-group--icon {
  margin-bottom: 0;
  width: 100%;
}

.ps-form--photo-search .form-group--icon i {
  right: auto;
  left: 20px;
  font-size: 18px;
  color: #000;
}

.ps-form--photo-search .form-group--icon i:before {
  font-size: inherit;
}

.ps-form--photo-search .form-group--icon input {
  background-color: #fff;
  border: none;
  height: 60px;
  text-indent: 30px;
  padding-left: 20px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 6px 0 0 6px;
}

.ps-form--photo-search .form-group--icon input::-webkit-input-placeholder {
  font-weight: 400;
  font-size: 14px;
}

.ps-form--photo-search .form-group--icon input::-moz-placeholder {
  font-weight: 400;
  font-size: 14px;
}

.ps-form--photo-search .form-group--icon input:-moz-placeholder {
  font-weight: 400;
  font-size: 14px;
}

.ps-form--photo-search .form-group--icon input:-ms-input-placeholder {
  font-weight: 400;
  font-size: 14px;
}

.ps-form--photo-search .ps-form__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
}

.ps-form--photo-search .ps-form__footer a {
  display: inline-block;
  margin-right: 10px;
}

.ps-form--photo-search .ps-form__footer a:hover {
  color: #c81919;
}

.ps-form--photo-search .ps-form__footer a:last-child {
  margin-right: 0;
}

@media (max-width: 480px) {
  .ps-form--photo-search .ps-form__content {
    align-items: flex-start;
    flex-flow: column-reverse wrap;
  }
  .ps-form--photo-search .ps-form__content .select2 {
    margin-bottom: 20px;
    min-width: 100%;
  }
  .ps-form--photo-search .ps-form__content .select2 .select2-selection {
    margin: 0 auto;
  }
}

.ps-form--checkout h4 {
  margin-bottom: 20px;
  font-weight: 600;
}

@media (min-width: 768px) {
  .ps-form--checkout .ps-form__submit {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }
}

.ps-form--account-setting .ps-form__header h3 {
  font-weight: 600;
}

.ps-search {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99999;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.75s cubic-bezier(0.7, 0, 0.3, 1);
  -moz-transition: all 0.75s cubic-bezier(0.7, 0, 0.3, 1);
  transition: all 0.75s cubic-bezier(0.7, 0, 0.3, 1);
  -webkit-transition-delay: 0;
  -moz-transition-delay: 0;
  transition-delay: 0;
}

.ps-search:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.95);
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.7, 0, 0.3, 1);
  -moz-transition: -moz-transform 0.6s cubic-bezier(0.7, 0, 0.3, 1);
  transition: transform 0.6s cubic-bezier(0.7, 0, 0.3, 1);
  -webkit-transform: scale3d(1, 0, 1);
  -moz-transform: scale3d(1, 0, 1);
  -ms-transform: scale3d(1, 0, 1);
  -o-transform: scale3d(1, 0, 1);
  transform: scale3d(1, 0, 1);
  -webkit-transform-origin: 50% 100%;
  -moz-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  -o-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

.ps-search__content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 1200px;
  width: 100%;
  padding: 0 3rem;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.ps-search .ps-form--primary-search {
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -o-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: all 0.6s cubic-bezier(0.7, 0, 0.3, 1);
  -moz-transition: all 0.6s cubic-bezier(0.7, 0, 0.3, 1);
  transition: all 0.6s cubic-bezier(0.7, 0, 0.3, 1);
  visibility: hidden;
  opacity: 0;
}

.ps-search > .ps-btn--close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 50px;
  height: 50px;
  background-color: #000;
  z-index: 1000;
  -webkit-transform: scale(0, 0) rotate(0);
  -moz-transform: scale(0, 0) rotate(0);
  -ms-transform: scale(0, 0) rotate(0);
  -o-transform: scale(0, 0) rotate(0);
  transform: scale(0, 0) rotate(0);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: all 0.6s cubic-bezier(0.7, 0, 0.3, 1);
  -moz-transition: all 0.6s cubic-bezier(0.7, 0, 0.3, 1);
  transition: all 0.6s cubic-bezier(0.7, 0, 0.3, 1);
  border: none;
}

.ps-search > .ps-btn--close:before, .ps-search > .ps-btn--close:after {
  background-color: #ffffff;
  height: 50%;
}

.ps-search > .ps-btn--close:hover {
  background-color: #f44336;
}

.ps-search.active {
  visibility: visible;
  opacity: 1;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
}

.ps-search.active:before {
  -webkit-transform: scale3d(1, 1, 1);
  -moz-transform: scale3d(1, 1, 1);
  -ms-transform: scale3d(1, 1, 1);
  -o-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transform-origin: 50% 0%;
  -moz-transform-origin: 50% 0%;
  -ms-transform-origin: 50% 0%;
  -o-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
}

.ps-search.active > .ps-btn--close {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1, 1) rotate(180deg);
  -moz-transform: scale(1, 1) rotate(180deg);
  -ms-transform: scale(1, 1) rotate(180deg);
  -o-transform: scale(1, 1) rotate(180deg);
  transform: scale(1, 1) rotate(180deg);
  -webkit-transition-delay: 0.75s;
  -moz-transition-delay: 0.75s;
  transition-delay: 0.75s;
}

.ps-search.active .ps-form--primary-search {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 0.35s;
  -moz-transition-delay: 0.35s;
  transition-delay: 0.35s;
  visibility: visible;
  opacity: 1;
}

@media (max-width: 479px) {
  .ps-search__content {
    padding: 0 1.5rem;
  }
}

.ps-section--default {
  margin-bottom: 80px;
}

.ps-section--default .ps-section__header {
  margin-bottom: 50px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e3e3e3;
}

.ps-section--default .ps-section__header h3 {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 20px;
}

.ps-section--default .ps-section__links {
  display: inline-block;
}

.ps-section--default .ps-section__links li {
  display: inline-block;
  vertical-align: top;
  margin-right: 30px;
}

.ps-section--default .ps-section__links li a {
  color: #666;
}

.ps-section--default .ps-section__links li a:hover {
  color: #F7E5CD;
}

.ps-section--default .ps-section__links li:last-child {
  margin-right: 0;
}

.ps-section--gray {
  padding: 70px 0;
  background-color: #f4f5f5;
}

@media (max-width: 767px) {
  .ps-section--gray {
    padding: 50px 0;
  }
}

@media (max-width: 479px) {
  .ps-section--gray {
    padding: 35px 0;
  }
}

.ps-section--vendor {
  padding: 100px 0;
}

.ps-section--vendor .ps-section__header {
  text-align: center;
  padding-bottom: 90px;
}

.ps-section--vendor .ps-section__header p {
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 600;
  color: #666666;
  text-transform: uppercase;
}

.ps-section--vendor .ps-section__header h4 {
  font-weight: 400;
  color: #000;
  font-size: 30px;
}

@media (max-width: 991px) {
  .ps-section--vendor {
    padding: 75px 0;
  }
  .ps-section--vendor .ps-section__header {
    padding-bottom: 50px;
  }
  .ps-section--vendor .ps-section__header p {
    margin-bottom: 16px;
  }
}

@media (max-width: 767px) {
  .ps-section--vendor {
    padding: 60px 0;
  }
  .ps-section--vendor .ps-section__header h4 {
    font-size: 24px;
  }
}

@media (max-width: 479px) {
  .ps-section--vendor {
    padding: 50px 0;
  }
  .ps-section--vendor .ps-section__header p {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .ps-section--vendor .ps-section__header h4 {
    font-size: 24px;
  }
}

.ps-section--shopping {
  padding: 100px 0;
}

.ps-section--shopping .ps-section__header {
  text-align: center;
  padding-bottom: 100px;
}

.ps-section--shopping .ps-section__header h1 {
  font-size: 48px;
  font-weight: 600;
}

@media (max-width: 991px) {
  .ps-section--shopping {
    padding: 75px 0;
  }
  .ps-section--shopping .ps-section__header h1 {
    font-size: 36px;
  }
}

@media (max-width: 767px) {
  .ps-section--shopping {
    padding: 60px 0;
  }
  .ps-section--shopping .ps-section__header {
    padding-bottom: 40px;
  }
  .ps-section--shopping .ps-section__header h1 {
    font-size: 30px;
  }
}

@media (max-width: 479px) {
  .ps-section--shopping {
    padding: 50px 0;
  }
}

.ps-newsletter {
  padding: 65px 0;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
}

@media (max-width: 767px) {
  .ps-newsletter {
    padding: 50px 0;
  }
}

.ps-product-list .ps-section__links {
  display: inline-block;
}

.ps-product-list .ps-section__links li {
  display: inline-block;
  vertical-align: top;
  margin-right: 30px;
}

.ps-product-list .ps-section__links li a {
  color: #666;
}

.ps-product-list .ps-section__links li a:hover {
  color: #F7E5CD;
}

.ps-product-list .ps-section__links li:last-child {
  margin-right: 0;
}

.ps-product-list .ps-section__header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  background-color: #f4f4f4;
  border-bottom: 1px solid #e3e3e3;
}

.ps-product-list .ps-section__header h3 {
  margin-bottom: 0;
  display: inline-block;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
}

.ps-product-list .ps-section__content {
  padding-top: 55px;
}

.ps-product-list--2 .ps-section__header {
  background-color: transparent;
  border-bottom: 1px solid #e1e1e1;
}

.ps-product-list--2 .ps-section__header h3 {
  font-size: 24px;
  font-weight: 600;
}

@media (max-width: 1199px) {
  .ps-product-list .ps-section__header {
    flex-flow: row wrap;
  }
}

@media (max-width: 991px) {
  .ps-product-list .ps-section__header h3 {
    padding-bottom: 10px;
  }
}

.ps-shopping-product {
  min-height: 80vh;
}

@media (max-width: 1199px) {
  .ps-shopping-product .ps-product {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .ps-shopping-product .row {
    margin: 0 -2px;
  }
  .ps-shopping-product .row > * {
    padding: 0 2px;
  }
  .ps-shopping-product .ps-product {
    margin-bottom: 4px;
  }
  .ps-shopping-product .ps-product .ps-product__badge {
    top: 5px;
    right: 5px;
  }
}

@media (max-width: 1440px) and (min-width: 1200px) {
  .ps-new-arrivals .ps-section__content .row {
    margin: 0;
  }
  .ps-new-arrivals .ps-section__content .col-12 {
    padding: 0;
  }
}

@media (max-width: 991px) {
  .ps-new-arrivals .ps-section__content .col-12 {
    max-width: 50%;
    flex-basis: 50%;
  }
}

@media (max-width: 600px) {
  .ps-new-arrivals .ps-section__content .col-12 {
    max-width: 100%;
    flex-basis: 100%;
  }
}

.ps-top-categories h3 {
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 1em;
  color: #000;
  font-weight: 600;
}

@media (min-width: 1680px) {
  .ps-top-categories .row .col-xl-2 {
    max-width: calc(100% / 8);
  }
}

.ps-deal-of-day .ps-section__header {
  margin-bottom: 65px;
  padding-bottom: 10px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
}

.ps-deal-of-day .ps-section__header > a {
  display: inline-block;
  padding-bottom: 2px;
  color: #000;
  border-bottom: 1px solid #000;
  min-width: 60px;
}

.ps-deal-of-day .ps-section__header > a:hover {
  color: #F7E5CD;
  border-color: #F7E5CD;
}

.ps-shop-brand {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 767px) {
  .ps-shop-brand {
    flex-flow: row wrap;
  }
  .ps-shop-brand > * {
    max-width: 20%;
    padding: 5px 10px;
  }
}

@media (max-width: 479px) {
  .ps-shop-brand > * {
    max-width: 25%;
  }
}

.ps-layout--shop {
  padding-bottom: 90px;
  display: flex;
  flex-flow: row nowrap;
}

.ps-layout--shop > * {
  width: 100%;
}

.ps-layout--shop .ps-layout__left {
  width: 100%;
  min-width: 320px;
  max-width: 310px;
  padding-right: 30px;
}

.ps-layout--shop .ps-layout__right {
  max-width: calc(100% - 320px);
}

.ps-layout--shop .ps-layout__right .owl-slider {
  max-width: 100%;
}

@media (max-width: 1199px) {
  .ps-layout--shop {
    flex-flow: column-reverse wrap;
  }
  .ps-layout--shop .ps-layout__left {
    max-width: 100%;
    padding-right: 0;
    display: none;
  }
  .ps-layout--shop .ps-layout__right {
    max-width: 100%;
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .ps-layout--shop {
    padding-bottom: 45px;
  }
}

.ps-shopping .select2 {
  display: inline-block;
}

.ps-shopping .select2 .select2-selection__rendered {
  padding: 6px 20px;
}

.ps-shopping .ps-shopping__view {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.ps-shopping .ps-shopping__view p {
  margin-bottom: 0;
  margin-right: 20px;
  color: #000;
}

.ps-shopping .ps-shopping__view ul li {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
}

.ps-shopping .ps-shopping__view ul li a {
  font-size: 24px;
  color: #999999;
}

.ps-shopping .ps-shopping__view ul li:last-child {
  margin-right: 0;
}

.ps-shopping .ps-shopping__view ul li.active a {
  color: #000;
}

.ps-shopping .ps-shopping__actions {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.ps-shopping .ps-shopping__actions .ps-shopping__view {
  padding-left: 40px;
}

.ps-shopping .ps-shopping__header {
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  background-color: #f5f5f5;
  align-items: center;
  padding: 8px 15px;
}

.ps-shopping .ps-shopping__header p {
  margin-bottom: 0;
  display: inline-block;
}

.ps-shopping .ps-shopping__header p strong {
  color: #000;
}

.ps-shopping .ps-shop__filter-mb {
  margin-bottom: 30px;
  font-size: 16px;
  vertical-align: top;
  display: flex;
  color: #000;
  align-items: center;
  font-weight: 500;
  text-transform: uppercase;
}

.ps-shopping .ps-shop__filter-mb i {
  font-weight: 700;
  font-size: 18px;
  margin-right: 10px;
}

@media (min-width: 1200px) {
  .ps-shopping .ps-shop__filter-mb {
    display: none;
  }
}

@media (max-width: 1680px) and (min-width: 1440px) {
  .ps-shopping .row .col-xl-2 {
    max-width: 20%;
    flex-basis: 20%;
  }
}

@media (max-width: 1440px) and (min-width: 1200px) {
  .ps-shopping .row .col-xl-2 {
    max-width: 25%;
    flex-basis: 25%;
  }
}

@media (max-width: 1199px) {
  .ps-shopping {
    margin-top: 40px;
  }
}

@media (max-width: 767px) {
  .ps-shopping .ps-shopping__header {
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  .ps-shopping .ps-shopping__header > * {
    flex-basis: 100%;
    max-width: 50%;
  }
  .ps-shopping .ps-shopping__header .ps-shopping__view {
    padding-left: 10px;
  }
  .ps-shopping .ps-shopping__actions {
    width: 100%;
    justify-content: flex-end;
  }
  .ps-shopping .ps-shopping__actions .select2 {
    display: none;
  }
}

@media (min-width: 1680px) {
  .ps-customer-bought .ps-section__content .row .col-xl-3 {
    padding: 0;
    flex-basis: calc(100% / 7);
    max-width: calc(100% / 7);
  }
}

.ps-client-say {
  padding: 70px 0;
  background-color: #f5f5f5;
}

.ps-client-say .ps-section__header {
  margin-bottom: 30px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.ps-client-say .ps-section__header h3 {
  display: inline-block;
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
}

.ps-client-say .ps-section__nav a {
  display: inline-block;
  margin-right: 20px;
  font-size: 18px;
  color: #999999;
}

.ps-client-say .ps-section__nav a:last-child {
  margin-right: 0;
}

.ps-client-say .ps-section__nav a:hover {
  color: #000;
}

.ps-best-sale-brands {
  padding: 70px 0;
}

.ps-best-sale-brands .ps-section__header h3 {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 20px;
  line-height: 1;
  color: #000;
}

.ps-best-sale-brands .ps-image-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-self: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.ps-best-sale-brands .ps-image-list li {
  display: flex;
  min-width: 20%;
  max-width: 20%;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: none;
  border-right: none;
}

@media (max-width: 991px) {
  .ps-best-sale-brands .ps-image-list {
    border: 1px solid rgba(0, 0, 0, 0.15);
  }
  .ps-best-sale-brands .ps-image-list li {
    min-width: 25%;
    max-width: 25%;
    border: none;
  }
}

@media (max-width: 767px) {
  .ps-best-sale-brands .ps-image-list li {
    min-width: 33.3333%;
    max-width: 33.3333%;
  }
}

@media (max-width: 479px) {
  .ps-best-sale-brands .ps-image-list li {
    min-width: 50%;
    max-width: 50%;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }
}

.ps-deal-hot {
  padding: 70px 0;
}

.ps-deal-hot .widget_best-sale {
  border-bottom: 1px solid #d9d9d9;
}

.ps-home-categories .ps-section__header {
  margin-bottom: 40px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e1e1e1;
}

.ps-home-categories .ps-section__header h3 {
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 400;
  line-height: 1em;
}

.ps-search-trending {
  padding: 70px 0;
}

.ps-search-trending .ps-section__header {
  margin-bottom: 20px;
}

.ps-search-trending .ps-section__header h3 {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 400;
}

.ps-search-trending .ps-section__header h3 span {
  font-size: 14px;
  margin-left: 10px;
  color: #666;
}

@media (max-width: 479px) {
  .ps-search-trending .ps-section__header h3 {
    font-size: 18px;
  }
  .ps-search-trending .ps-section__header h3 span {
    font-size: 12px;
  }
}

.ps-categories-box {
  background-color: #f5f5f5;
  padding: 70px 0;
}

@media (max-width: 767px) {
  .ps-categories-box {
    padding: 45px 0;
  }
}

@media (max-width: 479px) {
  .ps-categories-box {
    padding: 35px 0;
  }
}

.ps-home-blog .ps-section__header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding-bottom: 10px;
}

.ps-home-blog .ps-section__header h3 {
  font-size: 24px;
}

@media (max-width: 767px) {
  .ps-home-blog .ps-section__header {
    flex-flow: row wrap;
  }
  .ps-home-blog .ps-section__header h3 {
    display: block;
    margin-bottom: 10px;
  }
}

.ps-blog {
  padding-bottom: 100px;
}

.ps-blog .ps-blog__header {
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  .ps-blog {
    padding-bottom: 50px;
  }
}

.ps-blog--sidebar {
  padding-bottom: 90px;
  display: flex;
  flex-flow: row nowrap;
}

.ps-blog--sidebar > * {
  width: 100%;
}

.ps-blog--sidebar .ps-blog__left {
  padding-right: 70px;
}

.ps-blog--sidebar .ps-blog__left .ps-pagination {
  text-align: left;
}

.ps-blog--sidebar .ps-blog__right {
  max-width: 300px;
  min-width: 300px;
  padding-left: 30px;
}

.ps-blog--sidebar.reverse {
  flex-flow: row-reverse nowrap;
}

.ps-blog--sidebar.reverse .ps-blog__left {
  padding-left: 100px;
  padding-right: 0;
}

.ps-blog--sidebar.reverse .ps-blog__right {
  padding-left: 0;
}

@media (max-width: 1199px) {
  .ps-blog--sidebar .ps-blog__left {
    padding-right: 0;
  }
  .ps-blog--sidebar.reverse .ps-blog__left {
    padding-left: 30px;
    padding-right: 0;
  }
}

@media (max-width: 991px) {
  .ps-blog--sidebar {
    flex-flow: column wrap;
  }
  .ps-blog--sidebar .ps-blog__left {
    margin-bottom: 50px;
  }
  .ps-blog--sidebar .ps-blog__right {
    padding-left: 0;
  }
  .ps-blog--sidebar.reverse {
    flex-flow: column wrap;
  }
  .ps-blog--sidebar.reverse .ps-blog__left {
    padding-left: 0;
  }
}

@media (max-width: 767px) {
  .ps-blog--sidebar {
    padding-bottom: 45px;
  }
}

@media (max-width: 479px) {
  .ps-blog--sidebar {
    padding-bottom: 30px;
  }
}

.ps-related-posts {
  padding-top: 60px;
}

.ps-related-posts h3 {
  margin-bottom: 60px;
  text-align: center;
  font-size: 24px;
  color: #000;
}

.ps-post-comments {
  max-width: 770px;
  margin: 0 auto;
}

.ps-post-comments h3 {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: #000;
  margin-bottom: 40px;
  line-height: 1.4em;
}

.ps-about-intro {
  padding: 100px 0;
}

.ps-about-intro .ps-section__header {
  max-width: 820px;
  margin: 0 auto 100px;
}

.ps-about-intro .ps-section__header h4 {
  margin-bottom: 80px;
  font-size: 24px;
  color: #000;
  font-weight: 600;
}

.ps-about-intro .ps-section__header h3 {
  margin-bottom: 40px;
  font-weight: 400;
  line-height: 1.3em;
  color: #000;
  font-size: 36px;
}

.ps-about-intro .ps-section__header p {
  font-size: 18px;
  line-height: 1.6em;
}

.ps-about-intro .ps-section__content {
  border-top: 1px solid #e1e1e1;
  padding-top: 90px;
}

@media (max-width: 1199px) {
  .ps-about-intro {
    padding: 80px 0;
  }
}

@media (max-width: 991px) {
  .ps-about-intro {
    padding: 70px 0;
  }
}

@media (max-width: 767px) {
  .ps-about-intro {
    padding: 50px 0;
  }
  .ps-about-intro .ps-section__header h4 {
    margin-bottom: 50px;
  }
  .ps-about-intro .ps-section__header h3 {
    font-size: 24px;
  }
  .ps-about-intro .ps-section__header p {
    font-size: 14px;
  }
}

.ps-our-team {
  padding: 120px 0;
}

.ps-our-team .ps-section__header {
  padding-left: 180px;
  padding-bottom: 90px;
}

.ps-our-team .ps-section__header h3 {
  margin-bottom: 0;
  font-size: 36px;
  font-weight: 400;
  color: #000;
}

.ps-our-team .ps-section__content {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -15px;
}

.ps-our-team .ps-section__content figure {
  margin-bottom: 30px;
  width: 100%;
  max-width: 25%;
  padding: 0 15px;
}

@media (max-width: 1199px) {
  .ps-our-team .ps-section__header {
    padding-left: 0;
    padding-bottom: 50px;
  }
}

@media (max-width: 991px) {
  .ps-our-team {
    padding: 90px 0;
  }
  .ps-our-team .ps-section__header {
    padding-bottom: 40px;
  }
  .ps-our-team .ps-section__header h3 {
    font-size: 28px;
  }
  .ps-our-team .ps-section__content figure {
    max-width: 33.3333%;
  }
}

@media (max-width: 767px) {
  .ps-our-team {
    padding: 60px 0;
  }
  .ps-our-team .ps-section__header {
    padding-bottom: 30px;
  }
  .ps-our-team .ps-section__header h3 {
    font-size: 24px;
  }
  .ps-our-team .ps-section__content figure {
    max-width: 50%;
  }
}

.ps-about-milestone {
  padding: 120px 0;
}

.ps-about-awards {
  padding: 120px 0;
  background-color: #f5f5f5;
}

.ps-about-awards .ps-section__header {
  max-width: 820px;
  margin: 0 auto 100px;
}

.ps-about-awards .ps-section__header h4 {
  margin-bottom: 65px;
  font-size: 36px;
  color: #000;
  font-weight: 400;
}

.ps-about-awards .ps-section__header p {
  font-size: 18px;
  line-height: 1.6em;
}

.ps-about-awards .ps-section__content .owl-slider a {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1199px) {
  .ps-about-awards {
    padding: 100px 0;
  }
}

@media (max-width: 991px) {
  .ps-about-awards {
    padding: 80px 0;
  }
}

@media (max-width: 767px) {
  .ps-about-awards {
    padding: 70px 0;
  }
  .ps-about-awards .ps-section__header h4 {
    margin-bottom: 50px;
    font-size: 24px;
  }
  .ps-about-awards .ps-section__header p {
    font-size: 14px;
  }
}

@media (max-width: 479px) {
  .ps-about-awards {
    padding: 50px 0;
  }
}

.ps-contact-info {
  padding: 90px 0;
}

.ps-contact-info h3 {
  margin-bottom: 60px;
  text-align: center;
  font-size: 36px;
  font-weight: 600;
}

@media (max-width: 991px) {
  .ps-contact-info {
    padding: 60px 0;
  }
  .ps-contact-info h3 {
    font-size: 24px;
  }
}

@media (max-width: 479px) {
  .ps-contact-info {
    padding: 45px 0;
  }
  .ps-contact-info h3 {
    font-size: 20px;
  }
}

.ps-contact-form {
  padding: 100px 0;
  background-color: #f6f6f6;
}

.ps-contact-form h3 {
  margin-bottom: 90px;
  text-align: center;
  font-size: 36px;
  font-weight: 600;
  color: #000;
  line-height: 1em;
}

@media (max-width: 991px) {
  .ps-contact-form h3 {
    font-size: 24px;
  }
}

@media (max-width: 479px) {
  .ps-contact-form h3 {
    font-size: 20px;
  }
}

.ps-faqs {
  padding: 90px 0;
}

.ps-faqs .ps-section__header {
  padding-bottom: 100px;
}

.ps-faqs .ps-section__header h1 {
  text-align: center;
  font-size: 36px;
  font-weight: 600;
}

@media (max-width: 767px) {
  .ps-faqs .ps-section__header {
    padding-bottom: 50px;
  }
  .ps-faqs .ps-section__header h1 {
    font-size: 24px;
  }
}

.ps-call-to-action {
  background-color: #f1f1f1;
  padding: 130px 0;
  text-align: center;
}

.ps-call-to-action h3 {
  font-size: 36px;
  color: #000;
  font-weight: 600;
}

.ps-call-to-action h3 a {
  color: #F7E5CD;
  font-weight: inherit;
}

.ps-call-to-action h3 a:hover {
  color: #000;
}

@media (max-width: 991px) {
  .ps-call-to-action {
    padding: 100px 0;
  }
}

@media (max-width: 767px) {
  .ps-call-to-action {
    padding: 80px 0;
  }
}

@media (max-width: 479px) {
  .ps-call-to-action {
    padding: 65px 0;
  }
}

.ps-vendor-banner {
  position: relative;
  text-align: center;
  z-index: 10;
  padding: 160px 0;
}

.ps-vendor-banner:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.25);
}

.ps-vendor-banner h2 {
  color: #ffffff;
  font-size: 48px;
  margin: 0 auto 30px;
  max-width: 960px;
  font-weight: 600;
}

.ps-vendor-banner .ps-btn {
  padding: 20px 40px;
  font-size: 24px;
  border-radius: 0;
}

@media (max-width: 1199px) {
  .ps-vendor-banner {
    padding: 120px 0;
  }
  .ps-vendor-banner h2 {
    font-size: 36px;
  }
}

@media (max-width: 991px) {
  .ps-vendor-banner {
    padding: 100px 0;
  }
  .ps-vendor-banner h2 {
    font-size: 28px;
  }
  .ps-vendor-banner .ps-btn {
    font-size: 20px;
    padding: 15px 30px;
  }
}

@media (max-width: 767px) {
  .ps-vendor-banner {
    padding: 80px 0;
  }
  .ps-vendor-banner .ps-btn {
    font-size: 16px;
  }
}

@media (max-width: 479px) {
  .ps-vendor-banner {
    padding: 60px 0;
  }
  .ps-vendor-banner h2 {
    font-size: 24px;
  }
}

@media (max-width: 991px) {
  .ps-vendor-about .ps-block--icon-box-2 {
    max-width: 450px;
    margin: 0 auto 40px;
  }
  .ps-vendor-about .ps-section__header h4 br {
    display: none;
  }
}

.ps-vendor-milestone {
  background-color: #f6f6f6;
}

.ps-vendor-best-fees .ps-section__numbers {
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
}

.ps-vendor-best-fees .ps-section__numbers figure {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  width: 170px;
  height: 170px;
  border: 2px solid #F7E5CD;
  margin: 0 40px;
  border-radius: 50%;
}

.ps-vendor-best-fees .ps-section__numbers figure h3 {
  display: block;
  margin-bottom: 0;
  width: 100%;
  font-size: 60px;
  font-weight: 400;
  line-height: 1em;
  color: #000;
}

.ps-vendor-best-fees .ps-section__numbers figure span {
  font-size: 16px;
}

.ps-vendor-best-fees .ps-section__desc {
  max-width: 550px;
  margin: 0 auto 30px;
}

.ps-vendor-best-fees .ps-section__desc > figure figcaption {
  text-align: center;
  font-weight: 600;
  color: #000;
  margin-bottom: 40px;
  font-size: 16px;
}

.ps-vendor-best-fees .ps-section__desc ul li {
  text-align: left;
  color: #666;
  font-size: 16px;
  margin-bottom: 16px;
  line-height: 1.6em;
}

.ps-vendor-best-fees .ps-section__highlight {
  margin-bottom: 40px;
  padding: 30px 20px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background-color: #f6f6f6;
}

.ps-vendor-best-fees .ps-section__highlight figure {
  text-align: justify;
  padding-left: 60px;
  padding-right: 60px;
}

.ps-vendor-best-fees .ps-section__highlight figure p {
  font-weight: 500;
  color: #000;
  font-size: 16px;
}

.ps-vendor-best-fees .ps-section__content {
  max-width: 730px;
  margin: 0 auto;
  text-align: center;
}

.ps-vendor-best-fees .ps-section__content > h5 {
  margin-bottom: 50px;
  font-size: 18px;
  font-weight: 400;
  color: #666;
  line-height: 1.4em;
}

@media (max-width: 767px) {
  .ps-vendor-best-fees .ps-section__content > h5 {
    font-size: 16px;
  }
  .ps-vendor-best-fees .ps-section__desc ul li {
    font-size: 14px;
  }
  .ps-vendor-best-fees .ps-section__highlight {
    padding: 30px 10px;
  }
  .ps-vendor-best-fees .ps-section__highlight figure {
    text-align: left;
    padding: 0 20px;
  }
  .ps-vendor-best-fees .ps-section__highlight figure p {
    font-size: 14px;
  }
}

@media (max-width: 479px) {
  .ps-vendor-best-fees .ps-section__numbers figure {
    width: 100px;
    height: 100px;
    margin: 0 10px;
  }
  .ps-vendor-best-fees .ps-section__numbers figure h3 {
    font-size: 30px;
  }
  .ps-vendor-best-fees .ps-section__highlight {
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
  }
  .ps-vendor-best-fees .ps-section__highlight img {
    margin-bottom: 30px;
    max-height: 100px;
  }
}

.ps-vendor-testimonials {
  background-color: #f6f6f6;
}

.ps-vendor-faqs figure {
  margin-bottom: 50px;
}

.ps-vendor-faqs figure figcaption {
  font-size: 18px;
  color: #000;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 1.4em;
}

.ps-vendor-faqs figure p {
  font-size: 16px;
}

.ps-vendor-faqs .ps-section__footer {
  padding-top: 100px;
  text-align: center;
  border-top: 1px solid #e1e1e1;
}

.ps-vendor-faqs .ps-section__footer p {
  margin-bottom: 40px;
  font-size: 30px;
  font-weight: 400;
  color: #000;
}

.ps-vendor-faqs .ps-section__footer .ps-btn {
  padding: 20px 40px;
  font-size: 24px;
}

@media (max-width: 991px) {
  .ps-vendor-faqs .ps-section__footer {
    padding-top: 60px;
  }
  .ps-vendor-faqs .ps-section__footer p {
    font-size: 20px;
  }
  .ps-vendor-faqs .ps-section__footer .ps-btn {
    font-size: 16px;
    padding: 15px 30px;
  }
}

@media (max-width: 479px) {
  .ps-vendor-faqs .ps-section__footer {
    padding-top: 50px;
  }
  .ps-vendor-faqs .ps-section__footer p {
    font-size: 18px;
  }
}

.ps-my-account {
  min-height: 100vh;
  background-color: #f1f1f1;
}

.ps-order-tracking {
  padding: 100px 0;
}

.ps-order-tracking .ps-section__header {
  text-align: center;
  padding-bottom: 80px;
}

.ps-order-tracking .ps-section__header h3 {
  margin-bottom: 40px;
  font-size: 48px;
  font-weight: 600;
  line-height: 1em;
}

.ps-order-tracking .ps-section__header p {
  max-width: 960px;
  margin: 0 auto;
  font-size: 16px;
}

@media (max-width: 991px) {
  .ps-order-tracking {
    padding: 75px 0;
  }
  .ps-order-tracking .ps-section__header {
    padding-bottom: 60px;
  }
  .ps-order-tracking .ps-section__header h3 {
    margin-bottom: 20px;
    font-size: 36px;
  }
  .ps-order-tracking .ps-section__header p {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .ps-order-tracking {
    padding: 60px 0;
  }
}

@media (max-width: 479px) {
  .ps-order-tracking {
    padding: 50px 0;
  }
  .ps-order-tracking .ps-section__header {
    padding-bottom: 40px;
  }
}

.ps-shopping-cart .ps-section__cart-actions {
  padding-top: 30px;
  padding-bottom: 90px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.ps-shopping-cart .ps-section__cart-actions .ps-btn i {
  margin-right: 6px;
  vertical-align: middle;
}

.ps-shopping-cart .ps-section__cart-actions .ps-btn--outline {
  border-color: #000;
  color: #000;
  padding: 12px 20px;
  vertical-align: middle;
}

.ps-shopping-cart .ps-section__cart-actions .ps-btn--outline i {
  margin-right: 6px;
  vertical-align: middle;
}

.ps-shopping-cart .ps-section__cart-actions .ps-btn--outline:hover {
  background-color: #F7E5CD;
  border-color: #F7E5CD;
}

.ps-shopping-cart .ps-section__footer figure figcaption {
  position: relative;
  display: block;
  font-size: 20px;
  color: #000;
  margin-bottom: 35px;
}

.ps-shopping-cart .ps-section__footer figure figcaption:after {
  content: '\e93a';
  font-family: 'Linearicons';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
}

.ps-shopping-cart .ps-section__footer figure .form-control {
  border-color: #ccc;
}

.ps-shopping-cart .ps-section__footer .ps-btn--outline {
  border-color: #cccccc;
  color: #000;
  border-radius: 0;
}

.ps-shopping-cart .ps-section__footer .ps-btn--outline:hover {
  background-color: #F7E5CD;
  border-color: #F7E5CD;
  color: #ffffff;
}

.ps-shopping-cart .ps-section__footer .select2 {
  min-width: 100%;
}

.ps-shopping-cart .ps-section__footer .select2 .select2-selection--single .select2-selection__rendered {
  padding: 15px 20px;
  border: #cccccc;
}

@media (max-width: 479px) {
  .ps-shopping-cart .ps-section__cart-actions {
    flex-flow: row wrap;
  }
  .ps-shopping-cart .ps-section__cart-actions .ps-btn {
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
  }
}

.ps-vendor-store {
  padding: 40px 0;
}

.ps-vendor-store .ps-section__container {
  display: flex;
  flex-flow: row nowrap;
}

.ps-vendor-store .ps-section__container > * {
  width: 100%;
}

.ps-vendor-store .ps-section__container .ps-section__left {
  max-width: 25%;
}

.ps-vendor-store .ps-section__container .ps-section__right {
  max-width: 75%;
  padding-left: 30px;
}

.ps-vendor-store .ps-block--vendor-filter {
  margin-bottom: 45px;
}

@media (max-width: 1199px) {
  .ps-vendor-store .ps-section__container {
    flex-flow: column wrap;
  }
  .ps-vendor-store .ps-section__container .ps-section__left {
    max-width: 450px;
    margin-bottom: 30px;
  }
  .ps-vendor-store .ps-section__container .ps-section__right {
    max-width: 100%;
    padding-left: 0;
  }
}

.ps-vendor-best-seller .ps-section__header {
  margin-bottom: 30px;
  padding-bottom: 16px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  border-bottom: 1px solid #e1e1e1;
}

.ps-vendor-best-seller .ps-section__header h3 {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 20px;
}

.ps-vendor-dashboard .ps-section__header {
  padding: 100px 0;
  text-align: center;
}

.ps-vendor-dashboard .ps-section__header h3 {
  font-size: 48px;
  font-weight: 600;
  margin-bottom: 30px;
}

.ps-vendor-dashboard .ps-section__header p {
  color: #666;
  font-size: 16px;
  max-width: 860px;
  margin: 0 auto 30px;
}

.ps-vendor-dashboard .ps-section__links {
  background-color: #f5f5f5;
  text-align: center;
  padding: 20px 0;
  margin-bottom: 45px;
}

.ps-vendor-dashboard .ps-section__links li {
  display: inline-block;
  margin-right: 30px;
}

.ps-vendor-dashboard .ps-section__links li a {
  font-size: 18px;
  font-weight: 600;
  color: #666;
  line-height: 20px;
}

.ps-vendor-dashboard .ps-section__links li a:hover {
  color: #000;
}

.ps-vendor-dashboard .ps-section__links li:last-child {
  margin-right: 0;
}

.ps-vendor-dashboard .ps-section__links li.active a {
  color: #000;
}

@media (max-width: 767px) {
  .ps-vendor-dashboard .ps-section__header {
    padding: 60px 0;
  }
  .ps-vendor-dashboard .ps-section__header h3 {
    font-size: 36px;
  }
  .ps-vendor-dashboard .ps-section__header p {
    font-size: 14px;
  }
  .ps-vendor-dashboard .ps-section__links li {
    margin-bottom: 4px;
  }
  .ps-vendor-dashboard .ps-section__links li a {
    font-size: 16px;
  }
}

@media (max-width: 479px) {
  .ps-vendor-dashboard .ps-section__header {
    padding: 50px 0;
  }
  .ps-vendor-dashboard .ps-section__header h3 {
    margin-bottom: 20px;
    font-size: 28px;
  }
}

.ps-media-masonry {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(4, minmax(200px, 1fr));
  /* [2] Make columns adjust according to the available viewport */
  grid-auto-rows: 100%;
}

.ps-media-masonry figure {
  width: 100%;
}

.ps-my-account-2 {
  padding: 60px 0;
}

.ps-my-account-2 .ps-section__desc {
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 50px;
  padding-bottom: 30px;
}

.ps-my-account-2 .ps-section__desc figcaption {
  font-size: 24px;
  font-weight: 600;
  color: #000;
  margin-bottom: 20px;
}

.ps-my-account-2 .ps-section__desc p {
  margin-bottom: 35px;
}

.ps-my-account-2 .ps-section__desc .ps-list li {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.ps-my-account-2 .ps-section__desc .ps-list li span {
  font-size: 18px;
}

.ps-my-account-2 .ps-section__desc .ps-list li i {
  margin-right: 20px;
  font-size: 36px;
  color: #F7E5CD;
}

.ps-my-account-2 .ps-section__coupon {
  border: 1px dashed #b6b6b6;
  background-color: #f6f6f6;
  padding: 30px;
  display: flex;
  align-items: center;
}

.ps-my-account-2 .ps-section__coupon span {
  font-size: 72px;
  color: #690;
  margin: 0 30px 0 0;
}

.ps-my-account-2 .ps-section__coupon h5 {
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 600;
}

.ps-my-account-2 .ps-form--account {
  padding-top: 0;
  margin-bottom: 30px;
  border: 1px solid #e8e8e8;
}

.ps-my-account-2 .ps-form--account .ps-tab-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
}

.ps-my-account-2 .ps-form--account .ps-tab-list li {
  width: 100%;
  max-width: 50%;
  margin-right: 0;
  padding: 20px 15px;
}

.ps-my-account-2 .ps-form--account .ps-tab-list li a {
  font-size: 24px;
  line-height: 1em;
}

.ps-my-account-2 .ps-form--account .ps-tab-list li.active {
  border-bottom: 2px solid #F7E5CD;
}

.ps-my-account-2 .ps-form--account .ps-tab-list li.active a {
  color: #F7E5CD;
}

.ps-my-account-2 #register p {
  margin-bottom: 50px;
}

.ps-my-account-2 #register p a {
  color: #06c;
}

.ps-my-account-2 #register .ps-radio {
  margin-bottom: 10px;
}

.ps-my-account-2 #register .ps-radio label:before {
  width: 18px;
  height: 18px;
}

.ps-my-account-2 #register .ps-radio input[type='radio']:checked ~ label:before {
  background-color: transparent;
  border-color: #000;
}

.ps-my-account-2 #register .ps-radio input[type='radio']:checked ~ label:after {
  top: 4px;
  left: 4px;
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1);
  opacity: 1;
  background-color: #000;
}

@media (min-width: 1200px) {
  .ps-my-account-2 {
    padding-top: 100px;
    padding-bottom: 90px;
  }
  .ps-my-account-2 .ps-section__left {
    width: 100%;
    max-width: 430px;
  }
  .ps-my-account-2 .ps-section__right {
    padding-left: 130px;
  }
  .ps-my-account-2 .ps-section__wrapper {
    display: flex;
    align-items: center;
  }
}

.ps-store-list {
  padding-top: 60px;
}

.ps-store-list .ps-block--store-2 {
  margin-bottom: 30px;
}

.ps-store-list .ps-block--store-banner {
  margin-bottom: 30px;
}

.ps-store-list > .container > .ps-section__header {
  text-align: center;
  padding-bottom: 60px;
}

.ps-store-list > .container > .ps-section__header h3 {
  font-size: 36px;
  font-weight: 600;
}

.ps-store-list .ps-section__search .form-group {
  position: relative;
}

.ps-store-list .ps-section__search .form-group button {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 10px;
  background-color: transparent;
  border: none;
}

.ps-store-list .ps-section__search .form-control {
  text-indent: 20px;
  height: 40px;
}

.ps-store-list .ps-section__wrapper {
  display: flex;
  flex-flow: column-reverse wrap;
  padding-bottom: 60px;
}

.ps-store-list .ps-section__wrapper > * {
  flex-basis: 100%;
}

.ps-store-list .ps-section__left {
  padding: 25px 20px;
  background-color: #efefef;
}

.ps-store-list .ps-section__right {
  margin-bottom: 50px;
}

.ps-store-list .ps-store-link {
  margin-bottom: 50px;
  padding: 14px 15px;
  background-color: #f5f5f5;
}

.ps-store-list .ps-store-link ul li {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 20px;
}

.ps-store-list .ps-store-link ul li a {
  font-weight: 600;
  font-size: 16px;
  color: #999;
}

.ps-store-list .ps-store-link ul li.active a {
  color: #000;
}

@media (max-width: 560px) {
  .ps-store-list .ps-store-link ul {
    display: flex;
    flex-flow: row wrap;
  }
  .ps-store-list .ps-store-link ul li {
    margin-right: 0;
    flex-basis: 50%;
    margin-bottom: 10px;
  }
}

@media (min-width: 1200px) {
  .ps-store-list > .container > .ps-section__header {
    padding: 30px 0 115px;
  }
  .ps-store-list > .container > .ps-section__header h3 {
    font-size: 48px;
  }
  .ps-store-list .ps-section__left {
    max-width: 300px;
  }
  .ps-store-list .ps-section__right {
    margin-bottom: 0;
    padding-left: 40px;
  }
  .ps-store-list .ps-section__wrapper {
    flex-flow: row nowrap;
    padding-bottom: 90px;
  }
}

.ps-store-box .ps-section__header {
  display: flex;
  flex-flow: column wrap;
  padding-bottom: 32px;
}

@media (min-width: 768px) {
  .ps-store-box .ps-section__header {
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }
  .ps-store-box .ps-section__header p {
    margin-bottom: 0;
  }
}

.ps-section--account {
  padding: 60px 0;
}

.ps-section--account .ps-section__header {
  padding-bottom: 30px;
}

.ps-section--account .ps-section__header h3 {
  font-weight: 600;
}

.ps-section--account-setting .ps-table {
  border: 1px solid #eaeaea;
}

.ps-section--account-setting .ps-table thead tr th {
  text-transform: none;
  font-weight: 500;
  font-size: 16px;
  border: 1px solid #eaeaea;
  border-bottom: none;
  padding: 10px 15px;
}

.ps-section--account-setting .ps-table tr td {
  padding: 10px 15px;
}

.ps-shop-by-brand {
  padding: 50px 0;
}

.ps-shop-by-brand .ps-section__header {
  text-align: center;
  padding-bottom: 50px;
}

.ps-shop-by-brand .ps-section__header h3 {
  font-weight: 600;
  font-size: 30px;
  text-transform: uppercase;
}

.ps-shop-by-brand .ps-section__links {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 50px;
}

.ps-shop-by-brand .ps-section__links a {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: #ebebeb;
  width: 50px;
  height: 50px;
  font-weight: 600;
  text-transform: uppercase;
}

.ps-shop-by-brand .ps-section__links a.active, .ps-shop-by-brand .ps-section__links a:hover {
  background-color: #F7E5CD;
  color: #ffffff;
}

.ps-shop-by-brand .ps-section__items {
  display: flex;
  flex-flow: row wrap;
}

.ps-shop-by-brand .ps-section__items > * {
  flex-basis: 100%;
  max-width: 50%;
}

.ps-shop-by-brand .ps-section__items a {
  display: inline-block;
  margin-bottom: 10px;
}

.ps-shop-by-brand .ps-section__content figure {
  margin-bottom: 50px;
}

.ps-shop-by-brand .ps-section__content figure figcaption {
  display: block;
  padding-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  border-bottom: 1px solid #eaeaea;
}

@media screen and (min-width: 992px) {
  .ps-shop-by-brand .ps-section__items > * {
    max-width: 33.3333%;
  }
}

@media screen and (min-width: 1200px) {
  .ps-shop-by-brand {
    padding: 100px 0;
  }
  .ps-shop-by-brand .ps-section__items > * {
    max-width: 33.3333%;
  }
}

#homepage-1 .ps-home-banner {
  padding-top: 30px;
  margin-bottom: 30px;
}

#homepage-1 .ps-home-banner .ps-banner {
  min-height: 150px;
}

#homepage-1 .ps-home-banner .ps-collection {
  display: block;
  margin-bottom: 30px;
}

#homepage-1 .ps-home-banner .ps-collection:last-child {
  margin-bottom: 0;
}

#homepage-1 .ps-home-banner .owl-slider {
  max-width: 100%;
  height: 100%;
}

#homepage-1 .ps-home-banner .owl-slider .owl-stage-outer {
  height: 100%;
}

#homepage-1 .ps-home-banner .owl-slider .owl-stage-outer .owl-stage {
  height: 100%;
}

#homepage-1 .ps-home-banner .owl-slider .owl-stage-outer .owl-stage .owl-item {
  height: 100%;
}

#homepage-1 .ps-home-banner .owl-slider .ps-banner {
  height: 100%;
}

#homepage-1 .ps-home-banner .ps-section__left {
  padding-right: 30px;
  max-width: calc(100% - 390px);
}

#homepage-1 .ps-home-banner .ps-section__right {
  max-width: 390px;
}

#homepage-1 .ps-home-banner .ps-container {
  display: flex;
  flex-flow: row nowrap;
}

#homepage-1 .ps-home-banner .ps-container > * {
  width: 100%;
}

@media (max-width: 1199px) {
  #homepage-1 .ps-home-banner {
    display: block;
  }
  #homepage-1 .ps-home-banner .ps-collection {
    width: 100%;
    max-width: 50%;
    padding: 0 15px;
  }
  #homepage-1 .ps-home-banner .ps-collection img {
    width: 100%;
  }
  #homepage-1 .ps-home-banner .ps-section__left {
    max-width: 100%;
    padding-right: 0;
    margin-bottom: 20px;
  }
  #homepage-1 .ps-home-banner .ps-section__right {
    display: flex;
    flex-flow: row nowrap;
    margin: 0;
    max-width: 100%;
  }
  #homepage-1 .ps-home-banner .ps-container {
    flex-flow: column nowrap;
  }
}

@media (max-width: 479px) {
  #homepage-1 .ps-home-banner .ps-collection {
    padding: 0 5px;
    margin-bottom: 0;
  }
  #homepage-1 .ps-home-banner .ps-section__right {
    margin: 0 -5px;
  }
}

#homepage-1 .ps-site-features {
  padding-bottom: 90px;
}

#homepage-1 .ps-deal-of-day {
  padding-bottom: 80px;
}

#homepage-1 .ps-deal-of-day .ps-product .ps-product__price {
  color: #690;
  font-size: 18px;
  font-weight: 600;
}

#homepage-1 .ps-deal-of-day .ps-product .ps-product__price del {
  margin-left: 10px;
  font-size: 14px;
}

#homepage-1 .ps-deal-of-day .ps-product .ps-product__price small {
  margin-left: 10px;
  color: red;
}

#homepage-1 .ps-top-categories {
  padding: 80px 0 50px;
}

#homepage-1 .ps-top-categories h3 {
  text-transform: capitalize;
}

@media (min-width: 1440px) {
  #homepage-1 .ps-top-categories .row .col-xl-2 {
    max-width: calc(100% / 8);
  }
}

@media (max-width: 1199px) {
  #homepage-1 .ps-top-categories {
    padding: 60px 0;
  }
}

@media (max-width: 991px) {
  #homepage-1 .ps-top-categories {
    padding: 50px 0;
  }
}

@media (max-width: 767px) {
  #homepage-1 .ps-top-categories {
    padding: 40px 0;
  }
}

@media (max-width: 479px) {
  #homepage-1 .ps-top-categories {
    padding: 35px 0;
  }
}

#homepage-1 .ps-product-list {
  padding-bottom: 80px;
}

@media (max-width: 991px) {
  #homepage-1 .ps-product-list {
    padding-bottom: 50px;
  }
}

@media (max-width: 767px) {
  #homepage-1 .ps-product-list {
    padding-bottom: 40px;
  }
}

@media (max-width: 479px) {
  #homepage-1 .ps-product-list {
    padding-bottom: 0px;
  }
}

#homepage-1 .ps-download-app {
  margin-top: 80px;
  margin-bottom: 100px;
}

@media (max-width: 767px) {
  #homepage-1 .ps-download-app {
    margin-bottom: 50px;
  }
}

#shop-carousel .ps-carouse--nav-inside {
  margin-bottom: 60px;
}

@media (max-width: 767px) {
  #shop-carousel .ps-carouse--nav-inside {
    margin-bottom: 40px;
  }
}

#shop-carousel .ps-block--container-hightlight {
  margin-bottom: 60px;
}

#shop-carousel .ps-product-list {
  margin-bottom: 90px;
}

#shop-carousel .ps-product-list .ps-section__header {
  display: block;
  text-align: center;
}

@media (max-width: 767px) {
  #shop-carousel .ps-product-list {
    margin-bottom: 50px;
  }
}

#shop-categories {
  background-color: #f5f5f5;
  padding-bottom: 90px;
}

#shop-categories .ps-catalog-top {
  padding: 70px 0;
}

#shop-categories .ps-product-list {
  margin-bottom: 20px;
  padding: 20px 20px 50px;
  background-color: #ffffff;
}

#shop-categories .ps-product-list .ps-section__header h3 {
  font-weight: 400;
}

#shop-categories .ps-more-categories .ps-section__content {
  display: flex;
  flex-flow: row wrap;
}

#shop-categories .ps-more-categories .ps-section__content .ps-block--category-vertical {
  margin-bottom: 30px;
  max-width: 20%;
  padding: 30px 10px;
}

@media (max-width: 1199px) {
  #shop-categories .ps-more-categories .ps-section__content .ps-block--category-vertical {
    max-width: 25%;
  }
}

@media (max-width: 991px) {
  #shop-categories .ps-more-categories .ps-section__content .ps-block--category-vertical {
    max-width: calc(100% / 3);
  }
}

@media (max-width: 767px) {
  #shop-categories .ps-more-categories .ps-section__content .ps-block--category-vertical {
    max-width: 50%;
  }
}

@media (max-width: 479px) {
  #shop-categories .ps-more-categories .ps-section__content .ps-block--category-vertical {
    max-width: 100%;
  }
}

#shop-sidebar .ps-page__header {
  margin-bottom: 50px;
}

#shop-sidebar .ps-page__header h1 {
  margin-bottom: 20px;
  font-weight: 700;
  color: #000;
  font-size: 24px;
}

#shop-sidebar .ps-layout--shop {
  padding-top: 30px;
}

#contact-us .ps-breadcrumb {
  margin-bottom: 0;
}

#contact-map {
  height: 100vh;
}

#shop-carousel .ps-carousel--nav-inside {
  padding: 30px 0 50px;
}

#shop-carousel .ps-carousel--nav-inside .owl-item img {
  width: 100%;
}

@media (max-width: 767px) {
  #shop-carousel .ps-carousel--nav-inside {
    padding: 30px 0;
  }
}

.ps-page--404 {
  position: relative;
  min-height: 100vh;
  background-color: #efeef0;
  text-align: center;
}

.ps-page--404 .container {
  min-height: 100vh;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
}

.ps-page--404 img {
  margin-bottom: 100px;
}

.ps-page--404 h3 {
  margin-bottom: 20px;
  font-size: 36px;
  color: #000;
  font-weight: 600;
}

.ps-page--404 p {
  margin-bottom: 20px;
  font-size: 18px;
}

.ps-page--404 p a {
  color: #F7E5CD;
}

.ps-page--404 .ps-form--widget-search {
  max-width: 370px;
  margin: 0 auto;
}

.ps-page--404 .ps-form--widget-search input {
  height: 50px;
  border-color: #fff;
}

@media (max-width: 767px) {
  .ps-page--404 h3 {
    font-size: 24px;
  }
  .ps-page--404 p {
    font-size: 14px;
  }
}

.ps-page--comming-soon {
  text-align: center;
  padding-bottom: 50px;
}

.ps-page--comming-soon .ps-countdown li {
  display: inline-block;
  margin-right: 20px;
}

.ps-page--comming-soon .ps-countdown li span {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 48px;
  color: #000;
  line-height: 1em;
}

.ps-page--comming-soon .ps-countdown li p {
  margin-bottom: 0;
}

.ps-page--comming-soon .ps-countdown li:last-child {
  margin-right: 0;
}

.ps-page--comming-soon .ps-page__header {
  padding: 50px 0;
}

.ps-page--comming-soon .ps-page__header .ps-logo {
  display: inline-block;
  margin-bottom: 50px;
}

.ps-page--comming-soon .ps-page__header h1 {
  margin-bottom: 20px;
  font-size: 36px;
  font-weight: 600;
}

.ps-page--comming-soon figure figcaption {
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 600;
}

.ps-page--comming-soon figure .ps-countdown {
  margin-bottom: 80px;
}

.ps-page--comming-soon .ps-list--social li {
  margin-right: 30px;
}

.ps-page--comming-soon .ps-list--social li a {
  color: #cccccc;
}

.ps-page--comming-soon .ps-list--social li a:hover {
  color: #F7E5CD;
}

.ps-page--shop .ps-shop-banner {
  padding: 30px 0 45px;
}

.ps-page--shop .ps-shop-brand {
  margin-bottom: 45px;
}

.ps-page--shop .ps-shop-categories {
  margin-bottom: 30px;
}

.ps-page--shop .ps-carousel--nav-inside .owl-item img {
  width: 100%;
}

@media (max-width: 1199px) {
  .ps-page--shop .ps-product:not(.ps-product--wide) {
    border-color: #dfdfdf;
  }
  .ps-page--shop .ps-product:not(.ps-product--wide) .ps-product__content:not(.hover) {
    position: relative;
    display: block;
    left: 0;
    padding: 0 10px 20px;
    border-bottom: 1px solid #dfdfdf;
    border-top: none;
  }
  .ps-page--shop .ps-shop-banner,
  .ps-page--shop .ps-shop-brand,
  .ps-page--shop .ps-shop-categories,
  .ps-page--shop .ps-shop-categories,
  .ps-page--shop .ps-block--shop-features,
  .ps-page--shop .ps-block--shop-features {
    display: none;
  }
}

@media (max-width: 767px) {
  .ps-page--shop .ps-product .ps-product__vendor {
    padding-left: 10px;
    padding-right: 10px;
  }
  .ps-page--shop .ps-product .ps-product__content:not(.hover) {
    width: 100%;
    left: 0;
    padding: 0 10px 20px;
  }
}

@media (min-width: 576px) and (max-width: 992px) {
  .ps-page--shop .container {
    max-width: 100%;
  }
}

.ps-page--product {
  padding-top: 30px;
}

.ps-page--product .ps-page__container {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
}

.ps-page--product .ps-page__container > * {
  width: 100%;
}

.ps-page--product .ps-page__container .ps-page__left {
  padding-right: 30px;
  max-width: calc(100% - 290px);
}

.ps-page--product .ps-page__container .ps-page__right {
  min-width: 290px;
  max-width: 290px;
}

.ps-page--product .ps-page__right .widget_same-brand {
  border-bottom: 1px solid #eaeaea;
}

.ps-page--product.reverse .ps-page__container {
  flex-flow: row-reverse nowrap;
}

.ps-page--product.reverse .ps-page__right {
  padding-right: 30px;
}

@media (max-width: 1199px) {
  .ps-page--product .ps-page__container {
    flex-flow: column wrap;
  }
  .ps-page--product .ps-page__container .ps-page__left {
    max-width: 100%;
    padding-right: 0;
  }
}

@media screen and (max-width: 479px) {
  .ps-page--product .ps-page__container .ps-page__right {
    max-width: 100%;
  }
}

.ps-page--product-box {
  background-color: #f1f1f1;
}

.ps-page--blog .ps-page__header {
  padding: 90px 0;
  text-align: center;
}

.ps-page--blog .ps-page__header h1 {
  margin: 0 0 20px;
  font-size: 48px;
  font-weight: 600;
  line-height: 1em;
}

@media (max-width: 991px) {
  .ps-page--blog .ps-page__header {
    padding: 45px 0;
  }
  .ps-page--blog .ps-page__header h1 {
    font-size: 36px;
  }
}

@media (max-width: 767px) {
  .ps-page--blog .ps-page__header {
    padding: 30px 0;
  }
  .ps-page--blog .ps-page__header h1 {
    font-size: 32px;
  }
}

@media (max-width: 479px) {
  .ps-page--blog .ps-page__header {
    padding: 25px 0;
  }
  .ps-page--blog .ps-page__header h1 {
    font-size: 24px;
  }
}

.sub-toggle {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 36px;
  cursor: pointer;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  display: none;
}

.sub-toggle:before, .sub-toggle:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 1px;
  background-color: #000;
}

.sub-toggle:before {
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.sub-toggle:after {
  -webkit-transform: translateX(-50%) rotate(90deg);
  -moz-transform: translateX(-50%) rotate(90deg);
  -ms-transform: translateX(-50%) rotate(90deg);
  -o-transform: translateX(-50%) rotate(90deg);
  transform: translateX(-50%) rotate(90deg);
}

.sub-toggle.active:after {
  display: none;
}

.sub-menu {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 240px;
  z-index: 1000;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  background-color: #fff;
  border: 1px solid #ccc;
  visibility: hidden;
  opacity: 0;
}

.sub-menu:before {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  height: 0;
  width: 0;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  display: none;
}

.sub-menu > li {
  display: block;
}

.sub-menu > li > a {
  display: inline-block;
  padding: 6px 20px;
  width: 100%;
  font-size: 14px;
  color: #000;
  text-transform: capitalize;
  -webkit-transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  -moz-transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
}

.sub-menu > li > a:hover {
  color: #F7E5CD;
}

.sub-menu > li:last-child {
  border-bottom: none;
}

.sub-menu > li:last-child a {
  border-bottom: 1px solid #eee;
}

.sub-menu > li.menu-item-has-children {
  position: relative;
}

.sub-menu > li.menu-item-has-children > .sub-toggle {
  display: none;
}

.sub-menu > li.menu-item-has-children > .sub-menu {
  position: absolute;
  top: 0;
  left: 100%;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(30px);
  -moz-transform: translateY(30px);
  -ms-transform: translateY(30px);
  -o-transform: translateY(30px);
  transform: translateY(30px);
}

.sub-menu > li.menu-item-has-children > .sub-menu:before {
  display: none;
}

.sub-menu > li.menu-item-has-children:hover > .sub-menu {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.mega-menu {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 15px 30px 10px;
  background-color: #fff;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  border: 1px solid #ccc;
}

.mega-menu > * {
  width: 100%;
  padding: 10px 15px;
}

.mega-menu h4 {
  margin: 0;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

.mega-menu .mega-menu__column {
  min-width: 180px;
}

.mega-menu .mega-menu__list {
  background-color: transparent;
}

.mega-menu .mega-menu__list li a {
  display: block;
  color: #000;
  line-height: 20px;
  padding: 5px 0;
  font-size: 14px;
  background-color: transparent;
}

.mega-menu .mega-menu__list li a.has-badge {
  padding-right: 20px;
}

.mega-menu .mega-menu__list li a.has-badge:after {
  content: 'New';
  display: inline-block;
  position: absolute;
  top: 5px;
  right: 0;
  font-size: 9px;
  color: #ffffff;
  padding: 0px 8px;
  line-height: 2em;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  background-color: #F7E5CD;
}

.mega-menu .mega-menu__list li a.has-badge.sale {
  color: #ec0101;
}

.mega-menu .mega-menu__list li a.has-badge.sale:after {
  content: 'Sale';
  background-color: #ffa800;
}

.mega-menu .mega-menu__list li a:hover {
  color: #F7E5CD;
}

.menu {
  text-align: left;
}

.menu > li {
  display: inline-block;
}

.menu > li > a {
  display: inline-block;
  padding: 15px 25px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #000;
}

.menu > li > a:hover {
  color: #000;
}

.menu > li:first-child {
  padding-left: 0;
}

.menu > li:first-child > a {
  padding-left: 0;
}

.menu > li:last-child {
  margin-right: 0;
  padding-right: 0;
}

.menu > li .sub-toggle {
  margin-left: 5px;
  display: none;
}

.menu > li .sub-menu {
  visibility: hidden;
  opacity: 0;
}

.menu > li .mega-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
}

.menu > li.menu-item-has-children {
  position: relative;
}

.menu > li.menu-item-has-children > a:after {
  content: "\e93a";
  font: normal normal normal 12px/1 linearicons;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
}

.menu > li.menu-item-has-children > a:before {
  content: '';
  width: 13px;
  height: 13px;
  background-color: #fff;
  position: absolute;
  top: 100%;
  left: 50%;
  z-index: 10000;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  -webkit-transform: rotate(45deg) translate(-50%, -50%);
  -moz-transform: rotate(45deg) translate(-50%, -50%);
  -ms-transform: rotate(45deg) translate(-50%, -50%);
  -o-transform: rotate(45deg) translate(-50%, -50%);
  transform: rotate(45deg) translate(-50%, -50%);
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 0;
  visibility: hidden;
  opacity: 0;
}

.menu > li.has-mega-menu {
  position: relative;
}

.menu > li.has-mega-menu .mega-menu {
  position: absolute;
  top: 100%;
  left: 0;
}

.menu > li.has-mega-menu:hover .mega-menu {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.menu > li:hover .sub-toggle {
  color: #ffffff;
}

.menu > li:hover > .sub-menu {
  visibility: visible;
  opacity: 1;
}

.menu > li:hover.menu-item-has-children > a:before {
  visibility: visible;
  opacity: 1;
}

.menu--product-categories {
  position: relative;
  height: 100%;
}

.menu--product-categories .menu__toggle {
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;
}

.menu--product-categories .menu__toggle i {
  font-size: 20px;
  margin-right: 10px;
}

.menu--product-categories .menu__toggle span {
  font-size: 20px;
  color: #000;
  font-size: 16px;
  font-weight: 600;
}

.menu--product-categories .menu__toggle:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #000;
  transition: transform 0.75s cubic-bezier(0.7, 0, 0.3, 1);
  -webkit-transform: scale3d(0, 1, 1);
  -moz-transform: scale3d(0, 1, 1);
  -ms-transform: scale3d(0, 1, 1);
  -o-transform: scale3d(0, 1, 1);
  transform: scale3d(0, 1, 1);
  -webkit-transform-origin: 100% 50%;
  -moz-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  -o-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}

.menu--product-categories .menu__toggle:hover:before, .menu--product-categories .menu__toggle.active:before {
  -webkit-transform-origin: 0 50%;
  -moz-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
  -o-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transform: scale3d(1, 1, 1);
  -moz-transform: scale3d(1, 1, 1);
  -ms-transform: scale3d(1, 1, 1);
  -o-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

.menu--product-categories .menu__content {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 100;
  z-index: 1000;
  min-width: 100%;
  padding-top: 2px;
  visibility: hidden;
  opacity: 0;
  transition: all .3s ease;
  -webkit-transform: scale3d(1, 1, 0) translateY(30px);
  -moz-transform: scale3d(1, 1, 0) translateY(30px);
  -ms-transform: scale3d(1, 1, 0) translateY(30px);
  -o-transform: scale3d(1, 1, 0) translateY(30px);
  transform: scale3d(1, 1, 0) translateY(30px);
}

.menu--product-categories .menu--dropdown {
  background-color: #fff;
  border: 1px solid #d3d3d3;
  min-width: 260px;
}

.menu--product-categories .menu--dropdown > .has-mega-menu {
  position: relative;
}

.menu--product-categories:hover .menu__content {
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1) translateY(0);
  -moz-transform: scale3d(1, 1, 1) translateY(0);
  -ms-transform: scale3d(1, 1, 1) translateY(0);
  -o-transform: scale3d(1, 1, 1) translateY(0);
  transform: scale3d(1, 1, 1) translateY(0);
}

.menu--dropdown > li {
  padding: 0 20px;
  transition: all .4s ease;
}

.menu--dropdown > li > a {
  display: block;
  padding: 10px 0;
  color: #000;
}

.menu--dropdown > li > a i {
  margin-right: 10px;
  font-size: 18px;
}

.menu--dropdown > li.has-mega-menu .mega-menu {
  position: absolute;
  top: 0;
  left: 100%;
  width: auto;
  min-width: 530px;
  visibility: hidden;
  opacity: 0;
  border-left: none;
}

.menu--dropdown > li:hover {
  background-color: #F7E5CD;
}

.menu--dropdown > li:hover.has-mega-menu .mega-menu {
  visibility: visible;
  opacity: 1;
}

.menu--mobile .sub-menu {
  position: relative;
  display: none;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  visibility: visible;
  opacity: 1;
  -webkit-transition: all 0s ease;
  -moz-transition: all 0s ease;
  transition: all 0s ease;
  border: none;
}

.menu--mobile .sub-menu > li > a {
  border: none;
  padding: 10px 20px;
}

.menu--mobile .mega-menu {
  display: none;
  -webkit-flex-flow: row wrap;
  -moz-flex-flow: row wrap;
  flex-flow: row wrap;
  max-width: 100%;
  padding-left: 15px;
  -webkit-transition: all 0s ease;
  -moz-transition: all 0s ease;
  transition: all 0s ease;
}

.menu--mobile .mega-menu h4 {
  position: relative;
  display: block;
  margin-bottom: 0;
  padding: 10px 0;
  width: 100%;
  line-height: 20px;
}

.menu--mobile .mega-menu .mega-menu__column {
  padding: 0;
}

.menu--mobile .mega-menu .mega-menu__list {
  display: none;
  padding-left: 15px;
}

.menu--mobile .mega-menu .mega-menu__list li a {
  color: #666;
  border: none;
}

.menu--mobile .mega-menu .mega-menu__list li a:hover {
  color: #000;
}

.menu--mobile > li {
  border-bottom: 1px solid #dedede;
}

.menu--mobile > li > a {
  position: relative;
  z-index: 10;
  display: block;
  padding: 15px 20px;
  line-height: 20px;
  font-size: 15px;
}

.menu--mobile > li > a:hover {
  padding-left: 5px;
  color: #000;
}

.menu--mobile > li.menu-item-has-children {
  position: relative;
}

.menu--mobile > li.menu-item-has-children .sub-toggle {
  position: absolute;
  top: 5px;
  right: 0;
  width: 40px;
  height: 40px;
  z-index: 20;
  display: inline-block;
}

.menu--mobile .mega-menu {
  border: none;
}

.menu--mobile .mega-menu h4 {
  font-weight: 500;
}

.menu--mobile .mega-menu__list > li {
  border-bottom: 1px solid #dedede;
}

.menu--mobile .mega-menu__list > li a {
  padding: 10px 0;
}

.menu--mobile .mega-menu__list > li:last-child {
  border: none;
}

/*4. MODULES */
.navigation {
  background-color: #F7E5CD;
}

.navigation > .ps-container, .navigation > .container {
  display: flex;
  flex-flow: row nowrap;
}

.navigation > .ps-container > *, .navigation > .container > * {
  width: 100%;
}

.navigation__extra > li {
  position: relative;
  display: inline-block;
  margin-right: 20px;
  padding-right: 20px;
}

.navigation__extra > li:after {
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  width: 2px;
  height: 15px;
  background-color: #000;
}

.navigation__extra > li a {
  color: #000000;
}

.navigation__extra > li:last-child {
  margin-right: 0;
  padding-right: 0;
}

.navigation__extra > li:last-child:after {
  display: none;
}

.navigation__extra .ps-dropdown img {
  margin-right: 8px;
}

.navigation .navigation__left {
  max-width: 260px;
}

.navigation .navigation__right {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding-left: 35px;
}

.header .header__extra {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 42px;
  transition: all .4s ease;
}

.header .header__extra > i {
  font-size: 30px;
  line-height: 42px;
}

.header .header__extra span {
  position: absolute;
  bottom: 0;
  right: -6px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  vertical-align: top;
  width: 20px;
  height: 20px;
  color: #ffffff;
  background-color: #000000;
  border-radius: 50%;
}

.header .header__extra span i {
  font-size: 12px;
  font-style: normal;
  line-height: 1em;
  font-weight: 500;
}

.header .header__extra:hover i {
  color: #ffffff;
}

.header .header__actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header .header__actions > * {
  margin: 0 20px;
}

.header .header__left {
  max-width: 300px;
  display: flex;
  align-items: center;
}

.header .header__left .menu--product-categories {
  display: none;
}

.header .header__right {
  max-width: 370px;
}

.header .header__top {
  padding: 25px 0;
  background-color: #F7E5CD;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.header .header__top .ps-container, .header .header__top .container {
  display: flex;
  flex-flow: row nowrap;
}

.header .header__top .ps-container > *, .header .header__top .container > * {
  width: 100%;
}

.header.header--sticky .menu--product-categories .menu__toggle i {
  font-size: 30px;
}

.header.header--sticky .menu--product-categories .menu__toggle span {
  font-size: 16px;
}

.header.header--sticky .header__top {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  border-bottom: none;
  padding: 10px 0;
}

.header.header--sticky .header__left .ps-logo {
  display: none;
}

.header.header--sticky .header__left .menu--product-categories {
  display: block;
  background-color: transparent;
}

@media (max-width: 1199px) {
  .header {
    display: none;
  }
  .header.header--mobile {
    display: block;
  }
}

.header--standard .ps-block--user-header a:hover {
  color: #F7E5CD;
}

.header--standard .header__top-links {
  width: 100%;
}

.header--standard .header__top-links > li {
  display: inline-block;
  margin-right: 16px;
  padding-right: 15px;
  position: relative;
}

.header--standard .header__top-links > li:after {
  content: '';
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  height: 15px;
  width: 0;
  border-right: 1px solid #e1e1e1;
}

.header--standard .header__top-links > li > a {
  color: #666666;
}

.header--standard .header__top-links > li:last-child {
  margin-right: 0;
  padding-right: 0;
}

.header--standard .header__top-links > li:last-child:after {
  display: none;
}

.header--standard .header__top-links .language > a img {
  margin-right: 5px;
}

.header--standard .header__top-links .ps-block--user-header .ps-block__left i {
  font-size: 14px;
}

.header--standard .header__top-links .ps-block--user-header .ps-block__right a {
  display: inline-block;
  font-weight: 400;
  margin-right: 10px;
  color: #666666;
}

.header--standard .header__top-links .ps-block--user-header .ps-block__right a:first-child:after {
  content: '/';
  padding-left: 10px;
}

.header--standard .header__top-links .ps-block--user-header .ps-block__right a:hover {
  color: #F7E5CD;
}

.header--standard .header__top {
  padding: 10px 0;
  background-color: #fff;
  border-bottom: none;
}

.header--standard .header__top .container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.header--standard .header__top .header__left {
  max-width: 50%;
}

.header--standard .header__top .header__left p {
  margin-bottom: 0;
  color: #666666;
  line-height: 30px;
}

.header--standard .header__top .header__left p strong {
  color: #000;
}

.header--standard .header__top .header__right {
  text-align: right;
  max-width: 50%;
}

.header--standard .header__content-left {
  max-width: 270px;
  padding-right: 30px;
  display: flex;
  align-items: center;
}

.header--standard .header__content-left .ps-logo {
  margin-right: 30px;
}

.header--standard .header__content-center {
  max-width: 600px;
}

.header--standard .menu--product-categories .menu__toggle i {
  font-size: 30px;
}

.header--standard .ps-form--quick-search {
  width: 600px;
}

.header--standard .ps-form--quick-search button {
  background-color: #F7E5CD;
  color: #000;
}

.header--standard .ps-form--quick-search .form-group--icon {
  border: 1px solid #e1e1e1;
  min-width: 80px;
  border-radius: 4px 0 0 4px;
}

.header--standard .ps-form--quick-search .form-group--icon select {
  height: 40px;
  border: none;
}

.header--standard .ps-form--quick-search input {
  border: 1px solid #e1e1e1;
  border-left: none;
}

.header--standard .header__content {
  padding: 20px 0;
}

.header--standard .header__content .menu--product-categories .menu__toggle:hover:before {
  display: none;
}

.header--standard .header__content .container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.header--standard .header__content .container > * {
  width: 100%;
}

.header--standard .header__extra span {
  background-color: #F7E5CD;
}

.header--standard .header__extra span i {
  color: #000;
  font-weight: 700;
}

.header--standard.header--sticky .header__top {
  position: relative;
}

.header--standard.header--sticky .header__content {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
  background-color: #ffffff;
}

.header--standard.header--sticky .header__content .menu--product-categories {
  display: block;
}

.header--standard.header--sticky .ps-logo {
  display: none;
}

@media (max-width: 1199px) {
  .header--standard {
    display: none;
  }
}

.header--product {
  visibility: hidden;
  opacity: 0;
  height: 0;
}

.header--product .navigation {
  transition: transform .25s ease;
  transform: translateY(-50%);
}

.header--product.header--sticky {
  visibility: visible;
  opacity: 1;
  height: auto;
}

.header--product.header--sticky .navigation {
  padding: 10px 0;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  border-bottom: 1px solid #eaeaea;
  transform: translateY(0);
}

.header--mobile {
  position: relative;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 9999;
  text-align: center;
  border-bottom: 0 none;
  background-color: #F7E5CD;
  -webkit-transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  -moz-transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.header--mobile::after {
  clear: both;
  content: "";
  display: table;
}

.header--mobile .navigation__extra {
  display: inline-block;
}

.header--mobile .navigation__extra li {
  margin-right: 5px;
  padding-right: 5px;
}

.header--mobile .navigation__extra li:after {
  display: none;
}

.header--mobile .header__extra span {
  background-color: #F7E5CD;
}

.header--mobile .header__actions i {
  font-size: 24px;
}

.header--mobile .ps-block--user-header i {
  font-size: 24px;
}

.header--mobile .header__top {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  background-color: #f4f5f5;
  padding: 15px 30px;
}

.header--mobile .header__top > * {
  width: 100%;
}

.header--mobile .header__top .header__left {
  max-width: 40%;
}

.header--mobile .header__top .header__left p {
  margin-bottom: 0;
}

.header--mobile .header__top .header__right {
  max-width: 60%;
  text-align: right;
}

.header--mobile .header__top .header__right .navigation__extra {
  display: inline-block;
}

.header--mobile .navigation--mobile {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 10px 30px;
  border-bottom: none;
}

.header--mobile .navigation--mobile .navigation__left {
  display: flex;
  align-items: center;
}

.header--mobile .header__extra span {
  background-color: #000;
}

.header--mobile .header__extra span i {
  color: #ffffff;
  font-weight: 600;
}

.header--mobile.active {
  -webkit-transform: translateX(270px);
  -moz-transform: translateX(270px);
  -ms-transform: translateX(270px);
  -o-transform: translateX(270px);
  transform: translateX(270px);
}

.header--mobile.header--sticky .navigation--mobile {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: #F7E5CD;
  -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.12);
  -ms-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.12);
}

@media (min-width: 1200px) {
  .header--mobile {
    display: none;
  }
}

@media (max-width: 1199px) {
  .header--mobile .ps-search--mobile {
    display: none;
  }
}

@media (max-width: 991px) {
  .header--mobile .header__top .header__left {
    display: none;
  }
  .header--mobile .header__top .header__right {
    max-width: 100%;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .header--mobile .navigation__extra li > a {
    display: none;
  }
}

@media (max-width: 479px) {
  .header--mobile .header__top {
    display: none;
  }
  .header--mobile .header__actions > * {
    margin: 0;
    margin-right: 20px;
  }
  .header--mobile .header__actions i {
    font-size: 24px;
  }
  .header--mobile .ps-block--user-header {
    margin-right: 0;
  }
  .header--mobile .ps-block--user-header i {
    font-size: 24px;
  }
  .header--mobile .ps-block--user-header .ps-block__right {
    display: none;
  }
  .header--mobile .ps-search--mobile {
    display: block;
  }
}

.header--mobile-product .header__back {
  vertical-align: middle;
  text-align: left;
}

.header--mobile-product .header__back strong {
  font-size: 18px;
  vertical-align: middle;
}

.header--mobile-product .header__back i {
  vertical-align: middle;
  margin-right: 16px;
  font-size: 24px;
  color: #000;
}

.header--mobile-product .navigation--mobile .navigation__left {
  justify-content: flex-start;
}

.header--mobile-categories .header__back {
  vertical-align: middle;
  text-align: left;
}

.header--mobile-categories .header__back strong {
  font-size: 18px;
  vertical-align: middle;
}

.header--mobile-categories .header__back i {
  vertical-align: middle;
  margin-right: 16px;
  font-size: 24px;
  color: #000;
}

.header--mobile-categories .navigation--mobile .navigation__left {
  justify-content: flex-start;
}

.header--mobile-categories .header__filter {
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5;
}

.header--mobile-categories .header__filter > * {
  flex-basis: 100%;
  max-width: 50%;
}

.header--mobile-categories .header__filter button {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  background-color: transparent;
  font-size: 14px;
}

.header--mobile-categories .header__filter button:after {
  content: '\e93a';
  font-family: Linearicons;
  margin-left: 20px;
}

.header--mobile-categories .header__filter button i {
  margin-right: 10px;
  font-size: 16px;
  font-size: 20px;
  color: #666;
}

.header--mobile-categories .header__filter .header__sort {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

.header--mobile-categories .header__filter .header__sort i {
  margin-right: 10px;
  font-size: 16px;
  font-size: 20px;
  color: #666;
}

.header--mobile-categories .header__filter .header__sort .select2 .select2-selection {
  border: none;
  background-color: transparent;
}

.header--mobile-categories .header__filter .header__sort .select2 .select2-selection .select2-selection__rendered {
  background-color: transparent;
  text-align: left;
}

.header--mobile-categories .header__filter .header__sort .select2 .select2-selection .select2-selection__arrow:before {
  content: '\e93a';
  font-family: Linearicons;
}

.header--mobile-categories .header__filter > button {
  border-right: 1px solid #ccc;
}

.navigation--sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 320px;
  height: 100vh;
  overflow-y: auto;
  z-index: 10001;
  background-color: #fff;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
  transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1) 0s;
}

.navigation--sidebar .navigation__actions {
  text-align: center;
}

.navigation--sidebar .navigation__actions .ps-dropdown {
  margin-bottom: 10px;
}

.navigation--sidebar .navigation__actions .ps-dropdown > a {
  font-size: 1.4rem;
  color: #666;
}

.navigation--sidebar .navigation__header {
  position: relative;
  text-align: center;
  padding: 15px 0;
  background-color: #F7E5CD;
}

.navigation--sidebar .navigation__header h3 {
  margin-bottom: 0;
  font-weight: 600;
  color: #ffffff;
  font-size: 1.6rem;
  line-height: 20px;
  text-transform: uppercase;
  letter-spacing: .05em;
}

.navigation--sidebar .navigation__header .ps-btn--close {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 10px;
}

.navigation--sidebar .navigation__header .ps-btn--close:before, .navigation--sidebar .navigation__header .ps-btn--close:after {
  background-color: #ffffff;
  height: 60%;
}

.navigation--sidebar .navigation__content {
  padding-top: 10px;
  padding-bottom: 70px;
}

.navigation--sidebar .header__actions a {
  display: inline-block;
  margin: 0 5px;
  line-height: 40px;
}

.navigation--sidebar .header__actions a i {
  font-size: 24px;
  color: #000;
  vertical-align: middle;
}

.navigation--sidebar .header__actions .ps-dropdown a {
  padding-right: 0;
}

.navigation--sidebar .header__actions .ps-dropdown a:after {
  display: none;
}

.navigation--sidebar .header__actions .ps-cart-toggle {
  position: relative;
  display: inline-block;
  padding: 5px 0;
  line-height: 30px;
}

.navigation--sidebar .header__actions .ps-cart-toggle span {
  position: absolute;
  top: 40%;
  left: 50%;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  -webkit-transform: translate(25%, -100%);
  -moz-transform: translate(25%, -100%);
  -ms-transform: translate(25%, -100%);
  -o-transform: translate(25%, -100%);
  transform: translate(25%, -100%);
  background-color: #000;
}

.navigation--sidebar .header__actions .ps-cart-toggle span i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #ffffff;
  font-style: normal;
  font-size: 11px;
}

.navigation--sidebar.active {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}

@media (min-width: 1200px) {
  .navigation--sidebar .navigation__actions {
    display: none;
  }
}

@media (max-width: 479px) {
  .navigation--sidebar {
    width: 100%;
  }
}

.navigation--list {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10002;
  padding: 10px 30px;
  border-top: 1px solid #ccc;
  background-color: #ffffff;
}

.navigation--list .navigation__item {
  text-align: center;
}

.navigation--list .navigation__item i {
  font-size: 24px;
}

.navigation--list .navigation__item span {
  display: block;
}

.navigation--list .navigation__item.active i {
  color: #F7E5CD;
}

.navigation--list .navigation__item.active span {
  color: #F7E5CD;
}

.navigation--list .navigation__content {
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

@media (min-width: 1200px) {
  .navigation--list {
    display: none;
  }
}

.navigation--mobile-product {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10002;
  border-top: 1px solid #ccc;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
}

.navigation--mobile-product > * {
  width: 100%;
  flex-basis: 50%;
  font-size: 16px;
  border-radius: 0;
  text-align: center;
}

.navigation--mobile-product .ps-btn--black {
  color: #ffffff;
}

@media (min-width: 1200px) {
  .navigation--mobile-product {
    display: none;
  }
}

.navigation--sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: #fff;
}

.navigation--sticky .navigation {
  background-color: #fff;
}

.navigation--sticky .header__top {
  display: none;
}

.navigation--sticky.navigation--pin {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

.navigation--sticky.navigation--unpin {
  -webkit-transform: translate(0, -100%);
  -moz-transform: translate(0, -100%);
  -ms-transform: translate(0, -100%);
  -o-transform: translate(0, -100%);
  transform: translate(0, -100%);
}

.navigation--sticky.header--1.navigation--pin .navigation {
  background-color: rgba(0, 0, 0, 0.95);
}

.navigation--sticky.header--1.navigation--pin.header--black .navigation {
  background-color: rgba(255, 255, 255, 0.95);
}

.navigation--sticky.header--2.navigation--pin.active {
  background-color: #fff;
  -webkit-transform: translate(270px, 0);
  -moz-transform: translate(270px, 0);
  -ms-transform: translate(270px, 0);
  -o-transform: translate(270px, 0);
  transform: translate(270px, 0);
}

@media (max-width: 1199px) {
  .navigation--sticky.navigation--unpin {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

.ps-site-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  visibility: hidden;
  opacity: 0;
}

.ps-site-overlay.active {
  visibility: visible;
  opacity: 1;
}

.ps-search--mobile {
  max-width: 450px;
  margin: 0 auto;
  padding: 10px 20px;
}

.ps-footer {
  padding-top: 70px;
}

.ps-footer__widgets {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding-bottom: 35px;
}

.ps-footer__links {
  padding: 60px 0;
  border-top: 1px solid #e1e1e1;
}

.ps-footer__links p strong {
  color: #000;
  margin-right: 20px;
  font-weight: 600;
}

.ps-footer__links p a {
  display: inline-block;
  color: #666;
  line-height: 20px;
  margin-right: 10px;
  position: relative;
}

.ps-footer__links p a:after {
  content: '|';
  margin-left: 5px;
}

.ps-footer__links p a:before {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #000;
  -webkit-transform: scale3d(0, 1, 1);
  -moz-transform: scale3d(0, 1, 1);
  -ms-transform: scale3d(0, 1, 1);
  -o-transform: scale3d(0, 1, 1);
  transform: scale3d(0, 1, 1);
  transition: transform 0.4s cubic-bezier(0.7, 0, 0.3, 1);
  -webkit-transform-origin: 100% 50%;
  -moz-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  -o-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}

.ps-footer__links p a:hover:before {
  -webkit-transform: scale3d(1, 1, 1);
  -moz-transform: scale3d(1, 1, 1);
  -ms-transform: scale3d(1, 1, 1);
  -o-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transform-origin: 0 50%;
  -moz-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
  -o-transform-origin: 0 50%;
  transform-origin: 0 50%;
}

.ps-footer__links p a:last-child:after {
  display: none;
}

.ps-footer__copyright {
  padding: 35px 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  border-top: 1px solid #e1e1e1;
}

.ps-footer__copyright p {
  margin-bottom: 0;
  line-height: 30px;
  color: #000;
}

.ps-footer__copyright p:last-child span {
  display: inline-block;
  line-height: inherit;
  vertical-align: middle;
}

.ps-footer__copyright p:last-child a {
  display: inline-block;
  margin-left: 20px;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .ps-footer__widgets {
    flex-flow: row wrap;
  }
  .ps-footer__widgets > * {
    width: 100%;
    max-width: 25%;
  }
  .ps-footer__widgets .widget_contact-us {
    max-width: 100%;
    width: 100%;
  }
}

@media (max-width: 479px) {
  .ps-footer__widgets > * {
    max-width: 50%;
    flex-basis: 50%;
  }
  .ps-footer__copyright {
    flex-flow: row wrap;
    text-align: center;
  }
  .ps-footer__copyright p {
    display: block;
    width: 100%;
  }
  .ps-footer__copyright p span {
    display: block;
    padding-bottom: 10px;
  }
}

.ps-footer--2 .widget_newletters .form-group--nest {
  margin-bottom: 30px;
}

.ps-footer--3 {
  padding: 0;
  border-top: 1px solid #e1e1e1;
  background-color: #f8f8f8;
}

.ps-footer--3 .ps-block--site-features {
  padding: 40px 0;
  margin-bottom: 70px;
  border-bottom: 1px solid #e1e1e1;
}

@media (max-width: 767px) {
  .ps-footer--3 .ps-block--site-features {
    margin-bottom: 40px;
  }
}

.ps-footer--kids .ps-form--newletter .form-group--nest {
  margin-bottom: 20px;
}

.ps-footer--photo .ps-site-info h5 {
  color: #c81919;
  font-size: 24px;
  font-weight: 600;
}

.ps-footer--photo .ps-form--newletter .form-group--nest {
  margin-bottom: 10px;
}

.ps-footer--photo .ps-form--newletter button {
  color: #fff;
  background-color: #c81919;
}

.ps-footer--photo .ps-form--newletter button:hover {
  background-color: #9b1313;
}

.ps-footer--photo .widget_contact-us h3 {
  color: #c81919;
}

.ps-footer--photo .ps-footer__widgets {
  display: block;
}

.ps-footer--photo .ps-footer__widgets > * {
  max-width: 100%;
  flex-basis: 100%;
}

@media screen and (min-width: 1200px) {
  .ps-footer--photo .ps-footer__widgets {
    display: flex;
  }
}

@media screen and (min-width: 1440px) {
  .ps-footer--photo .ps-footer__widgets {
    display: flex;
    flex-flow: row nowrap;
  }
  .ps-footer--photo .ps-footer__widgets > * {
    flex-basis: 100%;
  }
  .ps-footer--photo .ps-footer__widgets .ps-footer__left {
    max-width: 420px;
  }
}

@media (min-width: 1440px) {
  .ps-footer--photo .ps-footer__content {
    flex: 2 1 100%;
  }
  .ps-footer--photo .ps-footer__left {
    flex: 1 1 100%;
    max-width: 400px;
  }
  .ps-footer--photo .ps-footer__content {
    width: 100%;
    max-width: 100%;
  }
  .ps-footer--photo .ps-footer__wrapper {
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

@media (min-width: 1680px) {
  .ps-footer--photo .ps-footer__wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }
}

/*5. HELPERS */
.ma-0 {
  margin: 0;
}

.pd-0 {
  padding: 0;
}

.mt-0 {
  margin-top: 0px;
}

.mr-0 {
  margin-right: 0px;
}

.mb-0 {
  margin-bottom: 0px;
}

.ml-0 {
  margin-left: 0px;
}

.pt-0 {
  padding-top: 0px;
}

.pr-0 {
  padding-right: 0px;
}

.pb-0 {
  padding-bottom: 0px;
}

.pl-0 {
  padding-left: 0px;
}

.mt-5 {
  margin-top: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.pt-5 {
  padding-top: 5px;
}

.pr-5 {
  padding-right: 5px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pl-5 {
  padding-left: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.pt-10 {
  padding-top: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mr-15 {
  margin-right: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.pt-15 {
  padding-top: 15px;
}

.pr-15 {
  padding-right: 15px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pl-15 {
  padding-left: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.pr-20 {
  padding-right: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mr-25 {
  margin-right: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}

.ml-25 {
  margin-left: 25px;
}

.pt-25 {
  padding-top: 25px;
}

.pr-25 {
  padding-right: 25px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pl-25 {
  padding-left: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mr-30 {
  margin-right: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.ml-30 {
  margin-left: 30px;
}

.pt-30 {
  padding-top: 30px;
}

.pr-30 {
  padding-right: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pl-30 {
  padding-left: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mr-35 {
  margin-right: 35px;
}

.mb-35 {
  margin-bottom: 35px;
}

.ml-35 {
  margin-left: 35px;
}

.pt-35 {
  padding-top: 35px;
}

.pr-35 {
  padding-right: 35px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pl-35 {
  padding-left: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mr-40 {
  margin-right: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.ml-40 {
  margin-left: 40px;
}

.pt-40 {
  padding-top: 40px;
}

.pr-40 {
  padding-right: 40px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pl-40 {
  padding-left: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mr-45 {
  margin-right: 45px;
}

.mb-45 {
  margin-bottom: 45px;
}

.ml-45 {
  margin-left: 45px;
}

.pt-45 {
  padding-top: 45px;
}

.pr-45 {
  padding-right: 45px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pl-45 {
  padding-left: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mr-50 {
  margin-right: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.ml-50 {
  margin-left: 50px;
}

.pt-50 {
  padding-top: 50px;
}

.pr-50 {
  padding-right: 50px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pl-50 {
  padding-left: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mr-55 {
  margin-right: 55px;
}

.mb-55 {
  margin-bottom: 55px;
}

.ml-55 {
  margin-left: 55px;
}

.pt-55 {
  padding-top: 55px;
}

.pr-55 {
  padding-right: 55px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pl-55 {
  padding-left: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mr-60 {
  margin-right: 60px;
}

.mb-60 {
  margin-bottom: 60px;
}

.ml-60 {
  margin-left: 60px;
}

.pt-60 {
  padding-top: 60px;
}

.pr-60 {
  padding-right: 60px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pl-60 {
  padding-left: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mr-65 {
  margin-right: 65px;
}

.mb-65 {
  margin-bottom: 65px;
}

.ml-65 {
  margin-left: 65px;
}

.pt-65 {
  padding-top: 65px;
}

.pr-65 {
  padding-right: 65px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pl-65 {
  padding-left: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mr-70 {
  margin-right: 70px;
}

.mb-70 {
  margin-bottom: 70px;
}

.ml-70 {
  margin-left: 70px;
}

.pt-70 {
  padding-top: 70px;
}

.pr-70 {
  padding-right: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pl-70 {
  padding-left: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mr-75 {
  margin-right: 75px;
}

.mb-75 {
  margin-bottom: 75px;
}

.ml-75 {
  margin-left: 75px;
}

.pt-75 {
  padding-top: 75px;
}

.pr-75 {
  padding-right: 75px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pl-75 {
  padding-left: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mr-80 {
  margin-right: 80px;
}

.mb-80 {
  margin-bottom: 80px;
}

.ml-80 {
  margin-left: 80px;
}

.pt-80 {
  padding-top: 80px;
}

.pr-80 {
  padding-right: 80px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pl-80 {
  padding-left: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mr-85 {
  margin-right: 85px;
}

.mb-85 {
  margin-bottom: 85px;
}

.ml-85 {
  margin-left: 85px;
}

.pt-85 {
  padding-top: 85px;
}

.pr-85 {
  padding-right: 85px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pl-85 {
  padding-left: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mr-90 {
  margin-right: 90px;
}

.mb-90 {
  margin-bottom: 90px;
}

.ml-90 {
  margin-left: 90px;
}

.pt-90 {
  padding-top: 90px;
}

.pr-90 {
  padding-right: 90px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pl-90 {
  padding-left: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mr-95 {
  margin-right: 95px;
}

.mb-95 {
  margin-bottom: 95px;
}

.ml-95 {
  margin-left: 95px;
}

.pt-95 {
  padding-top: 95px;
}

.pr-95 {
  padding-right: 95px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pl-95 {
  padding-left: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.mr-100 {
  margin-right: 100px;
}

.mb-100 {
  margin-bottom: 100px;
}

.ml-100 {
  margin-left: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pr-100 {
  padding-right: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pl-100 {
  padding-left: 100px;
}

.fw-300 {
  font-weight: 300;
}

.fw-500 {
  font-weight: 500;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.fw-900 {
  font-weight: 900;
}

.ps-fullwidth {
  width: 100%;
}

html .bg--parallax {
  position: relative;
  z-index: 10;
  background-attachment: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: 50% 50%;
}

.bg--cover {
  background-position: 50% 50% !important;
  background-size: cover !important;
}

.bg--top {
  background-position: 50% 50% !important;
  background-size: cover !important;
}

.ps-block--category-2 ul, .ps-block--products-of-category .ps-block__categories ul, .ps-block--product-box ul, .ps-block--categories-box ul, .ps-block--menu-categories .ps-block__content ul, .ps-block--shopping-total ul, .ps-block--ourteam ul, .ps-block--vendor-filter ul, .ps-block--store ul, .ps-block--payment-method ul, .ps-panel--sidebar ul, .widget_sidebar ul, .widget_shop ul, .widget_category ul, .widget_footer ul, .widget--blog ul, .widget--vendor ul, .ps-widget--account-dashboard .ps-widget__content ul, .ps-carousel--animate ul, .ps-product ul, .ps-product--detail .ps-product__countdown figure ul, .ps-product--hot-deal .ps-product__header ul, .ps-product--photo ul, .ps-filter ul, .ps-filter--sidebar ul, .ps-form--account ul, .ps-product-list ul, .ps-deal-of-day ul, .ps-shopping .ps-shopping__header ul, .ps-best-sale-brands ul, .ps-blog .ps-blog__header ul, .ps-my-account-2 ul, .ps-store-list .ps-store-link ul, .ps-page--comming-soon ul, .header ul, .navigation--sidebar ul, .ps-block--category-2 ol, .ps-block--products-of-category .ps-block__categories ol, .ps-block--product-box ol, .ps-block--categories-box ol, .ps-block--menu-categories .ps-block__content ol, .ps-block--shopping-total ol, .ps-block--ourteam ol, .ps-block--vendor-filter ol, .ps-block--store ol, .ps-block--payment-method ol, .ps-panel--sidebar ol, .widget_sidebar ol, .widget_shop ol, .widget_category ol, .widget_footer ol, .widget--blog ol, .widget--vendor ol, .ps-widget--account-dashboard .ps-widget__content ol, .ps-carousel--animate ol, .ps-product ol, .ps-product--detail .ps-product__countdown figure ol, .ps-product--hot-deal .ps-product__header ol, .ps-product--photo ol, .ps-filter ol, .ps-filter--sidebar ol, .ps-form--account ol, .ps-product-list ol, .ps-deal-of-day ol, .ps-shopping .ps-shopping__header ol, .ps-best-sale-brands ol, .ps-blog .ps-blog__header ol, .ps-my-account-2 ol, .ps-store-list .ps-store-link ol, .ps-page--comming-soon ol, .header ol, .navigation--sidebar ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
